import React, { forwardRef, useEffect, useState } from 'react'
import Image from 'next/image'

import { shouldUseNextImage } from '@/services/functions'

const ImageNext = ({ src, width, height, alt, autoSize, ...rest }, ref) => {
  const [altText, setAltText] = useState(alt)

  useEffect(() => {
    if (!alt) return
    setAltText(alt)
  }, [])

  const renderNativeImg = () => {
    return autoSize ? (
      <img ref={ref} src={src} alt={altText} {...rest} />
    ) : (
      <img
        ref={ref}
        src={src}
        width={width}
        height={height}
        alt={altText}
        {...rest}
      />
    )
  }

  return shouldUseNextImage() ? (
    <Image
      ref={ref}
      src={src}
      width={width}
      height={height}
      alt={altText}
      {...rest}
    />
  ) : (
    renderNativeImg()
  )
}

export default forwardRef(ImageNext)
