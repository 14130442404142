/* eslint-disable no-nested-ternary */
import styled from 'styled-components'

export const SharedListContainer = styled.div`
  background: var(--light);
  border-radius: 10px;
  padding: 1.8rem 0;
  width: fit-content;
  min-width: 36rem;
  min-width: 36rem;
  width: 47vw;
  max-width: 500px;

  button {
    background-color: transparent;
  }

  > form {
    padding: 0.75rem 2.25rem 0 2.25rem;
    position: relative;

    label {
      color: var(--dark);
      font-size: 0.875rem;
      font-weight: 500;
    }

    input {
      border-radius: 10px;
      @media only screen and (max-width: 410px) {
        font-size: 12px;
      }
    }
  }

  .inputError {
    font-size: 12px;
    color: var(--error);
  }

  form {
    @media only screen and (max-width: 550px) {
      padding: 0.75rem 1rem 0 1rem;
    }
  }
`

export const SharedListSuggestions = styled.ul`
  max-height: 14rem;
  overflow-y: scroll;
  border: 1px solid var(--grey4);
  position: ${props => (props.showLists ? 'absolute' : 'static')};
  width: ${props => (props.showLists ? '88%' : '100%')};
  z-index: 3;
  box-shadow: 0px 4px 4px 0px #00000033;

  li {
    color: var(--grey21);
    font-size: 16px;
    padding: 1rem;
    cursor: pointer;
    background-color: var(--light);
    border-bottom: 1px solid var(--grey12);

    &:hover {
      background-color: var(--grey12);
    }
  }
`

export const SharedListEmails = styled.ul`
  list-style: none;
  padding-top: 12px;
  max-height: 15rem;
  overflow-y: scroll;
  margin-top: 24px;
  border-top: 1px solid var(--grey4);
  padding-right: 13px;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: var(--grey23);
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--grey24); 
    border-radius: 50px;
  }

  .subtitle {
    padding-bottom: 16px;
    color: var(--grey2);
    font-size: 14px;
    font-weight: 700;
    display: block;
  }
`

export const SharedListEmailItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => (props.success ? 'var(--successLight)' : props.errors ? 'var(--errorLight)' : 'var(--grey5)')};
  padding: 12px 8px;
  border-radius: 5px;
  color: var(--grey21);
  font-size: 16px;
  margin-bottom: 8px;

  button {
    background-color: var(--light);
    padding: 4px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:last-of-type {
    margin-bottom: ${props => (props.errors ? '20px' : '0px')};
  }

  @media only screen and (max-width: 550px) {
    font-size: 13px;
  }
`

export const SharedListButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5rem;
  gap: 1.25rem;

  button {
    padding: 0.7rem 1.56rem;
    border-radius: 5px;
    font-size: 0.875rem;
    font-weight: 500;
  }

  button[type=submit] {
    background-color: var(--blue);
    color: var(--light);
  }

  button[type=button] {
    border: 1px solid var(--grey11);
    color: var(--grey14);
  }

  button:disabled,
  button[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
`

export const SharedListTitle = styled.div`
  border-bottom: 1px solid var(--grey4);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.25rem 1.56rem 2.25rem;
  word-wrap: break-word;
  word-break: break-all;

  p {
    color: var(--dark);
    font-size: 1.5rem;
    font-weight: 600;
  }
`
