import React, { useEffect, useState } from 'react'
import * as S from '../styles'

import { getCountryId } from '@/services/language'

import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const FormEditPersonalData = props => {
  const {
    requestHandler,
    firstNameState,
    firstNameHandler,
    lastNameState,
    lastNameHandler,
    userNameState,
    userNameHandler,
    phoneState,
    phoneHandler,
    langState
  } = props

  const { t } = useTranslation()

  const [labelsFirstName, setLabelsFirstName] = useState('')
  const [labelsLastName, setLabelsLastName] = useState('')
  const [labelsEmail, setLabelsEmail] = useState('')
  const [labelsPhone, setLabelsPhone] = useState('')
  const [placeholdersFirstName, setPlaceholdersFirstName] = useState('')
  const [placeholdersLastName, setPlaceholdersLastName] = useState('')
  const [placeholdersEmail, setPlaceholdersEmail] = useState('')

  useEffect(() => {
    setLabelsFirstName(t('labels.firstName'))
    setLabelsLastName(t('labels.lastName'))
    setLabelsEmail(t('labels.email'))
    setLabelsPhone(t('labels.phone'))
    setPlaceholdersFirstName(t('placeholders.name'))
    setPlaceholdersLastName(t('placeholders.lastName'))
    setPlaceholdersEmail(t('placeholders.email'))
  }, [t])

  const handleSubmit = e => {
    //Para evitar que recarregue a página
    e.preventDefault()
    requestHandler()
  }

  return (
    <S.FormWrapper id="form_changedata" onSubmit={handleSubmit}>
      <section>
        <label>{labelsFirstName + '*'}</label>
        <input
          id="inp_firstname"
          type="text"
          placeholder={placeholdersFirstName}
          value={firstNameState}
          onChange={e => firstNameHandler(e.target.value)}
        />
      </section>

      <section>
        <label>{labelsLastName + '*'}</label>
        <input
          id="inp_lastname"
          type="text"
          placeholder={placeholdersLastName}
          value={lastNameState}
          onChange={e => lastNameHandler(e.target.value)}
        />
      </section>

      <section>
        <label>{labelsEmail + '*'}</label>
        <input
          id="inp_email"
          type="email"
          placeholder={placeholdersEmail}
          value={userNameState}
          onChange={e => userNameHandler(e.target.value)}
        />
      </section>

      <section>
        <label>{labelsPhone}</label>
        <PhoneInput
          inputProps={{ id: 'inp_phone' }}
          country={getCountryId(langState).toLowerCase()}
          value={phoneState}
          onChange={e => phoneHandler(e)}
        />
      </section>

      <input type="submit" hidden />
    </S.FormWrapper>
  )
}

export default FormEditPersonalData
