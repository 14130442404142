import React, { useEffect, useState } from 'react'
import * as S from './styles'

import ImageWithFallback from '@/components/ImageWithFallback'
import ContentAccessButton from '../../ContentAccessButton'

import { useModal } from '@/contexts/ModalContext'

import { getUrlForResize } from '@/services/functions'

const ContentDetailsModal = ({ config }) => {
  const { closeModal } = useModal()

  const [imgSrc, setImgSrc] = useState()
  const [whiteBg, setWhiteBg] = useState(false)

  useEffect(() => {
    const src = getUrlForResize(
      config.img.URL,
      config.img.width,
      config.img.height,
      'png'
    )
    if (src.includes('HHS-') || src.includes('HS')) {
      setWhiteBg(true)
    }
    setImgSrc(src)
  }, [])

  return (
    config && (
      <S.ContentDetailsContainer width={config.img.width}>
        <S.ContentDetailsImgContainer whiteBg={whiteBg}>
          <ImageWithFallback
            src={imgSrc}
            fallbackSrc={config.img.alternateURL}
            alt={config.title}
            width={config.img.width}
            height={config.img.height}
            autoSize={false}
            className="detail-img"
          />
          <button id="btn_closemodal" type="button" onClick={closeModal}>
            <span className="material-icons">close</span>
          </button>
        </S.ContentDetailsImgContainer>

        <S.ContentDetailsTextContainer>
          <p className="title">{config.title}</p>
          <p>{config.text}</p>
          {config.access && (
            <ContentAccessButton
              text={config.access.text}
              handler={config.access.handler}
            />
          )}
        </S.ContentDetailsTextContainer>
      </S.ContentDetailsContainer>
    )
  )
}

export default ContentDetailsModal
