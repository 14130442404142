import ReactDOM from 'react-dom'

import { EVENT_KEYS, sendToDataLayer } from '@/services/events'
import { clearStorage, LOCAL_KEYS, setWithExpiry } from '@/services/browserStorage'
import { PAGE_PATHS, SNOWPLOW_SCHEMAS } from '@/services/paths'

import { trackSelfDescribingEvent } from '@snowplow/browser-tracker'

export const exitApp = () => {
  clearStorage()
  window.location = PAGE_PATHS.signin
}

export const delay = async ms => {
  return new Promise(res => setTimeout(res, ms))
}

export const toggleNav = async (id, classe) => {
  const m = document.getElementById('overt')
  const e = document.getElementById(id)

  if (e) {
    if (e.classList.contains(classe)) {
      e.classList.remove(classe)
      m.classList.add('adjust')
      m.classList.remove('inadjust')
      return true //Classe existia
    } else {
      e.classList.add(classe)
      m.classList.remove('adjust')
      m.classList.add('inadjust')
      return false //Classe não existia
    }
  } else {
    return -1
  }
}

export const toggleClasse = (id, classe) => {
  //Utilizando querySelectorAll por causa do Slick, que clona os slides utilizando o mesmo ID para os clones.
  let classExisted = -1
  const elements = document.querySelectorAll(`[id="${id}"]`)
  for (const e of elements) {
    if (e.classList.contains(classe)) {
      e.classList.remove(classe)
      classExisted = true
    } else {
      e.classList.add(classe)
      classExisted = false
    }
  }

  return classExisted
}

export const DivActive = id => {
  const c = document.getElementsByClassName('active')
  if (c?.[0]?.classList) {
    c[0].classList.remove('active')
  }

  const element = document.getElementById(id)
  element.classList.add('active')
}

export const visible = id => {
  if (id) {
    const element = document.getElementById(id)
    if (!element) {
      return -1
    }
    if (
      window.getComputedStyle(element).getPropertyValue('display') === 'none'
    ) {
      // eslint-disable-next-line react/no-find-dom-node
      ReactDOM.findDOMNode(element).style.setProperty('display', 'block')
      return true
    } else {
      // eslint-disable-next-line react/no-find-dom-node
      ReactDOM.findDOMNode(element).style.setProperty('display', 'none')
      return false
    }
  } else {
    return -1
  }
}

export const getUrlForResize = (url, width, height, format) => {
  if (!url) return

  const baseUrl = `${process.env.CDN_URL}/`
  if (!url.includes(baseUrl)) {
    url = baseUrl + url
  }

  if (url.includes('&{width}x{height}.{format}')) {
    return url
      .replace('{width}', width)
      .replace('{height}', height)
      .replace('{format}', format)
  }

  return `${url}&${width}x${height}.${format}`
}

export const parseFileSize = size => {
  if (size === 0) return '0 Bytes'
  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const i = Math.floor(Math.log(size) / Math.log(k))
  return parseFloat((size / k ** i).toFixed(2)) + ' ' + sizes[i]
}

export const getFileType = fileName => {
  return (
    fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
    fileName
  )
}

export const loadImage = src => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.addEventListener('load', resolve)
    img.addEventListener('error', reject)
    img.src = src
  })
}

export const getNormalizedAppName = () => {
  switch (process.env.APP_NAME) {
    case 'BioAtlas':
      return 'bioatlas'

    case 'BioAtlas Vet':
      return 'vet'

    default:
      return null
  }
}

export const isBrowserMobile = () => {
  const userAgent = navigator.userAgent

  const isMobile =
    containsString(userAgent, 'mobile') ||
    containsString(userAgent, 'android') ||
    containsString(userAgent, 'iphone') ||
    containsString(userAgent, 'ipad')

  return isMobile
}

export const changeInputClass = (id, inputState, isPost, validation) => {
  // console.log('TESTE changeInputClass', id, inputState, isPost, validation)
  const inputClass = getInputStyle(inputState, isPost, validation)
  const element = document.getElementById(id)
  if (element !== null) {
    if (id === 'inp_phone') {
      element.className = 'form-control ' + inputClass
    } else {
      element.className = inputClass
    }
  }
}

export const getInputStyle = (inputState, isPost, validation) => {
  const isValidated = validation ?? inputState !== ''

  if (isPost) {
    if (!isValidated) {
      return 'error'
    } else {
      return 'success'
    }
  } else {
    if (inputState !== '') {
      return 'filled'
    }
  }

  return ''
}

export const openViewer = (id, moduleID, newTab, router) => {
  // console.log('TESTE openViewer:', id, moduleID, newTab, router)

  sendToDataLayer({
    event: EVENT_KEYS.clickContent,
    content_id: id,
    module_id: moduleID
  })

  trackSelfDescribingEvent({
    event: {
      schema: SNOWPLOW_SCHEMAS.event_button_click,
      data: {
        button_name: SNOWPLOW_SCHEMAS.button_content
      }
    },
    context: [{
      schema: SNOWPLOW_SCHEMAS.entity_module,
      data: {
        content_id: `${id}`,
        module_id: `${moduleID}`,
      }
    }]
  })

  document.dispatchEvent(new Event(EVENT_KEYS.openContent))

  let url
  switch (moduleID) {
    case 'Histology':
      url = PAGE_PATHS.viewer
      break
    case 'Simulador':
      url = PAGE_PATHS.simsave
      break
    case 'Radiology':
      url = PAGE_PATHS.dicom
      break
    case 'Pathology':
      url = id.startsWith('HS') ? PAGE_PATHS.viewer : PAGE_PATHS.description
      // url = id.startsWith('HS') ? PAGE_PATHS.viewer : PAGE_PATHS.viewer3D
      break
    default:
      url = PAGE_PATHS.description
      // url = PAGE_PATHS.viewer3D
      break
  }

  if (router.includes(PAGE_PATHS.description)) {
    if (id.startsWith('SI')) {
      url = PAGE_PATHS.simsave
    } else if (id.startsWith('HHS') || id.startsWith('HS')) {
      url = PAGE_PATHS.viewer
    } else if (id.startsWith('1.')) {
      url = PAGE_PATHS.dicom
    } else {
      url = PAGE_PATHS.description
    }
  }

  url += `/${id}`

  if (newTab) {
    window.open(url, '_blank')
  } else {
    window.location.href = url
  }
}

export const removeSpecialCharacters = str => {
  str = str.replace(/[[\]&/\\#, +=\-()$~%@.;'"¨~^´`|:*?!<>{}_]/g, '')
  str = str.replace(/[áàãâä]/gi, 'a')
  str = str.replace(/[éèêë]/gi, 'e')
  str = str.replace(/[íìîï]/gi, 'i')
  str = str.replace(/[óòõôö]/gi, 'o')
  str = str.replace(/[úùûü]/gi, 'u')
  str = str.replace(/ç/gi, 'c')
  return str
}

export const compareStrings = (str1, str2, keepSpecialChars, caseSensitive) => {
  let s1, s2

  if (keepSpecialChars) {
    s1 = str1
    s2 = str2
  } else {
    s1 = removeSpecialCharacters(str1)
    s2 = removeSpecialCharacters(str2)
  }

  if (!caseSensitive) {
    s1 = s1.toLowerCase()
    s2 = s2.toLowerCase()
  }

  return s1 === s2
}

export const containsString = (
  originalString,
  comparison,
  keepSpecialChars,
  caseSensitive
) => {
  let s1, s2

  if (keepSpecialChars) {
    s1 = originalString
    s2 = comparison
  } else {
    s1 = removeSpecialCharacters(originalString)
    s2 = removeSpecialCharacters(comparison)
  }

  if (!caseSensitive) {
    s1 = s1.toLowerCase()
    s2 = s2.toLowerCase()
  }

  return s1.includes(s2)
}

export const replaceSpaces = str => {
  return str.replace(/\s+/g, '_')
}

export const reassignSpaces = str => {
  return str.replace(/_/g, ' ')
}

export const getVW = () => {
  return Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  )
}

export const rearrangeArray = (array, firstIndex) => {
  const greater = []
  const lesser = []
  for (let i = firstIndex; i < array.length; i++) {
    greater.push(array[i])
  }
  for (let j = 0; j < firstIndex; j++) {
    lesser.push(array[j])
  }
  return [...greater, ...lesser]
}

export const isElementInViewport = el => {
  const rect = el.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const convertToMiliseconds = (
  days = 0,
  hours = 0,
  minutes = 0,
  seconds = 0
) => {
  const daysToHours = days * 24
  const hoursToMinutes = (daysToHours + hours) * 60
  const minutesToSeconds = (hoursToMinutes + minutes) * 60
  return (minutesToSeconds + seconds) * 1000
}

export const updateUserImageId = () => {
  let max = 99999999
  let min = 10000000
  const newImageId = Math.floor(Math.random() * (max - min + 1)) + min;
  setWithExpiry(
    LOCAL_KEYS.user_image_id,
    newImageId,
    convertToMiliseconds(1, 0, 0, 0)
  )
  return newImageId
}

export const getFormattedDate = date => {
  // Muda data de YYYY-MM-DD para DD/MM/YYYY
  const split = date.split('-')
  return `${split[2]}/${split[1]}/${split[0]}`
}

export const isB2CAvailable = () => {
  return false
  //TODO: MUDAR QUANDO INCLUIR AS FUNCIONALIDADES PARA B2C
}

export const shouldUseNextImage = () => {
  return false
  //TODO: MUDAR QUANDO RESOLVER PROBLEMAS DE DOMÍNIO EM PROD
}

export const isPhoneNumberDefault = phoneNumber => {
  return phoneNumber === '+5500000000000'
}

export const isUserTyping = () => {
  const textTypeTags = ['INPUT', 'TEXTAREA']
  return textTypeTags.includes(document.activeElement.tagName)
}

export const isValueDefined = value => {
  return value !== undefined && value !== null
}

export const validations = {
  email: (emailState, inputId, isPost) => {
    const EmailValidator = require('email-validator')
    const isValid = EmailValidator.validate(emailState)
    changeInputClass(inputId, emailState, isPost, isValid)
    return isValid
  },
  password: (passwordState, inputId, isPost) => {
    const isValid = passwordState.length >= 8
    changeInputClass(inputId, passwordState, isPost, isValid)
    return isValid
  },
  repeatPassword: (passwordState, repeatPasswordState, inputId, isPost) => {
    const isValid =
      repeatPasswordState !== '' && repeatPasswordState === passwordState
    changeInputClass(inputId, repeatPasswordState, isPost, isValid)
    return isValid
  },
  phoneNumber: (phoneState, inputId, isPost) => {
    const isValid =
      phoneState.length <= 4 || // Até 4 dígitos é só o código do país que vem do PhoneInput, só valida mas não manda para a API
      phoneState.length >= 12
    changeInputClass(inputId, phoneState, isPost, isValid)
    return isValid
  },
  idNumber: (idNumberState, inputId, isPost) => {
    const isValid = isValueDefined(idNumberState) && idNumberState.length >= 7
    changeInputClass(inputId, idNumberState, isPost, isValid)
    return isValid
  },
  required: (state, inputId, isPost) => {
    const isValid = state !== '' && isValueDefined(state)
    changeInputClass(inputId, state, isPost, isValid)
    return isValid
  }
}

export const slugify = (...args) => {
  const value = args.join(' ')
  return value
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, '-') // separator
}

export const slugifyWithoutSeparator = (...args) => {
  const value = args.join(' ')
  return value
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, '') // separator
}

export const slugifyWithoutCasing = (...args) => {
  const value = args.join(' ')
  return value
  // return value
  // .normalize('NFD') // split an accented letter into the base letter and the accent
  // .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
  // .trim()
  // .replace(/[^a-zA-Z0-9\s\-\(\)]/g, '') // allow only letters, numbers, spaces, -, (, )
  // .replace(/\s+/g, '-') // replace spaces with a single hyphen
  // .replace(/-{2,}/g, '-') // replace multiple hyphens with a single one
}

export const validateEmail = (email) => {
  const re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  return re.test(String(email).toLowerCase());
};