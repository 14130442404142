import React from 'react'
import * as S from '../../styles'

import ImageCropper from '@/components/ImageCropper'

const FormCropAvatar = ({ inputImgState, cropperRef }) => {
  return (
    <S.FormWrapper>
      <S.CropperContainer>
        <ImageCropper
          ref={cropperRef}
          inputImgURL={inputImgState}
          shape="round"
        />
      </S.CropperContainer>
    </S.FormWrapper>
  )
}

export default FormCropAvatar
