import styled from 'styled-components'

export const SectionsNavContainer = styled.section`
  &:not(:last-child) {
    margin-bottom: 3rem;
  }
  .section-title {
    text-transform: uppercase;
    color: var(--grey2);
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--grey4);
  }
  .item-group {
    width: 100%;
    max-width: 28%;
    font-size: 14px;
    &:not(:last-child) {
      margin-right: 8%;
    }
    h6 {
      text-align: center;
      color: var(--dark);
      margin-bottom: 1rem;
      font-size: 14px;
    }
    span {
      color: var(--grey10);
    }
  }
`
