import React, { useEffect, useRef, useState } from 'react'
import * as S from './styles'

import ModalButton from '../ModalButton'

import { useModal } from '@/contexts/ModalContext'

import { useTranslation } from 'next-i18next'

const ButtonMenu = props => {
  const { cancelText, cancelCallback, confirmText, confirmCallback, allowSubmitBtn } = props
  const { settings, MODAL_TYPES } = useModal()
  const { t } = useTranslation()

  const [confirmButtonColorClass, setConfirmButtonColorClass] = useState()

  const [buttonsCancel, setButtonsCancel] = useState('')
  const [buttonsSave, setButtonsSave] = useState('')

  const didMount = useRef(false)

  useEffect(() => {
    const getConfirmButtonColorClass = () => {
      switch (settings.type) {
        case MODAL_TYPES.delete_account:
          return 'red'

        default:
          return null
      }
    }
    const colorClass = getConfirmButtonColorClass()
    if (allowSubmitBtn) {
      setConfirmButtonColorClass(colorClass)
    } else {
      setConfirmButtonColorClass('alternate inactive')
    }
  }, [allowSubmitBtn])

  useEffect(() => {
    setButtonsCancel(t('buttons.cancel'))
    setButtonsSave(t('buttons.save'))
  }, [t])

  return (
    <S.ButtonMenuContainer>
      <ModalButton
        settings={{
          id: 'btn_cancel',
          text: cancelText || buttonsCancel,
          callback: cancelCallback,
          colorClass: 'alternate'
        }}
      />
      <ModalButton
        settings={{
          id: 'btn_save',
          text: confirmText || buttonsSave,
          callback: confirmCallback,
          colorClass: confirmButtonColorClass
        }}
      />
    </S.ButtonMenuContainer>
  )
}

export default ButtonMenu
