import React, { useEffect, useState } from 'react'
import * as S from '../styles'

import Password from '@/components/Inputs/Password'

import { useTranslation } from 'react-i18next'

const FormEditPassword = props => {
  const {
    requestHandler,
    oldPassState,
    oldPassHandler,
    newPassState,
    newPassHandler,
    repeatPassState,
    repeatPassHandler
  } = props

  const { t } = useTranslation()

  const [dataValid, setDataValid] = useState(false)

  const [labelsOldPassword, setLabelsOldPassword] = useState('')
  const [labelsNewPassword, setLabelsNewPassword] = useState('')
  const [labelsRepeatPassword, setLabelsRepeatPassword] = useState('')
  const [placeholdersPassword, setPlaceholdersPassword] = useState('')
  const [placeholdersNewPassword, setPlaceholdersNewPassword] = useState('')

  useEffect(() => {
    setLabelsOldPassword(t('labels.oldPassword'))
    setLabelsNewPassword(t('labels.newPassword'))
    setLabelsRepeatPassword(t('labels.repeatPassword'))
    setPlaceholdersPassword(t('placeholders.password'))
    setPlaceholdersNewPassword(t('placeholders.newPassword'))
  }, [t])

  useEffect(() => {
    const oldPassValidation = oldPassState.length >= 8
    const newPassValidation = newPassState.length >= 8
    const repeatPassValidation = repeatPassState.length >= 8

    setDataValid(oldPassValidation && newPassValidation && repeatPassValidation)
  }, [oldPassState, newPassState, repeatPassState])

  useEffect(() => {
    const btn = document.getElementById('btn_save')
    if (!btn) return

    if (dataValid) {
      btn.classList.remove('disabled')
      btn.classList.add('blue')
    } else {
      btn.classList.remove('blue')
      btn.classList.add('disabled')
    }
  }, [dataValid])

  const handleSubmit = e => {
    //Para evitar que recarregue a página
    e.preventDefault()
    requestHandler()
  }

  return (
    <S.FormWrapper onSubmit={handleSubmit}>
      <section>
        <label>{labelsOldPassword}</label>
        <Password
          id="inp_pass"
          placeholder={placeholdersPassword}
          inputState={oldPassState}
          inputHandler={oldPassHandler}
        />
      </section>

      <section>
        <label>{labelsNewPassword}</label>
        <Password
          id="inp_newpass"
          placeholder={placeholdersNewPassword}
          inputState={newPassState}
          inputHandler={newPassHandler}
        />
      </section>

      <section>
        <label>{labelsRepeatPassword}</label>
        <Password
          id="inp_repeatpass"
          placeholder={labelsRepeatPassword}
          inputState={repeatPassState}
          inputHandler={repeatPassHandler}
        />
      </section>

      <input type="submit" hidden />
    </S.FormWrapper>
  )
}

export default FormEditPassword
