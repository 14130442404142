import React from 'react'
import * as S from './styles.js'

const SmallLoading = () => {
  return (
    <S.SmallLoadingWrapper>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </S.SmallLoadingWrapper>
  )
}

export default SmallLoading
