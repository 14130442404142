import { i18n } from '@/translate/i18n'
import { LOCAL_KEYS } from './browserStorage'
import { EVENT_KEYS } from './events'
import { PAGE_PATHS } from './paths'

export const COUNTRIES = {
  BR: { id: 'BR', lang: 'pt' },
  US: { id: 'US', lang: 'en' },
  ES: { id: 'ES', lang: 'es' }
}

export const parseLanguage = language => {
  const country = getCountryFromLanguage(language)
  if (!country) {
    return i18n.t('labels.undefined')
  }

  return i18n.t(`labels.${country.lang}`)
}

export const getCountryId = language => {
  // console.log('TESTE getCountryId language', language)
  const country = getCountryFromLanguage(language)
  // console.log('TESTE getCountryId country', country)
  if (!country) {
    return COUNTRIES.US.id
  }

  return country.id
}

export const getFullCountryData = countryObj => {
  return `${countryObj.lang.toUpperCase()}-${countryObj.id}`
}

export const getFullStyleLanguage = language => {
  const country = getCountryFromLanguage(language)
  if (!country) {
    return getFullCountryData(COUNTRIES.US)
  }

  return getFullCountryData(country)
}

export const getNormalizedLanguage = language => {
  const country = getCountryFromLanguage(language)
  if (!country) {
    return COUNTRIES.US.lang
  }

  return country.lang
}

export const getCountryFromLanguage = language => {
  if (!language) return null

  // console.log('TESTE isLanguageAvailable language:', language)
  const languageCode = getLanguageCode(language)
  // console.log('TESTE isLanguageAvailable languageCode:', languageCode)

  for (const key in COUNTRIES) {
    if (COUNTRIES[key].lang === languageCode) {
      return COUNTRIES[key]
    }
  }

  return null
}

export const getLanguageCode = language => {
  return language.split('-')[0].toLowerCase()
}

export const getLanguageFromPriorityList = () => {
  const priorityList = navigator.languages
  // console.log('TESTE getLanguageFromPriorityList priorityList', priorityList)
  if (!priorityList || priorityList.length <= 0) {
    return undefined
  }

  for (const lang in priorityList) {
    if (getCountryFromLanguage(lang)) {
      return lang
    }
  }

  return undefined
}

export const getDefaultLanguage = () => {
  //Checa se o browser já registrou um idioma
  const cachedLang = localStorage.getItem(LOCAL_KEYS.client_language)
  // console.log('TESTE getDefaultLanguage cachedLang', cachedLang)
  if (cachedLang) {
    return cachedLang
  }

  //Vê se o browser tem um idioma setado - geralmente é o mesmo do OS
  const browserLang = navigator.language
  // console.log('TESTE getDefaultLanguage browserLang', browserLang)
  if (browserLang && getCountryFromLanguage(browserLang)) {
    // console.log('browser language available')
    return browserLang
  }

  //Vê se o browser tem uma lista de preferências de idioma, e pega um compatível se tiver
  const priorityLang = getLanguageFromPriorityList()
  // console.log('TESTE getDefaultLanguage priorityLang', priorityLang)
  if (priorityLang) {
    // console.log('priority language available')
    return priorityLang
  }

  //Caso não consiga nenhuma dessas opções, retorna undefined e na changeClientLanguage ela mudará para inglês
  return undefined
}

export const changeClientLanguage = (language, reload) => {
  // console.log('TESTE changeClientLanguage language:', language)
  // console.log('TESTE changeClientLanguage currentLang:', i18n.language)
  if (language === i18n.language) {
    return
  }

  const langCode = getNormalizedLanguage(language)
  if (langCode === i18n.language) {
    return
  }

  i18n.changeLanguage(langCode, err => {
    document.dispatchEvent(new Event(EVENT_KEYS.languageChange))

    if (err) return console.log(err)
    if (reload) {
      document.location.reload(true)
    }
  })
}

export const getLocalizedZendeskLink = () => {
  const code = getLanguageCode(i18n.language)
  let locale

  switch (code) {
    case 'pt':
      locale = 'pt-br'
      break

    case 'es':
      locale = 'es'
      break

    default:
      locale = 'en-us'
      break
  }

  return `${PAGE_PATHS.zendesk_home}/${locale}/${PAGE_PATHS.zendesk_bioatlas}`
}

export const getLocalizedNewContentLink = () => {
  const code = getLanguageCode(i18n.language)
  let locale

  switch (code) {
    case 'pt':
      locale = 'https://medical-harbour.atlassian.net/wiki/external/ODhmNmY5MTFkZDhjNDIzYTlhNDAyNTllZjk3NzE5NmU'
      break

    case 'es':
      locale = 'https://medical-harbour.atlassian.net/wiki/external/MmFiNzJjOGU4NmJhNDhjNGE5ZmIyYWYwZWM3MWFhM2U'
      break

    default:
      locale = 'https://medical-harbour.atlassian.net/wiki/external/ODIzY2UxMmJlMTU0NGY0MmJmYTdjN2IwMTNhMDRjNWQ'
      break
  }

  return locale
}

export const getLocalizedRequestLink = () => {
  const code = getLanguageCode(i18n.language)
  let locale

  switch (code) {
    case 'pt':
      locale = 'pt-br'
      break

    case 'es':
      locale = 'es'
      break

    default:
      locale = 'en-us'
      break
  }

  return `${PAGE_PATHS.zendesk_home}/${locale}/${PAGE_PATHS.request_bioatlas}`
}
