import styled from 'styled-components'

export const ShortcutContainer = styled.div`
  span:not(.material-icons-round) {
    text-transform: capitalize;
  }
  .flexbox > *:not(:last-child, .material-icons-round) {
    margin-right: 1.5rem;
  }
  .flexbox > *.material-icons-round {
    margin-right: 0.5rem;
  }
`

export const Key = styled.div`
  background: var(--grey11);
  border-radius: 3px;
  padding: 0.5rem 0.75rem;
  text-align: center;
  min-width: 36px;
`
