import React, { useState, useEffect } from 'react'
import * as S from './styles'

import { useModal } from '@/contexts/ModalContext'

import { useTranslation } from 'react-i18next'

const TabMenu = ({ stateHandler }) => {
  const { closeModal } = useModal()
  const { t } = useTranslation()

  const [selectorWidth, setSelectorWidth] = useState('15%')
  const [selectorPos, setSelectorPos] = useState('4%')

  const [buttonsNavigation, setButtonsNavigation] = useState('')
  const [buttonsShortcuts, setButtonsShortcuts] = useState('')

  useEffect(() => {
    if (!document) return

    const defaultBtn = document.getElementById('btn_tabNavigation')
    if (defaultBtn) {
      // Delay na função pq o botão não foi totalmente renderizado
      // Como ele tem largura mínima, a barra de seleção também tem
      setTimeout(() => handleUnderlineSelector(defaultBtn), 10)
    }
  }, [document])

  useEffect(() => {
    setButtonsNavigation(t('buttons.navigation'))
    setButtonsShortcuts(t('buttons.shortcuts'))
  }, [t])

  const getTabButtonParsedWidth = tabButton => {
    const style = window.getComputedStyle(tabButton)
    return parseFloat(style.width)
  }

  const getTabButtonPosition = tabButton => {
    const modal = document.getElementById('modal-help')
    const modalPos = modal.getBoundingClientRect().x
    const btnPos = tabButton.getBoundingClientRect().x
    return btnPos - modalPos
  }

  const handleUnderlineSelector = tabButton => {
    const width = getTabButtonParsedWidth(tabButton)
    const pos = getTabButtonPosition(tabButton)
    setSelectorWidth(`${width + 5}px`)
    setSelectorPos(`${pos - 2.5}px`)
  }

  return (
    <S.TabMenuContainer
      className="flexbox h-spaced"
      selectorWidth={selectorWidth}
      selectorPos={selectorPos}
    >
      <div>
        <div>
          <S.TabButton
            id="btn_tabNavigation"
            type="button"
            onClick={e => {
              handleUnderlineSelector(e.target)
              stateHandler('navigation')
            }}
          >
            {buttonsNavigation}
          </S.TabButton>
          <S.TabButton
            id="btn_tabShortcuts"
            type="button"
            onClick={e => {
              handleUnderlineSelector(e.target)
              stateHandler('shortcuts')
            }}
          >
            {buttonsShortcuts}
          </S.TabButton>
        </div>
        <div className="underline-select"></div>
      </div>
      <S.CloseButton id="btn_closeHelp" type="button" onClick={closeModal}>
        <span className="material-icons-round">close</span>
      </S.CloseButton>
    </S.TabMenuContainer>
  )
}

export default TabMenu
