import React, { useEffect, useState } from 'react'
import * as S from '../styles'

import FileUploader from '@/components/FileUploader'
import { ASSET_PATHS } from '@/services/paths'

import {
  onMultipleFilesException,
  onFileTypeException,
  onFileSizeException
} from '@/services/userAlerts'
import { parseFileSize } from '@/services/functions'

import { useTranslation } from 'react-i18next'

const FormEditAvatar = ({
  outputImgState,
  fileNameState,
  fileNameHandler,
  fileSizeState,
  fileSizeHandler,
  inputImgHandler,
  cropperHandler
}) => {
  const { t } = useTranslation()

  const [labelsDragFile, setLabelsDragFile] = useState('')
  const [labelsUploadFile, setLabelsUploadFile] = useState('')
  const [altsAvatar, setAltsAvatar] = useState('')

  useEffect(() => {
    setLabelsDragFile(t('labels.dragFile'))
    setLabelsUploadFile(t('labels.uploadFile'))
    setAltsAvatar(t('alts.avatar'))
  }, [t])

  const validateFileType = file => {
    const validTypes = ['image/jpeg', 'image/png', 'image/webp']
    return validTypes.includes(file.type)
  }

  const validateFileSize = file => {
    return file.size <= 10485760 * 10
  }

  const handleInputFiles = async files => {
    // console.log("TESTE handleFiles files:", files);

    //Rejeita múltiplas imagens
    if (files.length > 1) {
      onMultipleFilesException()
      return
    }

    const fileData = files[0]

    //Valida tipo
    if (!validateFileType(fileData)) {
      onFileTypeException()
      return
    }

    //Valida tamanho
    const size = parseFileSize(fileData.size)
    if (!validateFileSize(fileData)) {
      onFileSizeException(size)
      return
    }

    //Seta dados
    fileNameHandler(fileData.name)
    fileSizeHandler(size)

    //Le a imagem e muda preview
    const reader = new FileReader()
    reader.readAsDataURL(fileData)
    reader.onload = e => {
      inputImgHandler(e.target.result)
      cropperHandler(true)
    }
  }

  return (
    <S.FormWrapper className="mb-5">
      <div className="photo">
        <div className="flexbox v-centered">
          <img
            id="user_img"
            src={outputImgState}
            alt={altsAvatar}
            style={{
              width: '150px',
              height: '150px',
              objectFit: 'cover'
            }}
            className="skeleton-img"
            onLoad={e => e.target.classList.remove('skeleton-img')}
            onError={e => e.currentTarget.src = ASSET_PATHS.default_avatar}
          />
          <div className="properties">
            <p>{fileNameState}</p>
            <p>{fileSizeState}</p>
          </div>
        </div>
      </div>

      <FileUploader
        handler={handleInputFiles}
        dropMessage={
          <>
            <span>{labelsDragFile}</span>
            <span> </span>
            <span className="link">{labelsUploadFile}</span>
          </>
        }
      />
    </S.FormWrapper>
  )
}

export default FormEditAvatar
