import styled from 'styled-components'

export const RowContainer = styled.div`
  padding: 1rem 0;
  &:first-child {
    padding-top: 0;
  }
  &:not(:last-child) {
    border-bottom: 1px solid var(--grey4);
  }
  span {
    color: var(--grey8);
    font-size: 16px;
  }
`

export const Message = styled.p`
  font-size: 12px;
  margin-top: 1rem;
`
