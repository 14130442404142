import React, { useEffect, useRef, useState } from 'react'

import EditStyleModal from '../../Layouts/Edit'
import FormEditLanguage from '@/components/Forms/EditLanguage'

import {
  ENDPOINTS,
  sendPostRequest,
  handleResponseStates
} from '@/services/api'
import { changeClientLanguage, getFullStyleLanguage } from '@/services/language'
import { setCachedAlert, onUncaughtError } from '@/services/userAlerts'
import { LOCAL_KEYS, SESSION_KEYS } from '@/services/browserStorage'

import { useAuth } from '@/contexts/AuthContext'
import { useModal } from '@/contexts/ModalContext'

import { useTranslation } from 'react-i18next'

const EditLanguageModal = () => {
  const { t } = useTranslation()
  const { user, updateUserAuth } = useAuth()
  const { settings, closeModal } = useModal()

  const [language, setLanguage] = useState('')
  const [initialLanguage, setInitialLanguage] = useState('')
  const [buttonsChangeLanguage, setButtonsChangeLanguage] = useState('')

  const didMount = useRef(false)

  useEffect(() => {
    setButtonsChangeLanguage(t('buttons.changeLanguage'))
  }, [t])

  useEffect(() => {
    if (didMount.current) return

    const initLanguage = () => {
      const lng = localStorage.getItem(LOCAL_KEYS.client_language)
      setLanguage(lng)
      setInitialLanguage(lng)
    }

    initLanguage()
    didMount.current = true
  }, [])

  const onSuccess = () => {
    const alert = {
      icon: 'success',
      title: 'swal.success',
      html: 'messages.dataUpdated'
    }
    setCachedAlert(alert)
    updateUserAuth({ language: getFullStyleLanguage(language) })
    localStorage.removeItem(LOCAL_KEYS.content_tree)
    sessionStorage.removeItem(SESSION_KEYS.navigation_data)
    changeClientLanguage(language, true)
  }

  const responsesUpdate = [{ name: 200, callback: onSuccess }]

  const updateUserLanguage = async () => {
    if (language === initialLanguage) {
      closeModal()
      return
    }

    settings.config.loadHandler(true)

    await sendPostRequest(ENDPOINTS.update_user_info, {
      id: user.idDB,
      language: language
    })
      .then(response => {
        handleResponseStates(response, responsesUpdate)
      })
      .catch(error => {
        const msg = error.data || error
        console.error('updateUserLanguage error: ', msg)
        onUncaughtError()
      })
      .finally(() => {
        //settings.config.leadHandler(false)
      })
  }

  return (
    <EditStyleModal
      name={buttonsChangeLanguage}
      confirmCallback={updateUserLanguage}
      cancelCallback={closeModal}
      contentHTML={
        <FormEditLanguage
          initialLangState={initialLanguage}
          langHandler={setLanguage}
        />
      }
    />
  )
}

export default EditLanguageModal
