import { createGlobalStyle } from 'styled-components'

import { ASSET_PATHS } from '@/services/paths'

export const GlobalStyle = createGlobalStyle`

:root{

    --blue: #5E48E8;
    --blue2: #7879F1;
    --blue3: #D8D1FF;
    --blue4: #9B9CFD;
    --blue5: #F5F9FF;
    --blue6: #21105D;
    --blue7: #E3E0FB;
    --blue8: #EBE7FF;
    --blue9: #D6D7FB;
    --blue10: #180753;
    --blue11: #182C40;
    --blue12: #8081E8;
    --blue13: #F2F6FA;
    --blue14: #7879f1;
    --blue15: #055c99;
    --purple: #7048E8;
    --purple2: #9734AD;
    --purple3: #4B3ABA;
    --purple4: #7b61ff;
    --navyblue: #1F1A3E;
    --navyblue2: #08163C;
    --navyblue3: #283655;
    
    --background: #E5E5E5;
    --text-body: #2B81A2;
    --link: #484AFF;

    --grey: #212429;
    --grey2: #495057;
    --grey3: #B5B5B5;
    --grey4: #E1E1E1;
    --grey5: #F3F5F9;
    --grey6: #BFBFBF;
    --grey7: #505050;
    --grey8: #333333;
    --grey9: #DEDEDE;
    --grey10: #4F4F4F;
    --grey11: #C4C4C4;
    --grey12: #EBEDEF;
    --grey13: #D5D5D5;
    --grey14: #767676;
    --grey15: #7E8286;
    --grey16: #7E7E7E;
    --grey17: #575757;
    --grey18: #3A3A39;
    --grey19: #444A51;
    --grey20: #777777;
    --grey21: #525252;
    --grey22: #363636;
    --grey23: #d9d9d9;
    --grey24: #7d8286;
    --grey25: #b4b4b4;
    --grey26: #DFE5F5;
    --grey27: #3C3C3C;
    --grey28: #767474;
    --grey29: #f1f1f1;
    --grey30: #A3A3A3;

    --orange: #F76C5E;
    --orange2: #F2994A;
    --orange3: #F88621;
    --orange4: #e98c39;

    --green: #0A9B5C;
    --green2: #13af6b;
    --green3: #0E814F;

    --dark: #252525;
    --dark2: #363434;
    --black: #000000;
    --light: #FFFFFF;
    --light2: #F2F2F2;
    --light3: #FFFBFB;
    --light4: #F9F9FE;
    --alert: #EB5757;
    --error: #F03D3E;
    --success: #007B40;
    --successLight: #eef9f5;
    --errorLight: '#fef5f5';
    --warning: #F8E115;
    --hover: #5028C6;
    --hover2: #f38727;
    --filled: #5D5FEF;
    --hover3: #7879F133;

    --headerSmallHeight: 56px;
    --headerLHeight: 80px;
    --headerDHeight: 100px;
    --headerDContactHeight: 41px;
    --bladesViewerMenuWidth: 86px;
    --paddingD: 10rem;
    --unsignedMaxWidth: 1316px;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box
}

*:focus {
    outline: none;
}

html{
    scroll-behavior: smooth;
    @media (max-width: 1080px){
        font-size: 93.75%
    }
    @media (max-width: 720px){
        font-size: 87.5%
    }
}

body{ 
    background: var(--light);
    -webkit-font-smoothing: antialiased;
    color: var(--grey2);
    overflow-x: hidden;
}

body, input, textarea, button{
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

input[type="text"],
input[type="search"],
input[type="password"],
input[type="email"],
input[type="file"],
input[type="number"],
select,
textarea {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    border: 1px var(--grey4) solid;

    width: 100%;
    display: block;
    border-radius: 5px;
    padding: 0.60rem 1rem;
    margin: 5px 0;
    position: relative;

    &.round {
        border-radius: 50px;
    }
}

label{
    display: inline-block;
    font-size: 16px;
    color: var(--grey2)
}

h1, h2, h3, h4, h5, h6, strong{
    font-weight: 600;
}

input[type="button"],
input[type="submit"],
button {
    cursor: pointer;
    border: none;
}

ul, ol {
    margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

::-webkit-input-placeholder { /* Edge */
  color: var(--grey3);
}

.skeleton-img {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
}

.btn {
    display: block;
    max-width: fit-content;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition-property: background, color;
    transition-duration: 0.5s;

    &.round{
        border-radius: 50px;
    }

    &.border-md{
        border-radius: 10px;
    }

    &.sm{
        padding: 0.3rem 0.7rem;
        font-weight: 500;
    }

    &.md{
        padding: .7rem 1.5rem;
        font-weight: 500;
    }

    &.lg{
        padding: 1rem 2.2rem;
        font-size: 16px;
        font-weight: 700;
    }

    &.xl{
        padding: 1.1rem 5.1rem;
        font-size: 22px;
        font-weight: 700;
    }

    &.opacity{
        opacity: 0.6;
    }

    &.shadow {
        box-shadow: 0 4px 4px rgba(148, 148, 148, 0.25);
    }

    &.inactive{
        pointer-events: none;
    }

    &.purple{
        background: var(--purple);
        color: var(--light);
        border: 1px var(--purple) solid;
    }

    &.blue{
        background: var(--blue);
        color: var(--light);
        border: 1px var(--blue) solid;

        &:hover,
        &.inactive{
            background: var(--hover);
        }
    }

    &.blue2{
        background: var(--blue12);
        color: var(--light);
        border: 1px var(--blue12) solid;

        &:hover,
        &.inactive{
            background: var(--hover);
        }
    }

    &.white{
        background: var(--light);
        color: var(--blue);
        border: 1px var(--blue) solid;
    }

    &.alternate{
        background: var(--light);
        color: var(--grey2);
        border: 1px var(--grey4) solid!important;

        &:hover,
        &.inactive{
            background: var(--grey5);
        }
    }

    &.orange{
        background: var(--orange2);
        color: var(--light);

        &:hover,
        &.inactive{
            background: var(--hover2);
        }
    }

    &.red{
        background: var(--alert);
        color: var(--light);

        &:hover,
        &.inactive{
            background: var(--error);
        }
    }

    &.disabled{
        background: var(--grey20);
        color: var(--light);
        border: 1px var(--grey20) solid;
        cursor: not-allowed;
    }
}

.adjust{
    width: 83%!important;
    transition: 0.8s;    
}

.inadjust{
    width: 100%!important;
    transition: 0.8s;    
}

[disable]{
    opacity: 0.6;
    cursor: not-allowed;
}

.hidden, .d-mobile {
    display: none !important;
}

.flexbox {
    display: flex;
}

.col {
    flex-direction: column;
}

.h-around {
    justify-content: space-around;
}

.h-even {
    justify-content: space-evenly;
}

.h-spaced {
    justify-content: space-between;
}

.h-centered {
    justify-content: center;
}

.v-centered {
    align-items: center;
}

.text-center {
    text-align: center;
}

.page-unsigned {
    position: relative;
    top: var(--headerDHeight);
    width: 100vw;
}

.page-signed {
    position: relative;
    top: var(--headerLHeight);
}

.page-viewer {
    position: relative;
    top: var(--headerSmallHeight);
}

.mw-unsigned {
    max-width: var(--unsignedMaxWidth);

    @media only screen and (max-width: 1366px) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

div.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.cont10, .cont20, .cont30, .cont40, .cont50, .cont60, .cont70, .cont80, .cont90, .cont100 {
    position: relative;
    padding: 0.50rem;
    display: inline-block;
}

.cont10 {
    width: 10%;
}
.cont20 {
    width: 20%;
}
.cont30 {
    width: 30%;
}
.cont40 {
    width: 40%;
}
.cont50 {
    width: 50%;
}
.cont60 {
    width: 60%;
}
.cont70 {
    width: 70%;
}
.cont80 {
    width: 80%;
}
.cont80 {
    width: 80%;
}
.cont90 {
    width: 90%;
}
.cont100 {
    width: 100%;
}

#breadcrumb {
	color: var(--grey2);
	width: 99%;
	font-size: 13px;
	padding: 1rem 0 1rem 0;
	z-index: 1;
	position: relative;
	text-align: left;
    float: right;

        strong {
            color: var(--grey3);
            text-decoration: underline;
            font-weight: 600;
            opacity: 0.7;
            padding: 0 0.50rem;
        }

        a {
            color: var(--grey);
            text-decoration: none;
            font-weight: 600;
            padding: 0 0.30rem;
        }

        span{
            padding: 0 0.50rem;
        }
}


.iorange,  .ipurple{
  border: 2px solid var(--grey3);
}

.iorange:hover, .infos:hover .iorange {
  border: 3px solid var(--orange);
}

.ipurple:hover{
  border: 3px solid var(--purple2);
}

.orange{
  background: var(--orange);
}

.purple{
  background: var(--purple2);
}

.error{
    border-color: var(--error) !important;
    background: url(${ASSET_PATHS.icon_error}) no-repeat bottom 54% right 3% !important;
    box-shadow: 0px 1px 1px rgba(240, 61, 62, 0.1);
}

.success{
    background: url(${ASSET_PATHS.icon_success}) no-repeat bottom 54% right 3% !important;
}

.filled{
    border-color: var(--filled) !important;
    box-shadow: 0px 1px 1px rgba(51, 154, 240, 0.1);
}

#onetrust-consent-sdk {
    #onetrust-banner-sdk {
        background-color: var(--blue6) !important;
    }

    #onetrust-policy > * {
        color: var(--light) !important;
    }

    #onetrust-pc-btn-handler {
        background-color: transparent !important;
        color: var(--orange2) !important;
    }

    #onetrust-accept-btn-handler,
    .save-preference-btn-handler {
        background-color: var(--orange2) !important;
        border-color: var(--orange2) !important;
    }

    #onetrust-close-btn-container > * {
        filter: brightness(100);
    }
}

@media only screen and (max-width: 1400px) {
    :root {
        --paddingD: 4rem;
    }
}

@media only screen and (max-width: 1100px) {
    :root {
        --paddingD: 2.5rem;
    }
}

@media only screen and (max-width: 800px) {

    .cont10, .cont20, .cont30, .cont40, .cont50, .cont60, .cont70, .cont80, .cont90{
        width: 100%;
    }

}

@media only screen and (max-width: 400px) {
    :root {
        --paddingD: 1.5rem;
    }
}

.mt-1 {
    margin-top: 0.25rem;
}

.mt-2 {
    margin-top: 0.5rem;
}

.mt-3 {
    margin-top: 1rem;
}

.mt-4 {
    margin-top: 1.5rem;
}

.mt-5 {
    margin-top: 3rem;
}

.mb-1 {
    margin-bottom: 0.25rem;
}

.mb-2 {
    margin-bottom: 0.5rem;
}

.mb-3 {
    margin-bottom: 1rem;
}

.mb-4 {
    margin-bottom: 1.5rem;
}

.mb-5 {
    margin-bottom: 3rem;
}

.ml-1 {
    margin-left: 0.25rem;
}

.ml-2 {
    margin-left: 0.5rem;
}

.ml-3 {
    margin-left: 1rem;
}

.ml-4 {
    margin-left: 1.5rem;
}

.ml-5 {
    margin-left: 3rem;
}

.mr-1 {
    margin-right: 0.25rem;
}

.mr-2 {
    margin-right: 0.5rem;
}

.mr-3 {
    margin-right: 1rem;
}

.mr-4 {
    margin-right: 1.5rem;
}

.mr-5 {
    margin-right: 3rem;
}

.pt-1 {
    padding-top: 0.25rem;
}

.pt-2 {
    padding-top: 0.5rem;
}

.pt-3 {
    padding-top: 1rem;
}

.pt-4 {
    padding-top: 1.5rem;
}

.pt-5 {
    padding-top: 3rem;
}

.pb-1 {
    padding-bottom: 0.25rem;
}

.pb-2 {
    padding-bottom: 0.5rem;
}

.pb-3 {
    padding-bottom: 1rem;
}

.pb-4 {
    padding-bottom: 1.5rem;
}

.pb-5 {
    padding-bottom: 3rem;
}

.pl-1 {
    padding-left: 0.25rem;
}

.pl-2 {
    padding-left: 0.5rem;
}

.pl-3 {
    padding-left: 1rem;
}

.pl-4 {
    padding-left: 1.5rem;
}

.pl-5 {
    padding-left: 3rem;
}

.pr-1 {
    padding-right: 0.25rem;
}

.pr-2 {
    padding-right: 0.5rem;
}

.pr-3 {
    padding-right: 1rem;
}

.pr-4 {
    padding-right: 1.5rem;
}

.pr-5 {
    padding-right: 3rem;
}

.fixed-size-swal-modal {
  width: 500px;
  height: 400px;
  grid-column-start: 1;
}

.smaller-icon .swal2-icon {
  width: 40px;
  height: 40px;
  font-size: 12px;
}

pin-marker,
arrow-marker,
rectangle-marker {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  /* font-weight: bold; */
  cursor: pointer;
  color: inherit;
}

pin-marker:hover,
arrow-marker:hover,
rectangle-marker:hover {
  color: darkblue;
  text-decoration: underline;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  display: none;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.wider-swal-modal {
  max-width: 27%;
  width: 27vw;
}

.custom-pin {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
  transform: translate(-50%, -50%);
}

.rectangle-marker {
  position: absolute;
  border: 2px solid red;
  background-color: rgba(255, 0, 0, 0.0);
  pointer-events: none;
}

#drawing-rectangle {
    position: absolute;
    border: 2px solid red;
    background-color: rgba(255, 0, 0, 0.0);
}
`
