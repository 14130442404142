import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import dynamic from 'next/dynamic'

import { loadImage } from '@/services/functions'

const Cropper = dynamic(() => import('react-easy-crop'), { ssr: false })

const ImageCropper = (props, ref) => {
  const {
    inputImgURL,
    aspect = 1,
    cropWidth,
    cropHeight,
    shape = 'rect',
    showGrid = true,
    zoomWithScroll = false,
    restrictPosition = true
  } = props

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [cropSize, setCropSize] = useState()
  const [cropShape, setCropShape] = useState()
  const [zoom, setZoom] = useState(1)
  const [aspectRatio, setAspectRatio] = useState(aspect)
  const [cropAreaPixels, setCropAreaPixels] = useState()

  useEffect(() => {
    if (!cropWidth || !cropHeight) return

    setCropSize({ width: cropWidth, height: cropHeight })
  }, [cropWidth, cropHeight])

  useEffect(() => {
    if (shape !== 'rect' && shape !== 'round') {
      console.warn('WARNING: Wrong crop shape parameter!')
      return
    }

    setCropShape(shape)
  }, [shape])

  useImperativeHandle(ref, () => ({
    cropToCanvas: async () => {
      const load = await loadImage(inputImgURL)
      const image = load.target
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      if (!ctx) {
        return null
      }

      canvas.width = image.width
      canvas.height = image.height

      ctx.drawImage(image, 0, 0)

      const data = ctx.getImageData(
        cropAreaPixels.x,
        cropAreaPixels.y,
        cropAreaPixels.width,
        cropAreaPixels.height
      )

      canvas.width = cropAreaPixels.width
      canvas.height = cropAreaPixels.height

      ctx.fillstyle = 'white'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      ctx.putImageData(data, 0, 0)

      return canvas
    },

    getImgAsBase64: canvas => {
      return canvas.toDataURL('image/jpeg')
    },

    getImgAsBlob: canvas => {
      return new Promise(resolve => {
        canvas.toBlob(file => {
          resolve(URL.createObjectURL(file))
        }, 'image/jpeg')
      })
    }
  }))

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    // console.log(croppedArea, croppedAreaPixels)
    setCropAreaPixels(croppedAreaPixels)
  }

  return (
    <Cropper
      image={inputImgURL}
      crop={crop}
      zoom={zoom}
      aspect={aspectRatio}
      cropShape={cropShape}
      cropSize={cropSize}
      showGrid={showGrid}
      zoomWithScroll={zoomWithScroll}
      restrictPosition={restrictPosition}
      onCropChange={setCrop}
      onCropComplete={onCropComplete}
      onZoomChange={setZoom}
    />
  )
}

export default forwardRef(ImageCropper)
