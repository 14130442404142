import React, { useEffect, useMemo, useRef, useState } from 'react'
import * as S from '../styles'

import MHSelect from '@/components/Inputs/MHSelect'

import { COUNTRIES } from '@/services/language'

import { useTranslation } from 'react-i18next'

const FormEditLanguage = ({ langHandler, initialLangState }) => {
  const { t } = useTranslation()

  const [defaultOption, setDefaultOption] = useState()

  const [labelsLanguage, setLabelsLanguage] = useState('')

  const LanguageOptions = useMemo(() => {
    return [
      { value: COUNTRIES.US.lang, label: t('labels.en') },
      { value: COUNTRIES.ES.lang, label: t('labels.es') },
      { value: COUNTRIES.BR.lang, label: t('labels.pt') }
    ]
  }, [t])

  const selectRef = useRef()

  useEffect(() => {
    setLabelsLanguage(t('labels.language'))
  }, [t])

  useEffect(() => {
    if (!initialLangState) return

    const getDefaultOption = lang => {
      return LanguageOptions.find(obj => obj.value === lang)
    }

    setDefaultOption(getDefaultOption(initialLangState))
  }, [initialLangState])

  useEffect(() => {
    if (!defaultOption || !selectRef.current) return
    selectRef.current.selectOption(defaultOption)
  }, [defaultOption, selectRef.current])

  const handleSelect = value => {
    langHandler(value)
  }

  return (
    <S.FormWrapper>
      <section>
        <label>{labelsLanguage}</label>
        <MHSelect
          id="inp_language"
          options={LanguageOptions}
          handler={handleSelect}
          selectRef={selectRef}
        />
      </section>
    </S.FormWrapper>
  )
}

export default FormEditLanguage
