import styled from 'styled-components'

export const AlertImgContainer = styled.div`
  position: relative;
  background: var(--light);
  border-radius: 20px 20px 0 0;
  overflow: hidden;

  button {
    background: none;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .alert-img {
    display: block;
    margin: 5rem auto;
  }
`

export const AlertTextContainer = styled.div`
  background: var(--blue2);
  color: var(--light);
  text-align: center;
  border-radius: 0 0 20px 20px;
  padding: 2.5rem;

  .title {
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 2rem;
  }

  p:not(.title) {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
  }

  strong {
    font-weight: 700;
  }

  button {
    margin: 2rem auto 0 auto;
    min-width: 45%;
  }
`
