import styled from 'styled-components'

export const EditTitleContainer = styled.div`
  margin-bottom: 3rem;

  .modal-close {
    background: transparent;
    transition: filter 0.2s;
    &:hover {
      filter: brightness(0.7);
    }
  }
`
