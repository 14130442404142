import React, { useEffect, useRef, useState } from 'react'

import EditStyleModal from '../../Layouts/Edit'
import FormEditPersonalData from '@/components/Forms/EditPersonalData'

import {
  isPhoneNumberDefault,
  changeInputClass,
  validations,
  delay
} from '@/services/functions'
import { LOCAL_KEYS } from '@/services/browserStorage'
import {
  setCachedAlert,
  invalidEmail,
  phoneTooShort,
  onUncaughtError,
  onPhoneDuplicatedError,
  loginAgain
} from '@/services/userAlerts'
import {
  ENDPOINTS,
  sendPostRequest,
  handleResponseStates,
  sendGetRequest
} from '@/services/api'

import { useAuth } from '@/contexts/AuthContext'
import { useModal } from '@/contexts/ModalContext'

import { useTranslation } from 'react-i18next'
import 'react-phone-input-2/lib/style.css'

const EditPersonalDataModal = () => {
  const { t } = useTranslation()
  const { user, userSensitive, updateUserAuth } = useAuth()
  const { settings, closeModal } = useModal()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [userName, setUserName] = useState('')
  const [phone, setPhone] = useState('')
  const [language, setLanguage] = useState()
  const [initialData, setInitialData] = useState()

  const [buttonsChangeProfile, setButtonsChangeProfile] = useState('')

  const didMount = useRef(false)

  let post = false
  let firstNameValidation = false
  let lastNameValidation = false
  let userNameValidation = false
  let phoneValidation = false

  useEffect(() => {
    if (didMount.current) return

    const data = {
      first_name: user.firstName,
      family_name: user.familyName,
      email: user.email,
      phone_number: ''
    }

    if (!isPhoneNumberDefault(userSensitive.phone)) {
      data.phone_number = userSensitive.phone
    }

    setInitialData(data)
    didMount.current = true
  }, [])

  useEffect(() => {
    if (!initialData) return

    setFirstName(initialData.first_name)
    setLastName(initialData.family_name)
    setUserName(initialData.email)

    if (initialData.phone_number) {
      setPhone(initialData.phone_number.replace(/\+/g, ''))
    } else {
      // Para setar o país do PhoneInput
      setLanguage(localStorage.getItem(LOCAL_KEYS.client_language))
    }
  }, [initialData])

  useEffect(() => {
    setButtonsChangeProfile(t('buttons.changeProfile'))
  }, [t])

  useEffect(() => {
    changeInputClass('inp_firstname', firstName, post, firstNameValidation)
  }, [firstName, post, firstNameValidation])

  useEffect(() => {
    changeInputClass('inp_lastname', lastName, post, lastNameValidation)
  }, [lastName, post, lastNameValidation])

  useEffect(() => {
    changeInputClass('inp_email', userName, post, userNameValidation)
  }, [userName, post, userNameValidation])

  useEffect(() => {
    changeInputClass('inp_phone', phone, post, phoneValidation)
  }, [phone, post, phoneValidation])

  const validateData = () => {
    firstNameValidation = validations.required(firstName, 'inp_firstname', post)
    lastNameValidation = validations.required(lastName, 'inp_lastname', post)
    userNameValidation = validations.email(userName, 'inp_email', post)
    phoneValidation = validations.phoneNumber(phone, 'inp_phone', post)

    if (!firstNameValidation || !lastNameValidation) {
      return false
    } else if (!userNameValidation) {
      invalidEmail()
      return false
    } else if (!phoneValidation) {
      phoneTooShort()
      return false
    }

    return true
  }

  const getAlteredData = () => {
    let newData = [
      { first_name: firstName },
      { family_name: lastName },
      { email: userName }
    ]

    // Verifica se inclui o telefone - até 4 dígitos é só o código do país
    if (phone.length > 4) {
      newData.push({ phone_number: `+${phone}` })
    } else {
      newData.push({ phone_number: '' })
    }

    return newData.filter(obj => {
      const key = Object.keys(obj)[0]
      return obj[key] !== initialData[key]
    })
  }

  const getUserDataForUpdate = alteredData => {
    let userData = { id: user.idDB }
    for (const obj of alteredData) {
      userData = {
        ...userData,
        ...obj
      }
    }

    return userData
  }

  const onRefreshSuccess = () => {
    updateUserAuth({
      firstName: firstName,
      familyName: lastName,
      email: userName,
      phone: phone.length > 0 ? `+${phone}` : phone
    })
    document.location.reload(true)
  }

  const onUpdateSuccess = () => {
    const alert = {
      icon: 'success',
      title: 'swal.success',
      html: 'messages.dataUpdated'
    }

    setCachedAlert(alert)
    executeRefresh()
  }

  const executeRefresh = async () => {
    // Adicionado delay para não dar erro na API
    await delay(3000)

    await sendGetRequest(ENDPOINTS.refresh_token)
      .then(response => {
        handleResponseStates(response, responsesRefresh)
      })
      .catch(error => {
        const msg = error.data || error
        console.error('executeRefresh error: ', msg)
        loginAgain()
        settings.config.loadHandler(false)
      })
  }

  const updateUserData = async () => {
    post = true
    if (!validateData()) return

    const alteredData = getAlteredData()
    if (alteredData.length === 0) {
      closeModal()
      return
    }

    settings.config.loadHandler(true)
    const userData = getUserDataForUpdate(alteredData)
    await sendPostRequest(ENDPOINTS.update_user_info, userData)
      .then(response => {
        handleResponseStates(response, responsesUpdate)
      })
      .catch(error => {
        const msg = error.data || error
        console.error('updateUserData error: ', msg)
        msg.data.msg.includes('01090i') ? onPhoneDuplicatedError() : onUncaughtError()
        settings.config.loadHandler(false)
      })
  }

  const responsesRefresh = [{ name: 204, callback: onRefreshSuccess }]

  const responsesUpdate = [{ name: 200, callback: onUpdateSuccess }]

  return (
    <EditStyleModal
      name={buttonsChangeProfile}
      confirmCallback={updateUserData}
      cancelCallback={closeModal}
      contentHTML={
        <FormEditPersonalData
          requestHandler={updateUserData}
          firstNameState={firstName}
          firstNameHandler={setFirstName}
          lastNameState={lastName}
          lastNameHandler={setLastName}
          userNameState={userName}
          userNameHandler={setUserName}
          phoneState={phone}
          phoneHandler={setPhone}
          langState={language}
        />
      }
    />
  )
}

export default EditPersonalDataModal
