const messages = {
  pt: {
    translations: {
      titles: {
        home: 'Home',
        contents: 'Conteúdo',
        integration: 'Integração',
        blog: 'Blog',
        contact: 'Contato',
        compliance: 'Compliance',
        signup: 'Cadastre-se',
        signupSuccess: 'Cadastro Realizado',
        signin: 'Fazer Login',
        login: 'Login',
        finalizeAccount: 'Finalize seu cadastro',
        guardianData: 'Dados do Responsável',
        forgotPassword: 'Esqueci minha senha',
        searchResults: 'Resultados da pesquisa',
        pageNotFound: 'Página não encontrada',
        adjustImage: 'Ajuste sua foto',
        account: 'Perfil',
        admin: 'Admin',
        help: 'Ajuda',
        rate: 'Avaliar',
        rated: 'Avaliado',
        rateQuestion: 'O que você achou desse conteúdo?',
        profileData: 'Dados Pessoais',
        permissions: 'Notificações e Permissões',
        plan: 'Plano',
        config: 'Configurações',
        mouse: 'Mouse',
        keyboard: 'Teclado',
        trackpad: 'Trackpad',
        organs: 'Órgãos e sistemas',
        completeAnatomy: 'Anatomia Completa',
        topographicAnatomy: 'Anatomia Topográfica',
        systemicAnatomy: 'Anatomia Sistêmica',
        cellStructure: 'Células e tecidos',
        cytology: 'Citologia',
        histology: 'Histologia',
        development: 'Funções Corporais e Desenvolvimento',
        embryology: 'Embriologia',
        physiology: 'Fisiologia',
        specialties: 'Clínica Integrada',
        radiology: 'Radiologia',
        pathology: 'Patologia',
        simulator: 'Simulador',
        api: 'API BioAtlas',
        institutions: 'Instituições',
        whiteLabel: 'White Label',
        VR: 'Realidade Virtual 3D',
        multiplatform: 'Acesso Multiplataforma',
        animations: 'Animações Interativas 3D',
        accessControl: 'Controle de Acesso',
        languages: 'Disponível em 3 línguas',
        platform:
          'Plataforma de Conteúdos Digitais para Facilitar o Ensino em Saúde',
        modules:
          'Diversos módulos para Ensino em Saúde em uma única plataforma',
        cadaver: 'Cadáver Virtual',
        cadaver3D: 'Cadáver Virtual 3D',
        atlas3D: 'Atlas Anatômico 3D',
        blades: 'Lâminas Histologia e Histopatologia',
        simulations: 'Simulação Médica',
        cardiacCycle: 'Ciclo Cardíaco do Coração',
        fiberContraction: 'Contração da Fibra Muscular',
        potentialGeneration: 'Geração e Condução de Potencial de Ação',
        hematosis: 'Hematose',
        synapticTransmission: 'Transmissão Sináptica',
        lungVentilation: 'Ventilação Pulmonar',
        pathophysiology: 'Fisiopatologia',
        sickleAnemia: 'Anemia Falciforme',
        diabetes1: 'Diabete Mellitus Tipo 01',
        diabetes2: 'Diabete Mellitus Tipo 02',
        histopathology: 'Histopatologia',
        respiratoryDiseases: 'Doenças do Sistema Respiratório',
        vascularDiseases: 'Doenças do Sistema Cardiovascular',
        bloodDiseases: 'Distúrbios da Circulação Sanguínea',
        tumor: 'Tumor',
        brainDiseases: 'Doenças do Sistema Nervoso',
        genitalDiseases: 'Doenças do Sistema Genital',
        inflammation: 'Inflamação',
        lesions: 'Lesão e Reparo de Células e Tecidos',
        endocrineDiseases: 'Doenças do Sistema Endócrino',
        immuneDiseases: 'Doenças Imunológicas',
        boneDiseases: 'Doenças do Sistemas Muscular e Esquelético',
        cardiology: 'Cardiologia / Urgências Cardiológicas',
        firstAid: 'Pronto Socorro',
        pulmonology: 'Pneumologia',
        infectology: 'Infectologia',
        neurology: 'Neurologia',
        vascular: 'Vascular',
        autoImmune: 'Reumato / Autoimune',
        survey: 'Olá, nos ajude a melhorar o BioAtlas?',
        collections: 'Minha coleção',
        deleteCollections: 'Deseja deletar '
      },
      subtitles: {
        signup: 'Preencha as informações para cadastrar',
        validationSent: 'Informações enviadas para o email',
        signin: 'Faça login para acessar sua conta.',
        finalizeAccount: 'Preencha as informações restantes',
        fillInfo: 'Preencha as informações abaixo:',
        resetPassword: 'Você precisa alterar sua senha:',
        resetToken: 'Você receberá um token para alterar sua senha',
        pageNotFound: 'Desculpe, ocorreu algum erro na nossa página.',
        rightsReserved: 'Todos os direitos reservados.',
        tabText: 'Conteúdos Digitais Interativos para Saúde',
        group: 'Somos uma empresa do Grupo Afya',
        sales: 'Entre em contato com nossa equipe de venda',
        detail: 'Informações',
        rotate: 'Rotacionar',
        moreTools: 'Mais ferramentas',
        pin: 'Pin',
        ruler: 'Régua',
        arrow: 'Seta',
        savePin: 'Salvar pin',
        saveArrow: 'Salvar seta',
        saveRectangle: 'Salvar retângulo',
        fullscreen: 'Tela cheia',
        info: 'Informações',
        move: 'Mover',
        select: 'Selecionar',
        camera: 'Movimentar câmera',
        zoom: 'Zoom',
        deselect: 'Delete seleção',
        focus: 'Focar',
        selectMultiple: 'Seleciona vários',
        hide: 'Esconder',
        isolate: 'Isolar',
        tree: 'Árvore de anatomia',
        textbox: 'Caixa de texto',
        tag: 'Etiqueta',
        cutTool: 'Ferramenta de corte',
        turn: 'Virar',
        menu: 'Menu',
        increaseZoom: 'Aumentar o zoom',
        decreaseZoom: 'Diminuir o zoom',
        originalZoom: 'Voltar à versão original',
        bladeMessage:
          'Para que os atalhos funcionem, primeiro clique no visualizador e depois pressione a tecla',
        organ: 'Órgão',
        tissue: 'Tecido',
        color: 'Coloração',
        section: 'Seção',
        partners1: 'Junte-se a mais de',
        partners2: 'usuários e',
        partners3: 'instituições de Ensino',
        completeAnatomy:
          'Navegue pelo corpo humano 3D através do Cadáver Virtual ou pelo Atlas Anatômico 3D.',
        completeAnatomy2:
          'A Anatomia Humana é a ciência que estuda a morfologia e a organização do corpo humano, através da observação e análise das estruturas macroscópicas constituintes. Essa análise está correlacionada com o funcionamento das várias partes e os mecanismos de formação e de resposta aos fatores ambientais, genéticos e temporais. Pode ser categorizada, a partir da separação das peças, em Sistemas Orgânicos e Regiões Anatômicas. Nesse módulo, foram contemplados modelos anatômicos representativos do corpo masculino e do feminino, além do cadáver virtual masculino.',
        topographicAnatomy:
          'Interaja com uma visão geral da anatomia 3D etiquetada e explore a macroanatomia.',
        topographicAnatomy2:
          'A Anatomia Topográfica trata do estudo da separação do corpo humano em regiões, a fim de facilitar a compreensão das relações anatômicas entre estruturas de todos os sistemas num determinado segmento do corpo. Esse método auxilia o domínio da topografia geral dos órgãos a partir da percepção de funcionamento e localização de todas as peças próximas e relacionadas. Nesse módulo, foram contempladas as regiões anatômicas: Cabeça e Pescoço, Tórax, Abdome, Pelve, Dorso, Membros Superiores e Membros Inferiores.',
        topographicAnatomy3:
          'Navegue nos Elementos Descritivos do Crânio e pelas regiões anatômicas: cabeça e pescoço, membro superior direito, membro superior esquerdo, dorso, tórax, abdome, pelve, membro inferior direito e membro inferior esquerdo.',
        systemicAnatomy:
          'Analise todas as regiões anatômicas separadas por órgãos e sistemas.',
        systemicAnatomy2:
          'A Anatomia Sistêmica trata do estudo analítico dos sistemas orgânicos do corpo humano, estabelecendo conjuntos de órgãos e peças anatômicas que executam, de maneira integrada, as mesmas funções nos mecanismos biológicos. Apesar de cada sistema realizar uma tarefa distinta, todos estão interligados, garantindo o funcionamento e a sobrevivência do organismo como um todo. Nesse módulo, foram considerados os sistemas: Arterial, Articular, Digestório, Endócrino, Esquelético, Linfático, Muscular, Nervoso, Respiratório, Tegumentar, Urogenital e Venoso.',
        systemicAnatomy3:
          'Sistemas Anatômicos: tegumentar, muscular, esquelético, articular, nervoso, linfático, digestório, respiratório, arterial, venoso, endócrino e urogenital.',
        cytology:
          'Navegue através das células 3D virtuais do BioAtlas e descubra mais deste universo a olho nu.',
        cytology2:
          'A Citologia, também conhecida como Biologia Celular, é a ciência que estuda as células e suas organelas, assim como as suas funções e importância na constituição dos seres vivos. Considerada como a unidade fisiológica e morfológica dos organismos, a célula eucariótica engloba várias estruturas menores com funções metabólicas específicas - as organelas - responsáveis por garantir o funcionamento adequado dos mecanismos biológicos. Para estudar essas estruturas invisíveis a olho nu, é necessária a utilização de microscópios ópticos, equipamento utilizado para ampliar a imagem.',
        cytology3:
          'O módulo de Citologia permite ao usuário interagir com duas categorias de conteúdos 3D: Células e Membrana Celular. Navegue nas células: Célula Adiposa, Célula Eucarionte de origem animal, Célula Sanguínea, Ilhotas de Langerhans, Neurônio e Fibra Muscular. Visualize um modelo de Membrana Celular em 3D e animações relacionadas ao transporte de moléculas pela membrana: Difusão Simples, Difusão facilitada, Bomba de sódio e potássio, Co- transporte e contra transporte',
        histology:
          'Um laboratório de microscopia virtual a sua disposição. Explore diversos tipos de tecidos, sistemas e órgãos sensoriais com todos recursos de um microscópio.',
        histology2:
          'A Histologia é a ciência que estuda a formação e a composição das células, órgãos e tecidos biológicos, além de investigar as suas funções individuais e como se relacionam para o funcionamento adequado do organismo. O estudo dessas estruturas microscópicas é feito a partir de cortes muito finos de tecidos biológicos, previamente fixados para preservação. Então, o material de estudo é colocado em lâminas de vidro e passa pelo processo de coloração, destacando os componentes daquele tecido. Com as lâminas histológicas prontas, a análise é feita em microscópios ópticos, equipamentos utilizados para aumentar a imagem.',
        histology3:
          'O BioAtlas possui um módulo dedicado ao estudo de lâminas de glândulas, órgãos, tecidos e sistemas, e lâminas com patologias. Além de possuir uma ferramenta com recursos para visualização e análise das mesmas com zoom de até 40x, marcação nas lâminas, espelhamento, etc.',
        embryology:
          'Acompanhe o desenvolvimento embrionário e fetal de forma interativa, a partir da 4ª semana de gestação até o nascimento, através de animações interativas 3D.',
        embryology2:
          'A Embriologia Humana é a ciência que estuda o desenvolvimento embrionário dos seres humanos, desde a fecundação até o nascimento, caracterizando cada etapa de formação dos indivíduos. Sendo assim, a embriologia analisa todos os processos que ocorrem no crescimento do embrião, considerando desde a fase de segmentação celular até o fim da formação de todos os órgãos e tecidos. Para isso, utiliza muitos conceitos de outras áreas do conhecimento como Citologia, Anatomia, Histologia, Fisiologia e outras. Nesse módulo, foi considerada a etapa da organogênese a partir da 4ª semana do desenvolvimento embrionário.',
        embryology3:
          'Acompanhe o desenvolvimento embrionário e fetal, a partir da 4ª semana de gestação até o nascimento, através de animações interativas 3D do Período Embrionário do Desenvolvimento e do Período Fetal do Desenvolvimento',
        physiology:
          'Diferentes modelos fisiológicos animados possibilitam ao usuário visualizar, interagir e compreender o funcionamento do corpo humano em 3D, além de acompanhar os estágios de seu desenvolvimento com descritivos.',
        physiology2:
          'A Fisiologia Humana é a ciência que estuda o funcionamento do corpo humano através dos processos biológicos internos, integrando os conhecimentos físico-químicos e anatômicos a fim de entender o comportamento do organismo como um todo. Esses processos fisiológicos ocorrem nas células, tecidos, órgãos e sistemas do corpo, evidenciando as interações entre eles e também a interação com o meio externo. Sendo assim, o campo da Fisiologia abrange conteúdos microscópicos, a nível molecular e celular, e macroscópicos.',
        physiology3:
          'Explore o funcionamento e as funções normais do corpo humano, através de conteúdos virtuais 3D interativos:',
        radiology:
          'Visualize exames de diversas modalidades como Tomografias Computadorizadas, Ressonâncias Magnéticas, Raio-X, entre outros.',
        radiology2:
          'Sistema para visualização de imagens DICOM com ferramentas para manipulação de estudos e casos clínicos radiológicos. Permite visualizar imagens de diversas modalidades como Tomografias Computadorizadas, Ressonâncias Magnéticas, Raio-X, entre outras através do navegador web.',
        pathology:
          'Modelos virtuais 3D e lâminas histológicas que permitem o estudo de diversas alterações morfológicas e funcionais do corpo humano. Promove o desenvolvimento do raciocínio clínico-epidemiológico.',
        pathology2:
          'A Patologia é a ciência que estuda as doenças, considerando seus mecanismos básicos e as alterações estruturais, bioquímicas e funcionais nas células, tecidos e órgãos associados. Além disso, estuda também os sintomas e as manifestações clínicas nos pacientes. Os processos e distúrbios patológicos podem ser compreendidos a partir de uma comparação com processos saudáveis, utilizando o conhecimento de outras ciências, como a Fisiologia, Citologia, Histologia etc. Assim, a patologia atua como um elo entre as ciências básicas e a medicina clínica, servindo como base científica para a formação de profissionais da saúde.',
        pathology3:
          'O módulo de Patologia está dividido em duas categorias: Histopatologia e Fisiopatologia. A primeira categoria conta com 100 lâminas histológicas que evidenciam patologias nos tecidos e células, dos diversos sistemas e regiões anatômicas do corpo humano. A segunda categoria contempla animações tridimensionais que representam processos fisiológicos não saudáveis (patológicos), destacando as alterações que acontecem no corpo nessas condições.',
        simulator:
          'Simulador virtual para experimentar na prática situações de emergência na clínica médica.',
        simulator2:
          'Unindo a gamificação aos treinamentos em saúde, oferecemos um módulo de Simulação Clínica 100% digital que oferece a sensação de uma experiência presencial, totalmente focada na tomada de decisão do aluno. Aprenda através de casos virtuais para salvar pacientes reais, baseados em problemas que promovem o raciocínio clínico.',
        features:
          'Navegue através dos detalhes do Corpo Humano em 3D de forma interativa a qualquer hora, em qualquer lugar!',
        VR: 'Envolva alunos e professores através do ambiente imersivo da Realidade Virtual',
        multiplatform:
          'Acesse através de qualquer navegador web, aplicativo Android ou iOS',
        animations:
          'Diversos conteúdos com animações 3D guiadas para fácil compreensão',
        accessControl:
          'Habilite, controle e analise os acessos de professores e alunos',
        integration: 'Embarque nosso conteúdo em seu site através de nossa API',
        languages:
          'Conteúdo mapeado e traduzido para português, espanhol e inglês',
        testimonial0_0:
          'O BioAtlas é uma excelente ferramenta para que o aluno integre os diferentes conteúdos das ciências biológicas e da saúde',
        testimonial0_1:
          'Professor de Fisiologia Humana e Neurociências na FMIT',
        newsletter:
          'Conheça o BioAtlas e suas possibilidades, garantindo o conhecimento em Saúde de sua Instituição em qualquer cenário do aprendizado.',
        platform:
          'Aprimore o aprendizado das disciplinas da saúde humana através do uso de conteúdos digitais interativos 3D em uma plataforma unificada.',
        modules:
          'Um ambiente virtual com milhares de possibilidades. Conteúdos referentes ao ensino da anatomia humana, como o Atlas Anatômico Humano Virtual 3D, o Cadáver Virtual 3D, conteúdos de Fisiologia, Histologia, Embriologia, Citologia, Simulação Médica e diversos outros.',
        cadaver:
          'Tenha a reconstrução 3D de um corpo humano real à disposição para dissecação virtual e complementar o ensino do laboratório de anatomia.',
        cadaver3D:
          'O Cadáver Virtual ilustra a anatomia de um corpo masculino real, com suas proporções e estruturas bem preservadas que revelam os detalhes da morfologia interna do organismo. A partir do congelamento e dissecção do corpo, todas as peças anatômicas foram fotografadas e reconstruídas em 3D a fim de facilitar a visualização e o estudo da anatomia humana.',
        atlas3D:
          'O Atlas Humano reúne as estruturas anatômicas que representam o corpo humano. A maioria das peças são similares às do corpo masculino e feminino, como nos sistemas Respiratório, Digestório, Muscular, Articular, Esquelético, Linfático e Endócrino. Já nos sistemas Urogenital, Arterial, Venoso, Nervoso e Tegumentar, algumas estruturas são diferentes, caracterizando cada sexo biológico.',
        blades:
          'Mais de 200 lâminas digitalizadas, podendo ampliar em até 40x, fazer marcações e muito mais!',
        simulations:
          'Simulador virtual para experimentar na prática situações de emergência na clínica médica em um ambiente 100% interativo.',
        contents1:
          'Explore o Corpo Humano em detalhes através de modelos virtuais 3D interativos',
        contents2:
          'Conteúdos que facilitam o aprendizado das disciplinas da saúde humana através a interatividade. Integra as áreas das Ciências Morfológicas, Ciências Fisiológicas, Ciências Humanas e Ciências Sociais Aplicadas à Saúde.',
        organs:
          'Aprenda sobre a anatomia humana de forma interativa através dos atlas anatômicos masculino e feminino, e por meio do cadáver virtual - uma reconstrução 3D de um corpo humano real. O estudo macroscópico do corpo humano está organizado em anatomia completa, que apresenta os sistemas e peças anatômicas de forma integrada; anatomia sistêmica e anatomia topográfica, que estudam estruturas que formam os sistemas orgânicos e regiões anatômicas.',
        cellStructure:
          'Navegue pelas células do corpo humano através de modelos interativos 3D, explorando as organelas que compõem os diferentes tipos celulares e suas funções. Além disso, um conjunto de lâminas histológicas permite ao usuário visualizar de forma microscópica diversas estruturas do corpo, como glândulas, órgãos e tecidos.',
        development:
          'Estude a fisiologia e embriologia humana através de conteúdos tridimensionais virtuais que exploram diferentes processos fisiológicos e as fases do desenvolvimento embrionário e fetal. Modelos animados possibilitam ao usuário visualizar e compreender o funcionamento do corpo humano, além de acompanhar os estágios de seu desenvolvimento - da 4ª semana de gestação até o nascimento.',
        specialties:
          'Explore o conhecimento médico de forma prática sob diferentes perspectivas. A radiologia pode ser estudada através de imagens DICOM, com ferramentas para manipulação de casos clínicos. Conheça o módulo de patologia, que utiliza modelos virtuais 3D e lâminas histológicas que permitem o estudo de diversas alterações morfológicas e funcionais do corpo humano. Além disso, acesse o simulador virtual para experimentar na prática situações de emergência na clínica médica.',
        survey:
          'Estamos te convidando a responder um pequeno formulário, é simples e rápido.',
        subtitleSuccessShareCollection: 'E-mails com acesso:',
        subtitleErrorShareCollection: 'Erro nos seguintes e-mails:'  
      },
      placeholders: {
        suggestion: 'Deixe sua sugestão',
        email: 'Digite seu email',
        phone: 'Digite seu telefone',
        password: 'Digite sua senha',
        newPassword: 'Crie sua senha',
        token: 'Digite o código',
        name: 'Digite seu nome',
        lastName: 'Digite seu sobrenome',
        idNumber: 'Digite o CPF (apenas números)',
        search: 'Pesquisar',
        searchQuestion: 'Qual conteúdo você deseja estudar?',
        resultsFor: 'Resultados para',
        find: 'Buscar',
        country: 'Digite seu País',
        profession: 'Digite sua profissão',
        countrySelect: 'Selecione seu País',
        professionSelect: 'Selecione sua profissão',
        institution: 'Digite sua instituição ou empresa',
        departmentSelect: 'Escolha um departamento',
        message: 'Escreva aqui sua mensagem',
        defaultCollectionTitle: 'Sem título',
        renameCollection: 'Adicione o nome para uma nova pasta ou modelo',
        moveCollection: 'Selecione a pasta que deseja mover',
        shareCollection: 'Digite o E-mail que deseja compartilhar',
        createFolder: 'Adicione o nome da nova pasta',
        newContent: 'O que há de novo'
      },
      labels: {
        email: 'Email',
        password: 'Senha',
        newPassword: 'Nova Senha',
        oldPassword: 'Senha atual',
        repeatPassword: 'Repita a senha',
        token: 'Informe o código recebido',
        firstName: 'Nome',
        lastName: 'Sobrenome',
        fullName: 'Nome completo',
        guardianName: 'Nome do Responsável',
        guardianIdNumber: 'CPF do Responsável',
        minorName: 'Nome do menor de idade',
        country: 'País',
        profession: 'Profissão',
        institution: 'Nome da Instituição ou Empresa',
        department: 'Em qual departamento deseja falar',
        isAfya: 'Sua instituição pertence ao Grupo Afya?',
        message: 'Mensagem',
        phone: 'Telefone',
        mobile: 'Telefone celular',
        language: 'Idioma',
        pt: 'Português',
        es: 'Español',
        en: 'English',
        undefined: 'Indefinido',
        description: 'Descrição',
        details: 'Detalhes',
        forbidden: 'Módulo não liberado',
        account: 'Conta',
        personalAccount: 'Conta Pessoal',
        corporateAccount: 'Conta Corporativa',
        legal: 'Jurídico',
        useTerms: 'Termos de Uso',
        politics: 'Política de Privacidade',
        cookies: 'Política de Cookies',
        requests: 'Pedidos de titular',
        dragFile: 'Arraste ou',
        uploadFile: 'faça o Upload',
        availability: 'Disponível em:',
        contactWhats: '+55 48 99958-0174',
        contactEmail: 'comercial@medicalharbour.com',
        newModule: 'Novo Módulo',
        new: 'Novo',
        updated: 'Atualizado',
        newContent: 'Novos conteúdos',
        step: 'Etapa',
        renameCollection: 'Nome da pasta ou modelo',
        moveCollection: 'Mover para',
        shareCollection: 'Compartilhar Modelo ou Pasta',
        createdAt: 'Criado em',
        folderName: 'Nome da pasta'
      },
      buttons: {
        signup: 'Cadastrar',
        signin: 'Iniciar sessão',
        enter: 'Acessar',
        save: 'Salvar',
        cancel: 'Cancelar',
        return: 'Voltar',
        exit: 'Sair',
        edit: 'Editar',
        update: 'Atualizar',
        view: 'Visualizar',
        subscribe: 'Assinar',
        send: 'Enviar',
        adjust: 'Ajustar',
        remember: 'Lembre-me',
        sendData: 'Enviar Dados',
        sendToken: 'Enviar email para redefinir',
        resendToken: 'Reenviar código',
        resetPassword: 'Redefinir senha',
        changePassword: 'Alterar Senha',
        changeProfile: 'Alterar Dados',
        changeLanguage: 'Alterar Idioma',
        changeAvatar: 'Alterar Foto',
        deleteAccount: 'Excluir Conta',
        confirmDelete: 'Excluir mesmo assim',
        cancelDelete: 'Não excluir',
        viewPlan: 'Conferir todos os serviços do seu Plano',
        finalizeAccount: 'Finalizar Cadastro',
        navigation: 'Noções de Navegação',
        shortcuts: 'Atalhos de Teclado',
        demo: 'Solicite uma Demonstração',
        discover: 'Quero conhecer o BioAtlas',
        openNewTab: 'Abrir link em nova aba',
        getNews: 'Receber novidades',
        knowMore: 'Saiba mais',
        knowContent: '$t(buttons.knowMore) sobre nosso conteúdo',
        knowModules: '$t(buttons.knowMore) sobre nossos módulos',
        knowCadaver: '$t(buttons.knowMore) sobre o cadáver virtual',
        askDemo: 'Peça uma demo para sua instituição',
        askDemoNow: 'Peça agora a demo para sua instituição',
        surveyNo: 'Não, obrigado',
        surveyYes: 'Vamos lá!',
        seeContent: 'Visualizar conteúdo',
        keepReading: 'continuar leitura no conteúdo',
        relatedContent: 'Conteúdos Relacionados',
        bibliography: 'Bibliografia',
        share: 'Compartilhar',
        rename: 'Renomear',
        duplicate: 'Duplicar',
        move: 'Mover',
        delete: 'Excluir',
        createFolder: 'Criar pasta',
        sendFeedback: 'Enviar meu feedback',
        seeAll: 'ver todos'
      },
      messages: {
        conjunction: 'e',
        separator: 'ou',
        agreeTerms1: 'Ao me cadastrar, eu concordo que li e aceito os',
        agreeTerms2: 'Eu concordo que li e aceito os',
        agreeTermsMinorS:
          'Sou menor de 18 anos e meu responsável legal autoriza a Medical Harbour a coletar meus dados pessoais.',
        agreeTermsMinorL:
          'Sou menor de 18 anos e meu responsável legal autoriza a Medical Harbour a coletar meus dados pessoais para fins de comunicações eletrônicas de ofertas e serviços (SMS, mensagens instantâneas, telefone, e-mail) de acordo com a $t(labels.politics).',
        validationSent: 'Enviamos um email para você validar seu cadastro!',
        contactAdmin:
          'Por favor, entre em contato com o Administrador do seu sistema!',
        accountReady: 'Seu acesso ao BioAtlas está ativo!',
        dataSharing:
          'Aceitar o compartilhamento de dados para ofertas de produtos',
        notifications:
          'Aceitar receber notificações pelo SMS, Whatsapp ou email?',
        deleteQuestion:
          'Tem certeza de que deseja deletar permanentemente sua conta?',
        deleteReminder1:
          'Excluir sua conta BioAtlas é uma ação permanente e não pode ser desfeita.',
        deleteReminder2:
          'Depois de excluir sua conta, você não poderá fazer login novamente no aplicativo e quaisquer modelos 3D que você criou e salvou em sua Biblioteca serão removidos de nosso banco de dados permanentemente e não podem ser restaurados.',
        trialWarning:
          'Você está usando a versão trial. Ainda falta {{count}} dia.',
        trialWarning_plural:
          'Você está usando a versão trial. Ainda faltam {{count}} dias.',
        trialUpgrade: 'Obter versão PRÓ.',
        noContent: 'Sem resultados para',
        newContent: 'Novos conteúdos que podem te interessar',
        noAccount: 'Você ainda não possui uma conta?',
        gotAccount: 'Você já possui uma conta?',
        passwordsDontMatch: 'Senhas precisam ser iguais!',
        notYetValidated:
          'Você ainda não validou seu cadastro pelo email que foi enviado!',
        notYetConfirmed:
          'Você ainda não confirmou seu cadastro. Verifique seu email.',
        confirmAccount: 'Confirme sua conta BioAtlas',
        notYetConfirmed1: 'Antes de alterar sua senha,',
        notYetConfirmed2: 'Antes de fazer login,',
        notYetConfirmed3: 'confirme sua conta no e-mail.',
        notYetConfirmed4:
          'Não esqueça de verificar também a caixa de spam, ok?',
        checkData: 'Verifique seus dados e tente novamente',
        loginInvalid: 'Usuário e/ou senha inválidos',
        userNotFound: 'Usuário não encontrado',
        wrongPassword: 'Senha atual está incorreta',
        tooManyRequests:
          'Você realizou muitas requisições. Aguarde um momento e tente novamente.',
        tooManyFailedAttempts: 'Você falhou em várias tentativas',
        tooManyAttempts: 'Você excedeu o limite de tentativas',
        tokenSent: 'Código enviado para o email: {{email}}',
        passwordChanged: 'Senha alterada com sucesso!',
        tokenInvalid:
          'Código fornecido inválido. Solicite um código novamente.',
        passwordTooShort: 'Senha precisa ter no mínimo 8 caracteres',
        tryAgain:
          'Houve um problema ao enviar suas informações. Tente novamente!',
        partialSuccessData:
          'Seus dados foram atualizados com sucesso, porém, houve um problema ao enviar sua foto.',
        partialSuccessImg:
          'Sua foto foi atualizada com sucesso, porém, houve um problema ao enviar seus dados.',
        invalidEmail: 'Informe um email válido',
        emailExists: 'Já existe uma conta com o email fornecido',
        phoneExists: 'Este número de telefone já está registrado',
        phoneTooShort: 'Número de telefone inválido.',
        idTooShort: 'Número de CPF inválido.',
        dataUpdated: 'Dados atualizados com sucesso!',
        collectionDeleted: 'Coleção excluída com sucesso!',
        alreadySignedUp:
          'Você já efetuou seu cadastro, acesse com seu login e senha!',
        invalidLink:
          'Este link não é válido, por favor entre em contato com o seu administrador!',
        multipleImages: 'Envie somente um arquivo',
        imageType:
          'Esse tipo de arquivo não é suportado. Extensões válidas: {{support}}',
        imageSize:
          'O arquivo é muito grande! ({{size}}) O tamanho máximo para upload é {{limit}}.',
        agreement:
          'Você precisa aceitar os $t(labels.useTerms) e a $t(labels.politics)',
        guardianData:
          'Por ser menor de idade, você precisa inserir os $t(titles.guardianData)',
        unreachable:
          'Nosso servidor está passando por dificuldades técnicas. Tente novamente mais tarde.',
        accessDenied: 'Por favor, realize seu login novamente.',
        unverifiedEmail: 'Erro: email não verificado',
        autoLogin: 'Estamos realizando seu login.',
        copySuccess: 'Copiado para a área de transferência!',
        copyError: 'Erro ao copiar!',
        unexistingModule: 'Conteúdo não existe',
        unavailable: 'Conteúdo não disponível',
        betterExp: 'Para ter uma melhor experiência, acesse pelo nosso aplicativo ou Desktop',
        desktop: 'Desktop',
        appAvailable: 'Faça o download do aplicativo BioAtlas nas lojas',
        support1: 'Por favor, entre em contato com nossa',
        support2: 'equipe de suporte',
        crash: 'Algo deu errado com o BioAtlas. $t(messages.support1)',
        appDenied:
          'Vimos que você não possui acesso ao BioAtlas. $t(messages.support1)',
        contactSuccess1: 'Olá! Acabamos de receber sua mensagem!',
        contactSuccess2:
          'Ficamos super felizes em saber que você quer conversar com a gente. Agradecemos muito pelo seu interesse pelo BioAtlas.',
        contactSuccess3:
          'Estamos analisando sua mensagem e logo entraremos em contato para responder às suas perguntas e comentários.',
        whichContents: 'Quais conteúdos você irá acessar:',
        errorCollection:
          'Desculpe, houve um erro ao tentar acessar suas coleções',
        emptyCollections: 'Parece que ainda não tem nenhuma coleção. Escolha qualquer modelo 3D, personalize do seu jeito e salve ;)',
        emptyCollectionsMobile: 'Parece que ainda não tem nenhuma coleção. Acesse seu aplicativo para visualizar suas coleções.',
        collectionRenamed: 'Coleção renomeada com sucesso!',
        folderRenamed: 'Pasta renomeada com sucesso!',
        collectionMoved: 'Coleção movida com sucesso!',
        collecionDeleted: 'Coleção deletada com sucesso!',
        collecionShared: 'Coleção compartilhada com sucesso!',
        collectionUnshared: 'Usuário removido do compartilhamento com sucesso!',
        collectionDuplicated: 'Coleção duplicada com sucesso!',
        collectionFolderCreated: 'Pasta criada com sucesso!',
        collectionFolderDuplicateErr: 'Pasta com esse nome já existe',
        demoPopUpText1: 'Você possui ',
        demoPopUpTextDays: 'dia ',
        demoPopUpTextDaysPlural: 'dias ',
        demoPopUpTextMonths: 'mês ',
        demoPopUpTextMonthsPlural: 'meses ',
        demoPopUpText2: ' de acesso demonstração',
        demoPopUpText3: ', sua opinião sobre nossa plataforma é muito importante.',
        demoExpiredText: 'A sua licença de demonstração expirou. Por favor, entre em contato com o nosso suporte.',
        demoExpiredBtn: 'Conversar com nossa equipe',
        errorInputShared: 'Não é possível compartilhar a coleção com esse email',
        by: 'Por',
        deleteCollectionMsgSingular: 'Ao excluir essa Pasta o seguinte modelo ou pasta também irá ser deletado:',
        deleteCollectionMsgPlural: 'Ao excluir essa Pasta os seguintes modelos ou pastas também irão ser deletados:',
        newContentTitle: 'Confira as novidades do BioAtlas',
        newContentSubtitle: 'Nova ferramenta para salvar suas coleções',
        newContentText: 'Agora você pode criar uma coleção personalizada de conteúdos 3D. Com o BioAtlas, é possível salvar esses modelos, organizá-los em pastas e compartilhá-los de forma simples e rápida com seus amigos e alunos.',
        newContentClose: 'Fechar',
        to: 'para',
        thankYouRating: 'Obrigado pela sua avaliação',
        pickRateError: 'Por favor, escolha uma nota para este conteúdo ou altere sua nota/sugestão anterior antes de enviar'
      },
      swal: {
        success: 'Sucesso',
        warning: 'Atenção',
        error: 'Um problema ocorreu!',
        access: 'Você não possui acesso',
        crash: 'Opps!!!'
      },
      banner: {
        title: 'Você Sabia?',
        curiosity1:
          'O encéfalo humano tem, aproximadamente, 86 bilhões de neurônios. Apenas 19% deles estão localizados no córtex cerebral, enquanto o cerebelo contém cerca de 80%. O restante está localizado no tronco encefálico.',
        curiosity2:
          'O corpo humano brilha no escuro. Esse processo consiste na emissão de biofótons, caracterizado por reações químicas que emitem pequenas quantidades de luz nas nossas células. Não percebemos esse brilho a olho nu pois a intensidade é muito mais fraca do que podemos detectar.'
      },
      alts: {
        load: 'Animação de carregamento',
        logo: 'Logo do BioAtlas',
        eye: 'Ícone de olho para habilitar visibilidade da senha',
        avatar: 'Foto de perfil do usuário',
        edit: 'Ícone de edição',
        computer: 'Imagem de um computador alertando que chegou um email',
        check: 'Animação de um ícone de check'
      },
      modules: {
        cytology: 'Citologia',
        transports: 'Transportes de Membrana',
        histology: 'Histologia',
        systemicAnatomy: 'Anatomia Sistêmica',
        topographicAnatomy: 'Anatomia Topográfica',
        completeAnatomy: 'Anatomia Completa',
        physiology: 'Fisiologia',
        embryology: 'Embriologia',
        radiology: 'Radiologia'
      },
      categories: {
        all: 'Todos',
        tissues: 'Tecidos',
        systems: 'Sistemas',
        organs: 'Órgãos Sensoriais',
        atlas: 'Atlas Humano',
        atlas2: 'Atlas Anatômico',
        cadaver: 'Cadáver Virtual'
      },
      subCategories: {
        epithelial: 'Tecido Epitelial',
        connective: 'Tecido Conjuntivo',
        circulatory: 'Circulatório',
        articular: 'Articular',
        digestive: 'Digestório',
        endocrine: 'Endócrino',
        skeletal: 'Esquelético',
        integumentary: 'Tegumentar',
        lymphoid: 'Linfático',
        muscular: 'Muscular',
        nervous: 'Nervoso',
        respiratory: 'Respiratório',
        urogenital: 'Urogenital',
        male: 'Masculino',
        female: 'Feminino'
      },
      helpers: {
        rotateMouse:
          'Segure o botão esquerdo do mouse e mova para rotacionar o objeto',
        moveMouseBlade:
          'Segure o botão esquerdo do mouse e arraste para mover a lâmina',
        moveMouseModel:
          'Segure o botão direito do mouse para mover o objeto na tela',
        selectMouse:
          'Clique uma vez com o botão esquerdo para selecionar uma estrutura',
        zoomMouse:
          'Utilize o botão scroll do mouse para aumentar ou diminuir zoom',
        textboxMouse:
          'Pressione o botão Shift no teclado, pressione o botão esquerdo do mouse e arraste',
        cameraKeyboard:
          'Utilize a tecla (W) para mover para cima, tecla (S) para mover para baixo, tecla (A) para mover para esquerda e tecla (D) para mover para direita',
        rotateKeyboard:
          'Segure o botão direito do mouse para mover o objeto na tela',
        zoomKeyboard: 'Utilize as teclas de + e - para aplicar o zoom',
        moveKeyboard: 'Utilize as setas para movimentar a lâmina',
        rotatePad: 'Segure o botão esquerdo do trackpad e mova para rotacionar',
        movePad:
          'Segure o botão direito do trackpad e movimente a câmera do objeto',
        zoomPad:
          'Clique uma vez com o botão esquerdo para selecionar uma estrutura'
      },
      home: {
        banner: {
          title: 'Conteúdo Virtual Interativo 3D para Ensino na Área da Saúde',
          subtitle:
            'Explore conteúdos digitais interativos 3D para Ensino do Ciclo Básico em Saúde através de um navegador web, aplicativo Android/iOS e óculos de Realidade Virtual'
        },
        specs: {
          title:
            'Navegue através dos detalhes do Corpo Humano a qualquer hora, em qualquer lugar!',
          item1: 'Acesso Multiplataforma',
          text1:
            'Acesse através de qualquer navegador web, aplicativo Android ou iOS, sem necessidade de plugins, não requer instalação',
          item2: 'Controle de Acesso',
          text2:
            'Habilite, controle e análise os acessos de professores e alunos',
          item3: 'Disponível em 3 línguas',
          text3:
            'Conteúdo mapeado e traduzido para português, espanhol e inglês',
          item4: 'Integração',
          text4: 'Embarque nosso conteúdo em seu site através de nossa API',
          item5: 'Animações Interativas 3D',
          text5:
            'Diversos conteúdos com animações 3D guiadas para fácil compreensão',
          item6: 'Realidade Virtual 3D',
          text6:
            'Envolva alunos e professores através do ambiente imersivo da Realidade Virtual'
        },
        minibanner: 'Reduza os custos do laboratório de Anatomia',
        allInOne: {
          title:
            'Uma suíte multiplataforma para acesso a uma Biblioteca Digital Interativa de conteúdos 3D para Ensino em Saúde',
          text: 'O BioAtlas é uma solução que se adapta diversas ferramentas tecnológicas do mercado e permite navegar através de Conteúdos Digitais Interativos 3D para saúde utilizando qualquer navegador web ou app mobile Android ou iOS e Óculos de Realidade Virtual'
        },
        teaser: 'Conheça um pouco mais sobre o BioAtlas',
        dashboard: {
          title:
            'Dashboard com dados para acompanhamento de indicadores de uso',
          subtitle:
            'Mais inteligência para acompanhamento de uso do sistema e resultados a seu favor',
          text1:
            'Analise o uso, engajamento, performance e outras métricas importantes para melhorar o aprendizado',
          text2: 'Obtenha insights das interações dos usuários'
        },
        quotes: {
          text1:
            'O BioAtlas é uma excelente ferramenta para que o aluno integre os diferentes conteúdos das ciências biológicas e da saúde',
          position: 'Professor de Fisiologia Humana e Neurociências na FMIT'
        },
        conclusion:
          'Conheça o BioAtlas, suas possibilidades, e garanta o conhecimento em Saúde de sua Instituição em qualquer cenário de aprendizado.'
      },
      contents: {
        banner: {
          title:
            'Explore o Corpo Humano em detalhes através de modelos virtuais 3D interativos',
          subtitle:
            'O BioAtlas permite navegar através do corpo humano de maneira virtual totalmente interativa utilizando qualquer navegador web, smartphone ou óculos de Realidade Virtual.',
          obs: '*Está disponível em 3 línguas: Português, Inglês e Espanhol'
        },
        systems: {
          title: 'Sistemas do corpo humano',
          completeAnatomy: {
            text1:
              'conta com mais de 2500 estruturas de todos os sistemas anatômicos do corpo humano etiquetados. Este módulo está separado em modelo Masculino e Feminino e organizado de acordo com 12 sistemas anatômicos e 9 regiões.',
            text2:
              'é a reconstrução 3D de um corpo humano real que foi doado para pesquisa*. Foi congelado, dividido em 1878 fatias e fotografado com equipamentos de altíssima resolução. O Cadáver Virtual possui as cores preservadas, está segmentado e mapeado para visualização completa.',
            obs1: '*A versão 3D em Realidade Virtual - VR - é uma forma imersiva para o entendimento e a compreensão do corpo humano a um passo do metaverso.',
            obs2: '*Saiba mais sobre o Projeto Visible Human'
          },
          topographicAnatomy: {
            text: 'Navegue nos Elementos Descritivos do Crânio e pelas regiões anatômicas: cabeça e pescoço, membro superior direito, membro superior esquerdo, dorso, tórax, abdome, pelve, membro inferior direito e membro inferior esquerdo.'
          },
          systemicAnatomy: {
            text: 'Sistemas Anatômicos: tegumentar, muscular, esquelético, articular, nervoso, linfático, digestório, respiratório, arterial, venoso, endócrino e urogenital.'
          },
          physiology: {
            text: 'Explore o funcionamento e as funções normais do corpo humano, através de conteúdos virtuais 3D interativos:',
            item1: 'Ciclo Cardíaco do Coração',
            item2: 'Contração da Fibra Muscular',
            item3: 'Geração e Condução de Potencial de Ação',
            item4: 'Hematose',
            item5: 'Transmissão Sináptica',
            item6: 'Ventilação Pulmonar'
          }
        },
        cellStructure: {
          title: 'Estrutura celular e microanatomia',
          cytology: {
            title: 'Citologia 3D',
            text: 'O módulo de Citologia permite ao usuário interagir com duas categorias de conteúdos 3D: Células e Membrana Celular. Navegue nas células: Célula Eucarionte de origem animal, Célula Sanguínea, Neurônio e Fibra Muscular. Visualize um modelo de Membrana Celular em 3D e cinco animações relacionadas ao transporte de moléculas pela membrana:',
            item1: 'Difusão simples',
            item2: 'Difusão facilitada',
            item3: 'Bomba de sódio e potássio',
            item4: 'Co-transporte',
            item5: 'Contra-transporte'
          },
          histology: {
            text: 'O BioAtlas possui um módulo dedicado ao estudo de lâminas de glândulas, órgãos, tecidos e sistemas, e lâminas com patologias. Além de possuir uma ferramenta com recrusos para visualização e análise das mesmas.'
          }
        },
        development: {
          title: 'Desenvolvimento humano',
          embryology: {
            text: 'Acompanhe o desenvolvimento embrionário e fetal, a partir da 4ª semana de gestação até o nascimento, através de animações interativas 3D.'
          }
        },
        specialties: {
          title: 'Especialidades médicas',
          radiology: {
            text: 'Sistema para visualização de imagens DICOM com ferramentas para manipulação de estudos e casos clínicos radiológicos. Permite visualizar imagens de diversas modalidades como Tomografias Computadorizadas, Ressonâncias Magnéticas, Raio-X, entre outras através do navegador web.'
          }
        },
        conclusion: 'Entre em contato para conhecer o BioAtlas'
      },
      integration: {
        banner: {
          title: 'Adicione a interatividade 3D do BioAtlas a seu conteúdo',
          subtitle:
            'Dê vida ao seu projeto da área da saúde adicionando sua marca ao BioAtlas ou embarcando nossas animações, peças virtuais interativas através da integração BioAtlas API.'
        },
        api: {
          label: 'API BioAtlas',
          title:
            'O conteúdo 3D do BioAtlas em seu projeto através de nossa API',
          subtitle:
            'Construa uma experiência interativa virtual 3D em seu projeto relacionado à anatomia ou outras disciplinas relacionadas ao ciclo básico em Saúde. O conteúdo do BioAtlas pode ser facilmente integrável a seu website, conteúdo digital, LMS ou projeto digital através da BioAtlas API.'
        },
        institutions: {
          label: 'Instituições',
          title: 'BioAtlas para sua instituição',
          item1: 'Farmacêutica',
          item2: 'Universidades',
          item3: 'Hospitais',
          item4: 'Editoras'
        },
        whiteLabel: {
          label: 'White Label',
          title: 'BioAtlas com a marca da sua Instituição',
          subtitle:
            'O BioAtlas possui uma solução “Private Label”, que permite uma colocar marca da sua Instituição tornando uma solução 100% customizada.'
        }
      }
    }
  }
}

export { messages }
