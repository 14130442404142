import React, { useEffect, useRef, useState } from 'react'
import * as S from './styles'
import { FormWrapper } from '@/components/Forms/styles'

import EditStyleModal from '../../Layouts/Edit'

import { useModal } from '@/contexts/ModalContext'

import { validations, changeInputClass } from '@/services/functions'
import { idTooShort } from '@/services/userAlerts'

import { useTranslation } from 'react-i18next'

const EditGuardianDataModal = ({ config }) => {
  const { t } = useTranslation()
  const { closeModal } = useModal()

  const [titlesGuardianData, setTitlesGuardianData] = useState('')
  const [labelsGuardianName, setLabelsGuardianName] = useState('')
  const [labelsGuardianIdNumber, setLabelsGuardianIdNumber] = useState('')
  const [labelsMinorName, setLabelsMinorName] = useState('')
  const [placeholdersName, setPlaceholdersName] = useState('')
  const [placeholdersIdNumber, setPlaceholdersIdNumber] = useState('')
  const [messagesAgreeTermsMinorL, setMessagesAgreeTermsMinorL] = useState('')

  const guardianNameRef = useRef(config.guardianName)
  const guardianIdRef = useRef(config.guardianID)
  const minorNameRef = useRef(config.minorName)

  let post = false
  let guardianNameValidation = false
  let idNumberValidation = false
  let minorNameValidation = false

  useEffect(() => {
    setTitlesGuardianData(t('titles.guardianData'))
    setLabelsGuardianName(t('labels.guardianName'))
    setLabelsGuardianIdNumber(t('labels.guardianIdNumber'))
    setLabelsMinorName(t('labels.minorName'))
    setPlaceholdersName(t('placeholders.name'))
    setPlaceholdersIdNumber(t('placeholders.idNumber'))
    setMessagesAgreeTermsMinorL(t('messages.agreeTermsMinorL'))
  }, [t])

  useEffect(() => {
    changeInputClass(
      'inp_lastname',
      config.guardianName,
      post,
      guardianNameValidation
    )
  }, [config.guardianName, post, guardianNameValidation])

  useEffect(() => {
    changeInputClass(
      'inp_lastname',
      config.minorName,
      post,
      minorNameValidation
    )
  }, [config.minorName, post, minorNameValidation])

  useEffect(() => {
    changeInputClass('inp_phone', config.guardianID, post, idNumberValidation)
  }, [config.guardianID, post, idNumberValidation])

  const validateData = () => {
    guardianNameValidation = validations.required(
      config.guardianName,
      'inp_guardian_name',
      post
    )
    minorNameValidation = validations.required(
      config.minorName,
      'inp_minor_name',
      post
    )
    idNumberValidation = validations.idNumber(
      config.guardianID,
      'inp_guardian_id',
      post
    )

    if (!guardianNameValidation || !minorNameValidation) {
      return false
    }

    if (!idNumberValidation) {
      idTooShort()
      return false
    }

    return true
  }

  const resetInfo = () => {
    config.guardianNameHandler(guardianNameRef.current)
    config.guardianIDHandler(guardianIdRef.current)
    config.minorNameHandler(minorNameRef.current)
    closeModal()
  }

  const saveInfo = () => {
    post = true
    if (!validateData()) return

    closeModal()
  }

  const handleSubmit = e => {
    //Para evitar que recarregue a página
    e.preventDefault()
    saveInfo()
  }

  return (
    <EditStyleModal
      name={titlesGuardianData}
      confirmCallback={saveInfo}
      cancelCallback={resetInfo}
      contentHTML={
        <S.GuardianDataContainer>
          <p className="subtitle">{messagesAgreeTermsMinorL}</p>

          <FormWrapper onSubmit={handleSubmit}>
            <section>
              <label>{labelsGuardianName + '*'}</label>
              <input
                id="inp_guardian_name"
                type="text"
                placeholder={placeholdersName}
                value={config.guardianName}
                onChange={e => config.guardianNameHandler(e.target.value)}
              />
            </section>

            <section>
              <label>{labelsGuardianIdNumber + '*'}</label>
              <input
                id="inp_guardian_id"
                type="text"
                placeholder={placeholdersIdNumber}
                value={config.guardianID}
                onChange={e => config.guardianIDHandler(e.target.value)}
                onKeyDown={e => {
                  const key = e.key
                  if (
                    (isNaN(key) &&
                      key !== 'Backspace' &&
                      key !== 'Delete' &&
                      key !== 'ArrowLeft' &&
                      key !== 'ArrowRight' &&
                      key !== 'ArrowUp' &&
                      key !== 'ArrowDown' &&
                      key !== 'Tab') ||
                    key === ' '
                  ) {
                    e.preventDefault()
                    return false
                  }
                }}
              />
            </section>

            <section>
              <label>{labelsMinorName + '*'}</label>
              <input
                id="inp_minor_name"
                type="text"
                placeholder={placeholdersName}
                value={config.minorName}
                onChange={e => config.minorNameHandler(e.target.value)}
              />
            </section>

            <input type="submit" hidden />
          </FormWrapper>
        </S.GuardianDataContainer>
      }
    />
  )
}

export default EditGuardianDataModal
