export const LOCAL_KEYS = {
  client_language: 'i18nextLng',
  user_persistence: 'ba_user_persistence',
  user_image: 'ba_user_image',
  user_image_id: 'ba_user_image_id',
  survey_answered: 'ba_survey',
  survey_answered2: 'ba_survey2',
  survey_answered3: 'ba_survey3',
  survey_answered4: 'ba_survey4',
  content_tree: 'ba_tree',
  new_content1: 'new_content1',
  seen_content: 'seen_content'
}

export const SESSION_KEYS = {
  session_user: 'ba_session_user',
  token_ttl: 'ba_token_ttl',
  navigation_data: 'ba_nav_data',
  client_alert: 'ba_swal_client',
  snowplow_uuid: 'ba_snowplow',
  demo_popup_was_closed: 'ba_demo_popup_was_closed'
}  

export const clearStorage = () => {
  localStorage.removeItem(LOCAL_KEYS.client_language)
  localStorage.removeItem(LOCAL_KEYS.user_persistence)
  localStorage.removeItem(LOCAL_KEYS.user_image)
  localStorage.removeItem(LOCAL_KEYS.user_image_id)
  localStorage.removeItem(LOCAL_KEYS.content_tree)
  localStorage.removeItem(LOCAL_KEYS.seen_content)

  sessionStorage.removeItem(SESSION_KEYS.session_user)
  sessionStorage.removeItem(SESSION_KEYS.token_ttl)
  sessionStorage.removeItem(SESSION_KEYS.navigation_data)
  sessionStorage.removeItem(SESSION_KEYS.client_alert)
  sessionStorage.removeItem(SESSION_KEYS.ba_snowplow)
}

export const setWithExpiry = (key, value, ttl) => {
  const now = new Date()
  const item = { value: value, expiry: now.getTime() + ttl }
  localStorage.setItem(key, JSON.stringify(item))
}

export const setWithFixedExpiry = (key, value, expiration) => {
  const item = { value: value, expiry: expiration }
  localStorage.setItem(key, JSON.stringify(item))
}

export const getWithExpiry = key => {
  const itemStr = localStorage.getItem(key)
  if (!itemStr) return null

  const item = JSON.parse(itemStr)
  const now = new Date()
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key)
    return null
  }

  return item.value
}

export const getParsed = (storage, key) => {
  const str = storage.getItem(key)
  if (str) {
    return JSON.parse(str)
  }

  return null
}
