import styled from 'styled-components'

export const NavIconContainer = styled.div`
  margin: 2rem 0;
  min-height: 55px;
  span {
    margin: 0 1rem;
    font-size: 14px;
  }
  .icon1 {
    max-width: 33%;
  }
  .icon2 {
    max-width: 40%;
  }
`
