import React, { useEffect, useRef, useState } from 'react'

import EditStyleModal from '../../Layouts/Edit'
import FormEditAvatar from '@/components/Forms/EditAvatar'
import FormCropAvatar from '@/components/Forms/EditAvatar/CropAvatar'

import {
  ENDPOINTS,
  sendPostRequest,
  handleResponseStates
} from '@/services/api'
import { setCachedAlert, onUncaughtError } from '@/services/userAlerts'
import { LOCAL_KEYS, setWithExpiry } from '@/services/browserStorage'
import { convertToMiliseconds, updateUserImageId } from '@/services/functions'

import { useAuth } from '@/contexts/AuthContext'
import { useModal } from '@/contexts/ModalContext'

import { useTranslation } from 'react-i18next'

const EditAvatarModal = () => {
  const { t } = useTranslation()
  const { user, getUserAvatar } = useAuth()
  const { settings, closeModal } = useModal()

  const [outputImg, setOutputImg] = useState()
  const [inputImg, setInputImg] = useState()
  const [fileName, setFileName] = useState('')
  const [fileSize, setFileSize] = useState('')
  const [oldFileName, setOldFileName] = useState('')
  const [oldFileSize, setOldFileSize] = useState('')
  const [showCropper, setShowCropper] = useState(false)

  const [titleModalAvatar, setTitleModalAvatar] = useState('')
  const [titleModalCropper, setTitleModalCropper] = useState('')
  const [buttonsAdjust, setButtonsAdjust] = useState('')

  const didMount = useRef(false)
  const cropperRef = useRef()

  useEffect(() => {
    setTitleModalAvatar(t('buttons.changeAvatar'))
    setTitleModalCropper(`${t('titles.adjustImage')} - ${t('labels.step')} 1/2`)
    setButtonsAdjust(t('buttons.adjust'))
  }, [t])

  useEffect(() => {
    if (didMount.current) return

    const img = getUserAvatar(150, 150)
    setOutputImg(img)
    didMount.current = true
  }, [])

  const confirmCrop = async () => {
    settings.config.loadHandler(true)
    const canvas = await cropperRef.current.cropToCanvas()
    const img = cropperRef.current.getImgAsBase64(canvas)
    setOutputImg(img)
    setOldFileName(fileName)
    setOldFileSize(fileSize)
    setShowCropper(false)
    setTitleModalAvatar(`${titleModalAvatar} - ${t('labels.step')} 2/2`)
    settings.config.loadHandler(false)
  }

  const cancelCrop = () => {
    setFileName(oldFileName)
    setFileSize(oldFileSize)
    setInputImg()
    setShowCropper(false)
  }

  const onAvatarUpdated = () => {
    const alert = {
      icon: 'success',
      title: 'swal.success',
      html: 'messages.dataUpdated'
    }
    setCachedAlert(alert)
    setWithExpiry(
      LOCAL_KEYS.user_image,
      outputImg,
      convertToMiliseconds(0, 0, 0, 30)
    )
    settings.config.loadHandler(true)
    window.location.reload()
  }

  const updateUserAvatar = async () => {
    settings.config.loadHandler(true)

    const dataSplit = outputImg.split(';')
    const mime = dataSplit[0].split(':')[1]
    const image = dataSplit[1]
    const newImageId = updateUserImageId()

    await sendPostRequest(ENDPOINTS.upload_user_img, {
      id: user.idDB,
      image: image,
      mime: mime
    })
      .then(response => {
        handleResponseStates(response, responsesUpdate)
      })
      .catch(error => {
        const msg = error.data || error
        console.error('updateUserAvatar error: ', msg)
        onUncaughtError()
      })
      .finally(() => {
        settings.config.loadHandler(false)
      })
  }

  const responsesUpdate = [{ name: 200, callback: onAvatarUpdated }]

  return showCropper ? (
    <EditStyleModal
      name={titleModalCropper}
      confirmText={buttonsAdjust}
      confirmCallback={confirmCrop}
      cancelCallback={cancelCrop}
      contentHTML={
        <FormCropAvatar inputImgState={inputImg} cropperRef={cropperRef} />
      }
    />
  ) : (
    <EditStyleModal
      name={titleModalAvatar}
      confirmCallback={updateUserAvatar}
      cancelCallback={closeModal}
      contentHTML={
        <FormEditAvatar
          outputImgState={outputImg}
          fileNameState={fileName}
          fileNameHandler={setFileName}
          fileSizeState={fileSize}
          fileSizeHandler={setFileSize}
          inputImgHandler={setInputImg}
          cropperHandler={setShowCropper}
        />
      }
    />
  )
}

export default EditAvatarModal
