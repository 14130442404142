import React, { useEffect, useState } from 'react'
import * as S from './styles'

const BannerVideoModal = ({ config }) => {
  return (
    config && (
      <S.BannerModal>
        <button
          type="button"
          id="btn_modal_close"
          onClick={() => config.videoHandler('')}
        >
          <span className="material-icons-round">close</span>
        </button>

        <video id="gif-video" preload="auto" playsInline loop muted autoPlay>
          <source type="video/webm" src={`${config.videoSrc}.webm`} />
          <source type="video/mp4" src={`${config.videoSrc}.mp4`} />
          Your browser does not support the video tag.
        </video>
      </S.BannerModal>
    )
  )
}

export default BannerVideoModal
