import React, { useEffect, useState } from 'react'

import { useModal } from '@/contexts/ModalContext'
import { onUncaughtError } from '@/services/userAlerts'

import {
  ENDPOINTS,
  sendPostRequest,
  handleResponseStates
} from '@/services/api'

import { useTranslation } from 'react-i18next'
import { i18n } from '@/translate/i18n'
import * as S from './styles'
import Swal from 'sweetalert2'

const RenameCollectionModal = ({ config }) => {
  const { closeModal } = useModal()

  const { t } = useTranslation()

  const [renameText, setRenameText] = useState()
  const [labelText, setLabelText] = useState()
  const [placeholderText, setPlaceholderText] = useState()
  const [cancelText, setCancelText] = useState()

  const [newName, setNewName] = useState()

  useEffect(() => {
    setRenameText(`${t('buttons.rename')}`)
    setLabelText(`${t('labels.renameCollection')}`)
    setPlaceholderText(`${t('placeholders.renameCollection')}`)
    setCancelText(`${t('buttons.cancel')}`)
  }, [t])

  const onSuccess = () => {
    config.setAction(!config.action)
    Swal.fire({
      icon: 'success',
      title: i18n.t('swal.success'),
      html: i18n.t('messages.collectionRenamed')
    })
  }

  const onSuccessFolder = () => {
    config.setAction(!config.action)
    // eslint-disable-next-line promise/catch-or-return
    Swal.fire({
      icon: 'success',
      title: i18n.t('swal.success'),
      html: i18n.t('messages.folderRenamed')
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload()
      }
    })
  }

  const responsesRename = [{ name: 200, callback: onSuccess }]
  const responsesRenameFolder = [{ name: 200, callback: onSuccessFolder }]

  const renameCollection = async e => {
    e.preventDefault()
    config.setLoading(true)
    closeModal()
    const body = {
      id: config.collectionId,
      name: e.target.children[1].value
    }
    await sendPostRequest(ENDPOINTS.rename_collection, body)
      .then(response => {
        handleResponseStates(response, config.folder ? responsesRenameFolder : responsesRename)
      })
      .catch(error => {
        const msg = error.data || error
        console.log(msg)
        onUncaughtError()
      })
      .finally(() => {
        config.setLoading(false)
      })
  }

  return (
    config && (
      <S.RenameCollectionContainer>
        <S.RenameCollectionTitle>
          <p>{renameText}</p>
          <button id="btn_closemodal_rename" type="button" onClick={closeModal}>
            <span className="material-icons">close</span>
          </button>
        </S.RenameCollectionTitle>

        <form onSubmit={renameCollection}>
          <label htmlFor={renameText}>{labelText}</label>
          <input
            type="text"
            placeholder={placeholderText}
            name={renameText}
            onChange={e => setNewName(e.target.value)}
            required
            maxLength={50} 
            // eslint-disable-next-line no-nested-ternary
            value={newName ? newName : newName === "" ? null : config.collectionName}
            id="inp_rename"
          />
          <div>
            <button type="button" onClick={closeModal} id="btn_cancel_rename">
              {cancelText}
            </button>
            <button type="submit" id="btn_rename">{renameText}</button>
          </div>
        </form>
      </S.RenameCollectionContainer>
    )
  )
}

export default RenameCollectionModal
