import axios from 'axios'

import { accessDenied, loginAgain, serverUnreachable } from './userAlerts'
import { compareStrings } from './functions'

export const apiPortal = axios.create({
  baseURL: process.env.API_URL,
  withCredentials: true
})

export const apiPortalRevokeVet = axios.create({
  baseURL: process.env.API_URL,
  withCredentials: true
})

apiPortalRevokeVet.interceptors.request.use(function (request) {
  request.headers['X-Application'] = 'vet'
  return request
})


apiPortalRevokeVet.interceptors.response.use(
  function (response) {
    if (!response) {
      console.log('TESTE server unreachable')
    }
    return response
  },

  function (error) {
    if (error.response){
      
      if (error.response.status === 0) {
        console.log('TESTE network error', error)
      } else if (
        (error.response.status === 401 || error.response.status === 403) &&
        error.config.url !== ENDPOINTS.sign_out
      ) {
        console.log('TESTE access denied', error)
      }
      return error.response
    } 
    return error
  }
)

apiPortal.interceptors.request.use(function (request) {
  const appSpecificEndpoints = [
    ENDPOINTS.refresh_token,
    ENDPOINTS.sign_out,
    ENDPOINTS.get_user_info,
    ENDPOINTS.get_organization_info,
    ENDPOINTS.update_user_info,
    ENDPOINTS.upload_user_img,
    ENDPOINTS.get_tree,
    ENDPOINTS.sign_in,
    ENDPOINTS.sign_up,
    ENDPOINTS.signup_confirm,
    ENDPOINTS.set_new_password,
    ENDPOINTS.request_password,
    ENDPOINTS.confirm_password,
    ENDPOINTS.change_password,
    ENDPOINTS.save_collection,
    ENDPOINTS.get_collections,
    ENDPOINTS.duplicate_collection,
    ENDPOINTS.create_folder,
    ENDPOINTS.share_collection,
    ENDPOINTS.move_collection,
    ENDPOINTS.delete_collection,
    ENDPOINTS.get_collection,
    ENDPOINTS.tree_collections,
    ENDPOINTS.rename_collection,
    ENDPOINTS.get_solucx_nps,
    ENDPOINTS.get_content_rating,
    ENDPOINTS.rate_content
  ]

  //  unitySpecificEndpoints = [ENDPOINTS.duplicate_collection, ENDPOINTS.move_collection]

  if (appSpecificEndpoints.includes(request.url)) {
    request.headers['X-Application'] = 'bioatlas'
  }

  /* if (unitySpecificEndpoints.includes(request.url)) {
    request.headers['x-unity-user-agent'] = 'web'
  } */

  // console.log(request)
  return request
})

apiPortal.interceptors.response.use(
  function (response) {
    // console.log(response)

    if (!response) {
      // console.log('TESTE server unreachable')
      serverUnreachable()
      return
    }

    return response
  },

  function (error) {
    if (error?.config.headers && error?.code) {
      console.error('-> ' + error?.message + ' ' + JSON.stringify({'name': error?.name, 'code': error?.code, 'headers': error.config.headers}))
    }

    if (error.response){
      if (error.response.status === 0) {
        console.log('TESTE network error', error)
        loginAgain()
        return
      } else if (
        (error.response.status === 401 || error.response.status === 403) &&
        error.config.url !== ENDPOINTS.sign_out
      ) {
        console.log('TESTE access denied', error)
        accessDenied()
        return
      }
      return error.response
    }
    return error
  }
)

export const sendPostRequest = (URL, data, optionalHeaders) => {
  let headers = { 'Content-Type': 'application/json;charset=UTF-8' }

  if (optionalHeaders) {
    headers = { ...headers, ...optionalHeaders }
  }

  let config = { parse: true, headers: headers }

  return apiPortal.post(URL, JSON.stringify(data), config)
}

export const sendGetRequest = (URL, optionalHeaders) => {
  let headers = {};

  if (optionalHeaders) {
    headers = { ...headers, ...optionalHeaders }
  }

  let config = { parse: true, headers: headers }

  return apiPortal.get(URL, config)
}

export const sendRevokeVetRequest = URL => {
  let headers = {}
  const config = {
    parse: true,
    headers: headers
  }

  return apiPortalRevokeVet.get(URL, config)
}

export const handleResponseStates = (response, listedStates) => {
  // console.log('TESTE handleResponseStates response', response)
  const matchingState = listedStates.find(state => {
    if (typeof state.name === 'number') {
      return state.name === response.status
    } else {
      return (
        compareStrings(state.name, response.data.status) ||
        compareStrings(state.name, response.data.msg)
      )
    }
  })

  if (matchingState) {
    // console.log('TESTE handleResponseStates matchingState', matchingState)
    matchingState.callback(response.data)
    return
  }

  //UNHANDLED EXCEPTION
  const error = new Error('Uncaught Exception')
  error.data = response
  throw error
}

export const ENDPOINTS = {
  sign_in: 'auth/signin',
  sign_out: 'auth/revoke-token',
  refresh_token: 'auth/refresh-token',
  auth_ip: 'adminv2/ip_login',
  auth_sso: 'authv2/sso',

  set_new_password: 'users/set-new-password',
  change_password: 'users/change-password',
  request_password: 'users/forgot-password',
  confirm_password: 'users/forgot-password-confirm',

  sign_up: 'users/signup',
  signup_confirm: 'users/signup-confirm',

  get_user_info: 'usersv2/get-user-info',
  update_user_info: 'usersv2/update-user',
  upload_user_img: 'usersv2/upload-user-image',
  get_organization_info: 'adminv2/get-organization-info',
  get_tree: 'portalv2/get-tree',
  get_class_info: 'adminv2/get-class-info',
  get_content_info: 'portalv2/get-content-info',

  get_slides: 'portalv2/get-slides',
  create_slide_marker: 'portalv2/create-slide-marker',
  get_solucx_nps: 'usersv2/get-solucx-nps',

  // get_collections: 'admin2/tree-collections', TODO: VOLTAR AQUI PARA TREE-COLLECTIONS
  get_collections: 'portalv2/list-collections',
  save_collection: 'portalv2/save-collection',
  move_collection: 'portalv2/move-collection',
  tree_collections: 'portalv2/tree-collections',
  get_collection: 'portalv2/get-collection',
  rename_collection: 'portalv2/rename-collection',
  delete_collection: 'portalv2/delete-collection',
  duplicate_collection: 'portalv2/duplicate-collection',
  share_collection: 'portalv2/share-collection',
  create_folder: 'portalv2/create-folder',
  update_folder: 'portalv2/update-folder',
  delete_folder: 'portalv2/delete-folder',
  list_related_users: 'usersv2/list-related-users',
  rate_content: 'portalv2/rate-content',
  get_content_rating: 'portalv2/get-content-rating',
  check_content_license: 'adminv2/check-content-license',
  pre_auth_sso: 'authv2/pre-auth-sso'
}
