import styled from 'styled-components'

export const FileUploaderContainer = styled.div`
  position: relative;
`

export const DropContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--grey5);
  border: 1px dashed var(--black);
  border-radius: 0.3125rem;
  min-height: 80px;

  input {
    display: none !important;
  }

  .link {
    text-decoration: underline;
    cursor: pointer;
  }
`
