import styled from 'styled-components'

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
`

export const ModalBody = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 95%;

  &.with-background {
    background: var(--light);
    border-radius: 5px;
  }

  &.viewer-help {
    width: 100%;
    height: 100%;
    max-width: 60%;
    max-height: 60%;
  }

  &.edit-info {
    width: 100%;
    max-height: 95%;
    overflow-y: auto;

    @media only screen and (min-width: 650px) {
      max-width: 576px;
    }
  }

  @media only screen and (max-width: 550px) {
    > div {
      min-width: 28rem;
    }
  }

  @media only screen and (max-width: 400px) {
    > div {
      min-width: 25rem;
    }
  }
`
