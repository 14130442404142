import styled from 'styled-components'

export const ButtonMenuContainer = styled.div`
  text-align: end;

  button {
    display: initial;
  }

  & > *:not(:last-child) {
    margin-right: 1rem;
  }
`
