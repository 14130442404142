import React, { useEffect, useState } from 'react'

import { useModal } from '@/contexts/ModalContext'
import { onUncaughtError } from '@/services/userAlerts'
import Swal from 'sweetalert2'
import { i18n } from '@/translate/i18n'

import {
  ENDPOINTS,
  sendPostRequest,
  handleResponseStates
} from '@/services/api'

import { useTranslation } from 'react-i18next'
import * as S from './styles'

const DeleteCollectionModal = ({ config }) => {
  const { closeModal } = useModal()

  const { t } = useTranslation()

  const [deleteText, setDeleteText] = useState()
  const [titleText, setTitleText] = useState()
  const [cancelText, setCancelText] = useState()
  const [msgSingularText, setMsgSingularText] = useState()
  const [msgPluralText, setMsgPluralText] = useState()
  const [seeAllText, setSeeAllText] = useState()

  const [showChildList, setShowChildList] = useState()

  useEffect(() => {
    setDeleteText(`${t('buttons.delete')}`)
    setTitleText(`${t('titles.deleteCollections')}`)
    setCancelText(`${t('buttons.cancel')}`)
    setSeeAllText(`${t('buttons.seeAll')}`)
    setMsgPluralText(`${t('messages.deleteCollectionMsgPlural')}`)
    setMsgSingularText(`${t('messages.deleteCollectionMsgSingular')}`)
  }, [t])

  const onSuccess = () => {
    config.setAction(!config.action)
    Swal.fire({
      icon: 'success',
      title: i18n.t('swal.success'),
      html: i18n.t('messages.collectionDeleted')
    })
  }

  const responsesDelete = [{ name: 200, callback: onSuccess }]

  const deleteCollection = async () => {
    const body = {
      id: config.collectionId
    }
    config.setLoading(true)
    closeModal()
    await sendPostRequest(ENDPOINTS.delete_collection, body)
      .then(response => {
        handleResponseStates(response, responsesDelete)
      })
      .catch(error => {
        const msg = error.data || error
        console.log(msg)
        onUncaughtError()
      })
      .finally(() => {
        config.setLoading(false)
      })
  }


  return (
    config && (
      <S.DeleteCollectionContainer>

        <S.DeleteCollectionTitle>
          <p>
            {titleText} {config.name}?
          </p>
          <button id="btn_closemodal_delete" type="button" onClick={closeModal}>
            <span className="material-icons">close</span>
          </button>
        </S.DeleteCollectionTitle>

          {!!config.child.length && (
            <S.DeleteCollectionContent>
              <p id="msg">{config.child.length > 1 ? msgPluralText : msgSingularText} {' '}</p>
              {!showChildList && (
                <>
                  {config.child.map((item, index) => {
                    return index < 3 && (
                      <span
                        key={item.id}
                      >
                        {item.name}{index !== 2 && index !== config.child.length - 1 && ', '}
                      </span>
                    )
                  })}
                  {config.child.length > 3 && <span>... </span>}
                  {config.child.length > 3 && <button onClick={() => setShowChildList(true)} id="show_list">{seeAllText}</button>}
                </>
              )}
              {showChildList && (
                <div>
                  {config.child.map((item) => (
                    <p key={item.id} id="items">{item.name}</p>
                  ))}
                </div>
              )}
            </S.DeleteCollectionContent>
          )}

        <S.DeleteCollectionButtons>
          <button type="cancel" id="btn_cancel_delete" onClick={closeModal}>
            {cancelText}
          </button>
          <button type="submit" id="btn_delete" onClick={deleteCollection}>
            {deleteText}
          </button>
        </S.DeleteCollectionButtons>
      </S.DeleteCollectionContainer>
    )
  )
}

export default DeleteCollectionModal
