import styled from 'styled-components'

export const DeleteCollectionContainer = styled.div`
  background: var(--light);
  border-radius: 10px;
  padding: 1.8rem 0;
  min-width: 36rem;
  width: 47vw;
  max-width: 500px;

  button {
    background-color: transparent;
  }
`

export const DeleteCollectionTitle = styled.div`
  border-bottom: 1px solid var(--grey4);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.25rem 1.56rem 2.25rem;

  p {
    color: var(--dark);
    font-size: 1.5rem;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden; 
    word-break: break-all;
  }
`

export const DeleteCollectionContent = styled.div`
  padding: 1.5rem 2rem;

  p {
    font-weight: 400;
    font-size: 14px;
  }
  
  #msg {
    color: var(--grey15);
    display: inline;
    line-height: 22px;
  }
  
  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
  }

  button {
    color: var(--blue2);
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    text-decoration: underline;
  }

  > div {
    width: 100%;
    max-height: 189px;
    border-radius: 10px;
    background-color: var(--grey5);
    margin-top: 1.5rem;
    padding: 1rem;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: var(--grey4);
      border-radius: 5px;

    }
    
    ::-webkit-scrollbar-thumb {
      background: var(--grey3); 
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: var(--grey3); 
    }
  }

  #items {
    color: var(--grey2);
    margin-bottom: 0.5rem;
  }
`

export const DeleteCollectionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  gap: 1.25rem;
  padding: 0 2.25rem 1.4rem 2.25rem;

  button {
    padding: 0.7rem 1.56rem;
    border-radius: 5px;
    font-size: 0.875rem;
    font-weight: 500;
  }

  button[type='submit'] {
    background-color: var(--error);
    color: var(--light);
  }

  button[type='cancel'] {
    border: 1px solid var(--grey11);
    color: var(--grey14);
  }
`
