import styled from 'styled-components'

export const DecorativeCircleContainer = styled.div`
  position: absolute;
  background: var(--blue2);
  opacity: 0.06;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  top: ${props => props.top};
  left: ${props => props.left};
  width: ${props => props.radius}px;
  height: ${props => props.radius}px;

  div {
    position: absolute;
    background: var(--light);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: ${props => props.radius - props.thickness}px;
    height: ${props => props.radius - props.thickness}px;
  }
`
