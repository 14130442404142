import React from 'react'
import * as S from './styles'

import { useModal } from '@/contexts/ModalContext'

import AlertStyleModal from './Layouts/Alert'
import ContentDetailsModal from './Templates/ContentDetails'
import EditAvatarModal from './Templates/EditAvatar'
import EditLanguageModal from './Templates/EditLanguage'
import EditPasswordModal from './Templates/EditPassword'
import EditPersonalDataModal from './Templates/EditPersonalData'
import EditGuardianDataModal from './Templates/EditGuardianData'
import ViewerHelpModal from './Templates/ViewerHelp'
import BannerVideoModal from './Templates/BannerVideo'
import RenameCollectionModal from './Templates/RenameCollection'
import DeleteCollectionModal from './Templates/DeleteCollection'
import MoveCollectionModal from './Templates/MoveCollection'
import MoveCollectionDropModal from './Templates/MoveCollectionDrop'
import CreateFolderCollectionModal from './Templates/CreateFolderCollectionModal'
import SharedListCollections from './Templates/SharedListCollections'
import NewContentModal from './Templates/NewContentModal'

const Modal = () => {
  const { showModal, settings, MODAL_TYPES } = useModal()

  const getModalByType = type => {
    switch (type) {
      case MODAL_TYPES.alert:
        return <AlertStyleModal config={settings.config} />

      case MODAL_TYPES.content_details:
        return <ContentDetailsModal config={settings.config} />

      case MODAL_TYPES.edit_avatar:
        return <EditAvatarModal />

      case MODAL_TYPES.edit_language:
        return <EditLanguageModal />

      case MODAL_TYPES.edit_password:
        return <EditPasswordModal />

      case MODAL_TYPES.edit_data:
        return <EditPersonalDataModal />

      case MODAL_TYPES.edit_guardian_data:
        return <EditGuardianDataModal config={settings.config} />

      case MODAL_TYPES.viewer_help:
        return <ViewerHelpModal />

      case MODAL_TYPES.banner_video:
        return <BannerVideoModal config={settings.config} />

      case MODAL_TYPES.rename_collection:
        return <RenameCollectionModal config={settings.config} />
      
      case MODAL_TYPES.delete_collection:
        return <DeleteCollectionModal config={settings.config} />

      case MODAL_TYPES.move_collection:
        return <MoveCollectionModal config={settings.config} />

      case MODAL_TYPES.create_collection_folder:
        return <CreateFolderCollectionModal config={settings.config} />

      case MODAL_TYPES.shared_list_collection:
        return <SharedListCollections config={settings.config} />

      case MODAL_TYPES.new_content:
        return <NewContentModal config={settings.config} />

      case MODAL_TYPES.move_collection_drop:
        return <MoveCollectionDropModal config={settings.config} />

      default:
        return null
    }
  }

  return (
    showModal && (
      <S.Overlay id="ba_modal_overlay">
        <S.ModalBody id="ba_modal" className={settings.classes}>
          {getModalByType(settings.type)}
        </S.ModalBody>
      </S.Overlay>
    )
  )
}

export default Modal
