const URL_IGLU = 'iglu:br.com.medicalharbour'
const URL_MH = 'https://medicalharbour.com'
const SOCIAL_NAME = 'thebioatlas'

export const PAGE_PATHS = {
  landing: '/',
  contents: '/Contents',
  integration: '/Integration',
  contact: '/Contact',
  contact_success: '/Contact/Success',
  collections: '/Collections',
  signin: '/Signin',
  signup: '/Signup',
  signout: '/Signout',
  confirm_account: '/ConfirmAccount',
  finalize_account: '/finalyaccount',
  auth: '/Auth',
  home: '/Home',
  description: '/Description',
  profile: '/Profile',
  viewer: '/Viewer',
  viewer3D: '/Viewer3D',
  simsave: '/SimSave',
  dicom: '/DICOM',
  authSSO: '/AuthSSO',
  embedded: '/Embedded',

  blog: 'https://blog.bioatlas.medicalharbour.com/?utm_source=site-bioatlas&utm_medium=botao-blog-menu-superior&utm_campaign=blog_bioatlas_medical_harbour&utm_term=bioatlas%2C+medical+harbour%2C+blog+bioatlas',
  medicalharbour: URL_MH,
  privacy: `${URL_MH}/politica-de-privacidade`,
  terms: `${URL_MH}/bioatlas/termos-de-uso`,
  cookies: `${URL_MH}/politica-de-cookies`,
  requests: `${URL_MH}/pedidodotitular`,
  facebook: `https://facebook.com/${SOCIAL_NAME}`,
  instagram: `https://www.instagram.com/${SOCIAL_NAME}`,
  youtube: `https://youtube.com/@${SOCIAL_NAME}`,
  zendesk_home: 'https://medicalharbour.zendesk.com/hc',
  zendesk_bioatlas: 'sections/7771906494996-BioAtlas',
  request_bioatlas: 'requests/new',
  student_survey: 'https://t.maze.co/300549846',
  teacher_survey: 'https://t.maze.co/299652709',
  student_survey_intercompany: 'https://t.maze.co/299688555',
  teacher_survey_intercompany: 'https://t.maze.co/302084514',
  visible_human: 'https://www.nlm.nih.gov/research/visible/visible_human.html'
}

export const ASSET_PATHS = {
  default_avatar: '/assets/defaults/avatar.webp',
  default_module: '/assets/defaults/module.webp',
  default_content: '/assets/defaults/content.webp',
  default_detail: '/assets/defaults/detail.webp',
  default_quoter: '/assets/defaults/quoter.webp',

  icon_edit: '/assets/images/edit_squared.png',
  icon_success: '/assets/images/Success.svg',
  icon_error: '/assets/images/Error.svg',
  icon_facebook: `${process.env.CDN_URL}/public/icons/social/facebook.svg`,
  icon_instagram: `${process.env.CDN_URL}/public/icons/social/instagram.svg`,
  icon_tiktok: `${process.env.CDN_URL}/public/icons/social/tiktok.svg`,
  icon_youtube: `${process.env.CDN_URL}/public/icons/social/youtube.svg`,
  icon_whatsapp: `${process.env.CDN_URL}/public/icons/social/whatsapp.svg`,
  icon_info: '/assets/defaults/info.svg',
  icon_folder: '/assets/defaults/folder.svg',
  icon_folder_droppable: '/assets/defaults/folder_droppable.svg',
  icon_emoji: '/assets/defaults/emoji_smiling.svg',
  icon_new_content: '/assets/defaults/new_content.svg',
  icon_close: '/assets/defaults/close.svg',
  icon_shared: '/assets/defaults/icon_shared.svg',
  icon_star_grey: '/assets/defaults/star_grey.svg',

  animation_check: '/assets/animations/check.gif',
  animation_load: '/assets/animations/loading.gif',

  logo: `${process.env.CDN_URL}/public/logos/bioatlas-cropped.svg`,
  logoPurple: `/assets/images/bioatlaslogopurple.png`,

  dots_grid: `${process.env.CDN_URL}/public/decorations/dots_grid.svg`,
  quotes: `${process.env.CDN_URL}/public/decorations/quotes.svg`,

  img_rating: '/assets/images/rating_img.png',

  applestore_pt: '/assets/images/appstore_pt.png',
  applestore_es: '/assets/images/appstore_es.png',
  applestore_en: '/assets/images/appstore_en.png',
  playstore_pt: '/assets/images/playstore_pt.png',
  playstore_es: '/assets/images/playstore_es.png',
  playstore_en: '/assets/images/playstore_en.png',
}

export const SNOWPLOW_SCHEMAS = {
  entity_user: `${URL_IGLU}/user/jsonschema/1-0-2`,
  entity_collection: `${URL_IGLU}/collection_item/jsonschema/2-0-0`,
  entity_module: `${URL_IGLU}/module_content/jsonschema/1-0-1`,

  event_login: `${URL_IGLU}/login/jsonschema/1-0-2`,
  event_logout: `${URL_IGLU}/logout/jsonschema/1-0-0`,
  event_button_click: `${URL_IGLU}/button_click/jsonschema/2-0-18`,
  event_input_text: `${URL_IGLU}/input_text/jsonschema/1-0-2`,
  event_view: `${URL_IGLU}/view/jsonschema/2-0-0`,

  input_header_search: 'homepage_header_search',
  input_content_filter: 'homepage_content_filter',

  button_content: 'homepage_content',
  button_content_description: 'homepage_content_description',
  button_module_description: 'homepage_module_description',
  button_category: 'homepage_category',
  button_back_homepage: 'viewer_back_to_homepage',
  menu_my_collection: 'menu_collection',
  menu_new_content: 'menu_new_content',
  collection_item_menu: 'collection_item_menu',
  collection_item_click_open: 'collection_item_click_open',
  collection_item_menu_share: 'collection_item_menu_share',
  collection_item_menu_share_confirm: 'collection_item_menu_share_confirm',
  collection_item_menu_move: 'collection_item_menu_move',
  collection_item_menu_rename: 'collection_item_menu_rename',
  collection_item_menu_delete: 'collection_item_menu_delete',
  collection_item_menu_duplicate: 'collection_item_menu_duplicate',
  content_page_bibliography_click: 'content_page_bibliography_click',
  content_page_action: 'content_page_action',
  content_page_related_click: 'content_page_related_click',
  view: 'view',

  slides_menu_expand: 'slides_menu_expand',
  slides_menu_info: 'slides_menu_info',
  slides_menu_rotate: 'slides_menu_rotate',
  slides_menu_turn: 'slides_menu_turn',
  slides_menu_textbox: 'slides_menu_textbox',
  slides_menu_pin: 'slides_menu_pin',
  slides_menu_rule: 'slides_menu_rule',
  slides_menu_arrow: 'slides_menu_arrow',
  slides_menu_fullscreen: 'slides_menu_fullscreen',

  radio_length_ruler: 'radio_lenght_ruler',
  radio_length_bidirectional: 'radio_lenght_bidirectional',
  radio_length_annotation: 'radio_lenght_annotation',
  radio_length_ellipse: 'radio_lenght_ellipse',
  radio_zoom: 'radio_zoom',
  radio_window_level: 'radio_window_level',
  radio_window_level_soft: 'radio_window_level_soft',
  radio_window_level_lung: 'radio_window_level_lung',
  radio_window_level_liver: 'radio_window_level_liver',
  radio_window_level_bone: 'radio_window_level_bone',
  radio_window_level_brain: 'radio_window_level_brain',
  radio_pan: 'radio_pan',
  radio_capture: 'radio_capture',
  radio_grid_layout: 'radio_grid_layout',
  radio_mpr: 'radio_mpr',
  radio_crosshairs: 'radio_crosshairs',
  radio_reset_view: 'radio_reset_view',
  radio_rotate_right: 'radio_rotate_right',
  radio_flip: 'radio_flip',
  radio_stack_image: 'radio_stack_image',
  radio_reference_lines: 'radio_reference_lines',
  radio_stack_scroll: 'radio_stack_scroll',
  radio_invert: 'radio_invert',
  radio_probe: 'radio_probe',
  radio_cine: 'radio_cine',
  radio_angle: 'radio_angle',
  radio_magnify: 'radio_magnify',
  radio_rectangle: 'radio_rectangle',
  radio_calibration: 'radio_calibration',
  radio_dicom_tag: 'radio_dicom_tag',
  radio_studies: 'radio_studies',
  radio_change_view: 'radio_change_view',
}

export const EXTERNAL_PATHS = {
  feedback_en: `https://forms.office.com/pages/responsepage.aspx?id=6Oy8YGvrTkmmB-g_zc7zM9Hkimbf6RFEukfcK46N69JUNFFEUzdFVFFQUEdTNThISEs5RkNSSUwwWi4u`,
  feedback_es: `https://forms.office.com/pages/responsepage.aspx?id=6Oy8YGvrTkmmB-g_zc7zM0uwT313s61NjQDxDnCp5JtURTBYWjRMMDZVNVVMM1kzSzFUUVQzU1pWQy4u&origin=lprLink`,
  feedback_pt: `https://forms.office.com/pages/responsepage.aspx?id=6Oy8YGvrTkmmB-g_zc7zM9Hkimbf6RFEukfcK46N69JUMEZUSkc1NFg4OEJMMjg5MUJGT0ZPS0I5MS4u`,
  appStoreLink: 'https://apps.apple.com/br/app/bioatlas/id1632056156',
  playStoreLink: 'https://play.google.com/store/apps/details?id=com.medicalharbour.bioatlas&pli=1',
  appLink: 'bioatlas://auth',
}
