import React, { useState, useEffect } from 'react'

import { useModal } from '@/contexts/ModalContext'

import { useTranslation } from 'react-i18next'

import * as S from './styles'

const videoContent = '/assets/videos/teaser.mp4'

const NewContentModal = ({ config }) => {
  const { t } = useTranslation()
  const { closeModal } = useModal()

  const [titleText, setTitleText] = useState()
  const [subtitleText, setSubtitleText] = useState()
  const [descriptionText, setDescriptionText] = useState()
  const [closeText, setCloseText] = useState()

  useEffect(() => {
    setTitleText(`${t('messages.newContentTitle')}`)
    setSubtitleText(`${t('messages.newContentSubtitle')}`)
    setDescriptionText(`${t('messages.newContentText')}`)
    setCloseText(`${t('messages.newContentClose')}`)
  }, [t])

  const closeContent = () => {
    closeModal()
    config.registerUserAnswer()
  }

  return (
    <S.NewContentModalWrapper>
      <h3>{titleText}</h3>
      <S.VideoWrapper>
        <video controls muted autoPlay>
          <source src={videoContent} type="video/mp4" />
        </video>
      </S.VideoWrapper>
      <p id="subtitle">{subtitleText}</p>
      <p id="text">{descriptionText}</p>
      <button onClick={closeContent}>{closeText}</button>
    </S.NewContentModalWrapper>
  )
}

export default NewContentModal
