import React, { useEffect, useState } from 'react'
import * as S from '@/components/AlertScreen/CrashScreen/styles'

import AlertScreen from '@/components/AlertScreen'

import { ASSET_PATHS } from '@/services/paths'
import { getLocalizedZendeskLink } from '@/services/language'

import { useTranslation } from 'react-i18next'

const AppAccessScreen = () => {
  const { t } = useTranslation()

  const [swalCrash, setSwalCrash] = useState('')
  const [swalAccess, setSwalAccess] = useState('')
  const [messagesAppDenied, setMessagesAppDenied] = useState('')
  const [messagesSupport, setMessagesSupport] = useState('')

  useEffect(() => {
    setSwalCrash(t('swal.crash'))
    setSwalAccess(t('swal.access'))
    setMessagesAppDenied(t('messages.appDenied'))
    setMessagesSupport(t('messages.support2'))
  }, [t])

  return (
    <AlertScreen
      id="screen-alert"
      titleHtml={
        <S.CrashScreenTitle className="mb-4">{`${swalCrash} ${swalAccess}`}</S.CrashScreenTitle>
      }
      textHtml={
        <S.CrashScreenText>
          <span>{`${messagesAppDenied} `}</span>
          <a
            href={getLocalizedZendeskLink()}
            rel="noreferrer"
            target="_blank"
            id="btn_help"
          >
            {`${messagesSupport}.`}
          </a>
        </S.CrashScreenText>
      }
      imgURL={`${process.env.CDN_URL}/public/alerts/app_access.webp`}
      imgWidth={496}
      imgHeight={394}
      logoURL={ASSET_PATHS.logo}
      logoWidth={232}
      logoHeight={92}
      circlesInfo={[{ top: '91%', left: '4%', radius: 176, thickness: 40 }]}
    />
  )
}

export default AppAccessScreen
