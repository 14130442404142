import { i18n } from '@/translate/i18n'
import Swal from 'sweetalert2'

import { PAGE_PATHS } from './paths'
import { SESSION_KEYS, getParsed } from './browserStorage'
import { exitApp } from './functions'

export const setCachedAlert = alert => {
  sessionStorage.setItem(SESSION_KEYS.client_alert, JSON.stringify(alert))
}

export const fireCachedAlert = () => {
  const alert = getParsed(sessionStorage, SESSION_KEYS.client_alert)
  // console.log("TESTE fireCachedAlert alert", alert)
  if (!alert) return

  Swal.fire({
    icon: alert.icon,
    title: i18n.t(alert.title),
    html: i18n.t(alert.html)
  })
  sessionStorage.removeItem(SESSION_KEYS.client_alert)
}

export const onTokenSent = email => {
  Swal.fire({
    icon: 'success',
    title: i18n.t('swal.success'),
    html: i18n.t('messages.tokenSent', { email: email })
  })
}

export const onPasswordReset = (autoLoginHandler, email, password) => {
  let message = i18n.t('messages.passwordChanged')
  if (autoLoginHandler) {
    message += ' ' + i18n.t('messages.autoLogin')
  }

  Swal.fire({
    icon: 'success',
    title: i18n.t('swal.success'),
    html: message,
    didClose: () => {
      if (autoLoginHandler) {
        autoLoginHandler(email, password, true)
      }
    }
  })
}

export const onAccountFinalized = autoLoginHandler => {
  Swal.fire({
    icon: 'success',
    title: i18n.t('swal.success'),
    html: i18n.t('messages.accountReady') + ' ' + i18n.t('messages.autoLogin'),
    didClose: autoLoginHandler
  })
}

export const onInvalidFinalizeToken = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.invalidLink')
  })
}

export const onAlreadySignedUp = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.alreadySignedUp'),
    didClose: exitApp
  })
}

export const onUncaughtError = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.tryAgain')
  })
}

export const codError = (message, callback) => {

  Swal.fire({
    icon: 'error',
    title: i18n.t('swal.error'),
    html: i18n.t('messages.contactAdmin') + '<br>' + message + '<br>' + new Date(),
    didClose: () => callback && callback()
  })
}

export const loginAgain = () => {
  Swal.fire({
    icon: 'error',
    title: i18n.t('swal.error'),
    html: i18n.t('messages.accessDenied'),
    didClose: exitApp
  })
}

export const contactAdmin = callback => {
  Swal.fire({
    icon: 'error',
    title: i18n.t('swal.error'),
    html: i18n.t('messages.contactAdmin'),
    didClose: () => callback && callback()
  })
}

export const invalidEmail = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.invalidEmail')
  })
}

export const invalidPassword = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.wrongPassword')
  })
}

export const passwordTooShort = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.passwordTooShort')
  })
}

export const passwordsDontMatch = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.passwordsDontMatch')
  })
}

export const phoneTooShort = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.phoneTooShort')
  })
}

export const onPhoneDuplicatedError = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.phoneExists')
  })
}

export const idTooShort = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.idTooShort')
  })
}

export const guardianAgreementRequired = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.guardianData')
  })
}

export const legalAgreementRequired = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.agreement')
  })
}

export const accessDenied = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.accessDenied'),
    didClose: exitApp
  })
}

export const serverUnreachable = () => {
  Swal.fire({
    icon: 'error',
    title: i18n.t('swal.error'),
    html: i18n.t('messages.unreachable'),
    didClose: exitApp
  })
}

export const contentNotAvailable = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.unavailable'),
    didClose: () => (window.location = PAGE_PATHS.home)
  })
}

export const contentDoesntExist = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.unexisting'),
    didClose: () => (window.location = PAGE_PATHS.home)
  })
}

export const dataUpdated = () => {
  Swal.fire({
    icon: 'success',
    title: i18n.t('swal.success'),
    html: i18n.t('messages.dataUpdated')
  })
}

export const onUserError = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.loginInvalid')
  })
}

export const onExpiredCode = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.tokenInvalid')
  })
}

export const onTooManyRequests = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.tooManyRequests')
  })
}

export const onTooManyFailedAttempts = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.tooManyFailedAttempts')
  })
}

export const onUserNotFound = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.userNotFound')
  })
}

export const onLimitExceeded = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.tooManyAttempts')
  })
}

export const onInvalidParameter = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.checkData')
  })
}

export const onMultipleFilesException = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.multipleImages')
  })
}

export const onFileTypeException = () => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.imageType', { support: '.jpg, .png, .webp' })
  })
}

export const onFileSizeException = (fileSize, limit = '10 MB') => {
  Swal.fire({
    icon: 'warning',
    title: i18n.t('swal.warning'),
    html: i18n.t('messages.imageSize', { size: fileSize, limit: limit })
  })
}
