import React, { useEffect, useState } from 'react'

import { useModal } from '@/contexts/ModalContext'
import { useAuth } from '@/contexts/AuthContext'

import {
  ENDPOINTS,
  sendPostRequest,
  handleResponseStates
} from '@/services/api'
import { onUncaughtError } from '@/services/userAlerts'
import { ASSET_PATHS, SNOWPLOW_SCHEMAS } from '@/services/paths'

import SmallLoading from '@/components/SmallLoading'
import ImageNext from '@/components/ImageNext'

import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import { i18n } from '@/translate/i18n'
import { trackSelfDescribingEvent } from '@snowplow/browser-tracker'

import * as S from './styles'

const ShareCollectionModal = ({ config }) => {
  const { closeModal } = useModal()
  const { user } = useAuth()

  const { t } = useTranslation()

  const [shareText, setShareText] = useState()
  const [labelText, setLabelText] = useState()
  const [placeholderText, setPlaceholderText] = useState()
  const [cancelText, setCancelText] = useState()
  const [successSubtitleText, setSuccessSubtitleText] = useState()
  const [errorSubtitleText, setErrorSubtitleText] = useState()
  const [errorInputText, setErrorInputText] = useState()

  const [showLoading, setShowLoading] = useState(false)
  const [allUsers, setAllUsers] = useState()
  const [suggestionsList, setSuggestionsList] = useState([])
  const [showLists, setShowLists] = useState(false)
  const [emailsList, setEmailsList] = useState([])
  const [successList, setSuccessList] = useState()
  const [errorsList, setErrorsList] = useState()
  const [hasAccessList, setHasAccessList] = useState([])
  const [getUsers, setGetUsers] = useState()
  const [errorInput, setErrorInput] = useState(false)
  const [currentInputValue, setCurrentInputValue] = useState('')
  const [removedUsers, setRemovedUsers] = useState({})
  const [alreadySelectedUsers, setAlreadySelectedUsers] = useState({})


  useEffect(() => {
    setShareText(`${t('buttons.share')}`)
    setLabelText(`${t('labels.shareCollection')}`)
    setPlaceholderText(`${t('placeholders.shareCollection')}`)
    setCancelText(`${t('buttons.cancel')}`)
    setSuccessSubtitleText(`${t('subtitles.subtitleSuccessShareCollection')}`)
    setErrorSubtitleText(`${t('subtitles.subtitleErrorShareCollection')}`)
    setErrorInputText(`${t('messages.errorInputShared')}`)
  }, [t])

  useEffect(() => {
    async function fetchUsers() {
      setShowLoading(true)
      const body = {
        attributes: ["id","email","id_v1"],
        collection_id: config.collectionId
      }
      await sendPostRequest(ENDPOINTS.list_related_users, body)
      .then(response => {
        const users = response.data.msg.Items
        setAllUsers(users)
        
      })
      .catch(error => {
        const msg = error.data || error
        console.log(msg)
        onUncaughtError()
      })
      .finally(() => {
        setShowLoading(false)
      })
    }
    fetchUsers()
  }, [getUsers])

  useEffect(() => {
    if (config.sharedList.length) {
      setHasAccessList(config.sharedList)
      setShowLists(true)
    }
  }, [])

  const onChangeInput = async e => {
    const insertedValue = e.target.value
    setCurrentInputValue(insertedValue)
    
    if (e.nativeEvent.inputType === "deleteContentBackward" && insertedValue === '') {
      setErrorInput(false)
      return
    }
  
    if (insertedValue.length < 3) {
      setSuggestionsList([])
      return
    }

    if (insertedValue.length < 3 && e.nativeEvent.inputType === "deleteContentBackward") {
      setSuggestionsList([])
      return
    }

    let currentUsers
    if (insertedValue) {
      currentUsers = allUsers.filter(item => {
        const lowerCaseInsertedValue = insertedValue.toLowerCase()
        return item.email.includes(lowerCaseInsertedValue)
      })
      setSuggestionsList(currentUsers)
    }

    if (insertedValue.length > 6 && insertedValue.includes('@') && !currentUsers.length) {
      setErrorInput(true)
    } else {
      setErrorInput(false)
    }
  }

  const onClickEmail = value => {
    const newEmails = emailsList
    newEmails.push(value)
    setEmailsList(newEmails)

    let alreadySelectedUsersList = alreadySelectedUsers
    alreadySelectedUsersList[value.id_v1] = value.id_v1
    setAlreadySelectedUsers(alreadySelectedUsersList)
    const newUsers = allUsers.filter((item => {
      return !alreadySelectedUsersList[item.id_v1] && item.id_v1
    }))
    setAllUsers(newUsers)

    setSuccessList([])
    setErrorsList([])
    setHasAccessList([])
    setShowLists(true)
    setSuggestionsList([])
    resetCurrentInput()
  }

  const resetCurrentInput = () => {
    setCurrentInputValue('')
  }

  const removeEmail = value => {
    const newEmails = emailsList.filter(item => item !== value)
    setEmailsList(newEmails)

    const id = value.id_v1
    const newSelectedUsers = alreadySelectedUsers
    delete newSelectedUsers[id]

    const newUsers = [...allUsers, value]
    setAllUsers(newUsers)
  }

  const onSuccess = () => {
    // config.setAction(!config.action)
    /* Swal.fire({
      icon: 'success',
      title: i18n.t('swal.success'),
      html: i18n.t('messages.collecionShared')
    }) */
  }

  const responsesShare = [{ name: 200, callback: onSuccess }]

  const shareCollection = async e => {
    config.setLoading(true)
    e.preventDefault()
    trackSelfDescribingEvent({
      event: {
        schema: SNOWPLOW_SCHEMAS.event_button_click,
        data: {
          button_name: SNOWPLOW_SCHEMAS.collection_item_menu_share_confirm
        }
      },
      context: [
        {
          schema: SNOWPLOW_SCHEMAS.entity_collection,
          data: {
            id: config.collectionId,
            content_id: config.content_id,
            type: config.type,
            name: config.name,
            origin_user_email: config.origin_user_email,
            origin_id: config.origin_id,
            shares: `${config.shares}`
          }
        },
        {
          schema: SNOWPLOW_SCHEMAS.entity_module,
          data: {
            content_id: `${config.content_id}`,
            module_id: null
          }
        }
      ]
    })
    const body = {
      id: config.collectionId,
      share_with_users_id: emailsList.map(item => `${item.id_v1}`),
      origin_user_email: user.email
    }
    await sendPostRequest(ENDPOINTS.share_collection, body)
      .then(response => {
        handleResponseStates(response, responsesShare)
        setEmailsList([])
        setSuccessList(response.data.success)
        setErrorsList(response.data.error)
      })
      .catch(error => {
        const msg = error.data || error
        console.log(msg)
        onUncaughtError()
      })
      .finally(() => {
        config.setLoading(false)
        setEmailsList([])
        setShowLists(true)
      })
  }

  const onSuccessUnshare = (id, email) => {
    setGetUsers(!getUsers)

    let removedUsersList = removedUsers
    removedUsersList[id] = email
    setRemovedUsers(removedUsersList)
    const newList = config.sharedList.filter((item => {
      return !removedUsers[item.id_v1] && item.id_v1
    }))
    setHasAccessList(newList)
  
    setAllUsers([...allUsers, { id_v1: id, email: email }])
  
    Swal.fire({
      icon: 'success',
      title: i18n.t('swal.success'),
      html: i18n.t('messages.collectionUnshared')
    })
  }

  const unshareCollection = async (value) => {
    config.setLoading(true)
    const body = {
      id: config.collectionId,
      user_id: value.id_v1,
    }
    await sendPostRequest(ENDPOINTS.delete_collection, body)
      .then(response => {
        if (response.status === 200) {
          onSuccessUnshare(value.id_v1, value.email)
        }
      })
      .catch(error => {
        const msg = error.data || error
        console.log(msg)
        onUncaughtError()
      })
      .finally(() => {
        config.setLoading(false)
        setShowLists(true)
      })
  }

  const closeModalShare = () => {
    config.setAction(!config.action)
    closeModal()
  }

  return (
    config && (
      <S.SharedListContainer>
        <S.SharedListTitle>
          <p>
            {shareText} {config.name}
          </p>
          <button id="btn_closemodal_shared" type="button" onClick={closeModalShare}>
            <span className="material-icons">close</span>
          </button>
        </S.SharedListTitle>
        {showLoading ? (
          <SmallLoading />
        ) : (
          <form onSubmit={shareCollection}>
            <label htmlFor={shareText}>{labelText}</label>
            <input
              type="text"
              placeholder={placeholderText}
              name={shareText}
              onChange={e => onChangeInput(e)}
              autoComplete="off"
              style={{ border: `${errorInput ? '1px solid #F03D3E' : '1px solid #e1e1e1'}`}}
              value={currentInputValue}
              id={"inp_share"}
            />
            {errorInput && (
              <p className='inputError'>{errorInputText}</p>
            )}
            {!!suggestionsList.length && (
              <S.SharedListSuggestions showLists={showLists}>
                {suggestionsList.map(item => (
                  <li
                    key={item.id}
                    onClick={() => onClickEmail({ email: item.email, id_v1: item.id_v1 })}
                    id={`add_email_list`}
                  >
                    {item.email}
                  </li>
                ))}
              </S.SharedListSuggestions>
            )}
            {showLists && (
              <S.SharedListEmails>
                {!!emailsList.length && (
                  <>
                    {emailsList.map(item => (
                      <S.SharedListEmailItem key={item.id_v1}>
                        <span>{item.email}</span>
                        <button onClick={() => removeEmail(item)} id="remove_email_list">
                          <ImageNext
                            src={ASSET_PATHS.icon_close}
                            width={16}
                            height={16}
                            alt={'Close'}
                          />
                        </button>
                      </S.SharedListEmailItem>
                    ))}
                  </>
                )}
                {!!errorsList && !!errorsList.length && (
                  <>
                    <span className="subtitle">{errorSubtitleText}</span>
                    {errorsList.map(item => (
                      <S.SharedListEmailItem key={item.id_v1} errors>
                        <span>{item.email}</span>
                      </S.SharedListEmailItem>
                    ))}
                  </>
                )}
                {!!successList && !!successList.length && (
                  <>
                    <span className="subtitle">{successSubtitleText}</span>
                    {successList.map(item => (
                      <S.SharedListEmailItem key={item.id_v1} success>
                        <span>{item.email}</span>
                      </S.SharedListEmailItem>
                    ))}
                  </>
                )}
                {!!hasAccessList && !!hasAccessList.length && (
                  <>
                    <span className="subtitle">{successSubtitleText}</span>
                    {hasAccessList.map(item => (
                      <S.SharedListEmailItem key={item.id_v1} success>
                        <span>{item.email}</span>
                        <button type="button" onClick={() => unshareCollection(item)} id="unshare_btn">
                          <ImageNext
                            src={ASSET_PATHS.icon_close}
                            width={16}
                            height={16}
                            alt={'Close'}
                          />
                        </button>
                      </S.SharedListEmailItem>
                    ))}
                  </>
                )}
              </S.SharedListEmails>
            )}
            <S.SharedListButtons>
              <button type="button" onClick={closeModalShare} id={"btn_cancel_share"}>
                {cancelText}
              </button>
              <button
                type="submit"
                disabled={!emailsList.length || errorInput}
                id="btn_share"
              >
                {shareText}
              </button>
            </S.SharedListButtons>
          </form>
        )}
      </S.SharedListContainer>
    )
  )
}

export default ShareCollectionModal
