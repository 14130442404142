import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { useRouter } from 'next/router'

import { EVENT_KEYS, sendToDataLayer } from '@/services/events'

import { PAGE_PATHS } from '@/services/paths'
import {
  LOCAL_KEYS,
  SESSION_KEYS,
  setWithFixedExpiry,
  getWithExpiry,
  clearStorage,
  getParsed
} from '@/services/browserStorage'
import { getUrlForResize, updateUserImageId } from '@/services/functions'
import { changeClientLanguage } from '@/services/language'
import { sendGetRequest, handleResponseStates, ENDPOINTS } from '@/services/api'
import { loginAgain } from '@/services/userAlerts'
import { SNOWPLOW_SCHEMAS } from '@/services/paths'

import {
  addGlobalContexts,
  trackSelfDescribingEvent
} from '@snowplow/browser-tracker'

const initialState = {
  signed: false,
  user: {
    idCognito: null,
    idDB: null,
    groups: null,
    firstName: null,
    familyName: null,
    fullName: null,
    language: null,
    dataSharing: null,
    receiveNotifications: null,
    orgName: null,
    orgLogo: null,
    classes: null,
    accessType: null,
    orgColor: null,
    orgIDV1: null,
    orgId: null,
    userIp: null,
    userSso: null,
    userEzProxy: null
  },
  solucxData: {
    nps: false,
    transaction_data: {}
  },
  userSensitive: {
    email: null,
    phone: null
  },
  loadingUser: true,
  showDemoUser: true,
  tokenSSO: null,
  embedded: false,
  idsEmbedded: null,
  width: 0,
  height: 0
}

export const AuthContext = createContext(initialState)

export function AuthProvider({ children, tokenData }) {
  const router = useRouter()
  
  const [user, setUser] = useState(initialState.user)
  const [userSensitive, setUserSensitive] = useState(initialState.userSensitive)
  const [loadingUser, setLoadingUser] = useState(initialState.loadingUser)
  const [showDemoUser, setShowDemoUser] = useState(false)
  const [daysLeftLicense, setDaysLeftLicense] = useState()
  const [solucxData, setSolucxData] = useState(initialState.solucxData)
  const [tokenSSO, setTokenSSO] = useState(initialState.token)
  const [embedded, setEmbedded] = useState(initialState.embedded)
  const [idsEmbedded, setIdsEmbedded] = useState(initialState.idsEmbedded)
  const [width, setWidth] = useState(initialState.width)
  const [height, setHeight] = useState(initialState.height)

  const didMount = useRef(false)

  const exitApp = () => {
    clearStorage()
    router.push(PAGE_PATHS.signin)
  }

  const setUserData = userData => {
    setUser(userData)
    setUserSensitive({ email: tokenData?.email, phone: tokenData?.phone })
  }

  const setUserAuth = (userData, sessionStart) => {
    const contextUser = {
      schema: SNOWPLOW_SCHEMAS.entity_user,
      data: {
        user_id: userData.idDB,
        org_name: userData.orgName,
        user_class: userData.classes.length ? userData.classes[0].name : '',
        access_type: userData.accessType,
        group_type: userData.groups.toString()
      }
    }
    addGlobalContexts([contextUser])

    setUserData(userData)

    const demoPopupWasAlreadyClosed = !!sessionStorage.getItem(SESSION_KEYS.demo_popup_was_closed)
    if (!demoPopupWasAlreadyClosed) {
      const expiredDate = new Date(userData.expires_date)
      const currentDate = new Date()
      if (expiredDate.getTime() < currentDate.getTime() && userData.demoLicense) {
        setShowDemoUser(true)
        setDaysLeftLicense(0)
      } else {
        const diffMili = Math.abs(currentDate - expiredDate)
        const seconds = parseInt(Math.floor(diffMili / 1000))
        const minutes = parseInt(Math.floor(seconds / 60))
        const hours = parseInt(Math.floor(minutes / 60))
        const days = parseInt(Math.floor(hours / 24))
        if (userData.demoLicense) {
          setShowDemoUser(true)
          setDaysLeftLicense(days)
        }
      }
    }

    if (sessionStart) {
      startUserSession(userData)
    }
  }

  const updateUserAuth = newData => {
    const newUser = { ...user }

    Object.keys(newData).forEach(key => {
      newUser[key] = newData[key]
    })

    sessionStorage.setItem(SESSION_KEYS.session_user, JSON.stringify(newUser))
    setUser(newUser)

    const persistence = getParsed(localStorage, LOCAL_KEYS.user_persistence)
    if (persistence) {
      setWithFixedExpiry(
        LOCAL_KEYS.user_persistence,
        newUser,
        persistence.expiry
      )
    }
  }

  const setTokenTTL = ttl => {
    // Tira 10 segundos do tempo de expiração do token
    // Não pode expirar de verdade pq precisa dele na rota de refresh
    const normalizedTTL = ttl - 10000
    const cachedTTL = getParsed(sessionStorage, SESSION_KEYS.token_ttl)
    if (cachedTTL !== normalizedTTL) {
      sessionStorage.setItem(SESSION_KEYS.token_ttl, normalizedTTL)
    }
  }

  const getUserAvatar = (width, height, format = 'png') => {
    const cachedImg = getWithExpiry(LOCAL_KEYS.user_image)
    const cachedImgId = getWithExpiry(LOCAL_KEYS.user_image_id)
    if (cachedImg) {
      return cachedImg
    } else if (cachedImgId) {
      return getUrlForResize(
        `users/images/${user.idCognito}`,
        width,
        height,
        format + `?v=${cachedImgId}`
      )
    }
      const newImageId = updateUserImageId()
      return getUrlForResize(
        `users/images/${user.idCognito}`,
        width,
        height,
        format + `?v=${newImageId}`
      )
    }

  const handleAuthTokenRefresh = exp => {
    const timeUntilRefresh = getTimeUntilRefresh(exp)
    if (timeUntilRefresh <= 0) {
      refreshAuthToken()
    } else {
      setNextRefreshTimer(timeUntilRefresh)
    }
  }

  const refreshAuthToken = async () => {
    // console.log('executando refresh...')
    await sendGetRequest(ENDPOINTS.refresh_token)
      .then(response => {
        handleResponseStates(response, responsesRefresh)
      })
      .catch(error => {
        const msg = error.data || error
        console.error('refreshAuthToken:', msg)
        loginAgain()
      })
  }

  const getTimeUntilRefresh = exp => {
    // Retorna o tempo que falta até 10 segundos do token expirar
    // Não pode expirar de verdade pq precisa dele na rota de refresh
    const now = new Date()
    const refreshTime = new Date(exp)
    refreshTime.setSeconds(refreshTime.getSeconds() - 10)

    const timeUntilRefresh = refreshTime - now
    // console.log('TESTE getTimeUntilRefresh now', now)
    // console.log('TESTE getTimeUntilRefresh refreshTime', refreshTime)
    // console.log(
    //   'TESTE getTimeUntilRefresh timeUntilRefresh',
    //   timeUntilRefresh / 1000
    // )

    return timeUntilRefresh
  }

  const setNextRefreshTimer = timeLeft => {
    // console.log('novo refresh setado para', timeLeft / 1000, 'segundos')
    setTimeout(refreshAuthToken, timeLeft)
  }

  const getUserSession = () => {
    const loggedUser = sessionStorage.getItem(SESSION_KEYS.session_user)
    if (loggedUser) {
      return { data: JSON.parse(loggedUser), isCurrentSession: true }
    }

    const cachedUser = getWithExpiry(LOCAL_KEYS.user_persistence)
    if (cachedUser) {
      return { data: cachedUser, isCurrentSession: false }
    }

    return null
  }

  const startUserSession = userData => {
    sessionStorage.setItem(SESSION_KEYS.session_user, JSON.stringify(userData))
    changeClientLanguage(userData.language)
    sendToDataLayer({
      event: EVENT_KEYS.signin,
      user_id: userData.id,
      org_name: userData.orgName,
      user_class: userData.classes.length ? userData.classes[0].name : '',
      access_type: userData.accessType,
      group_type: userData.groups.toString()
    })
    trackSelfDescribingEvent({
      event: {
        schema: SNOWPLOW_SCHEMAS.event_login,
        data: {
          time: new Date().toISOString()
        }
      }
    })
  }

  const onRefreshSuccess = () => {
    // console.log('sucesso no refresh')
    // console.log('******************')
    const cachedTTL = getParsed(sessionStorage, SESSION_KEYS.token_ttl)
    setNextRefreshTimer(cachedTTL)
  }

  const responsesRefresh = [{ name: 204, callback: onRefreshSuccess }]

  useEffect(() => {
    if (didMount.current) return

    const session = getUserSession()
    if (session) {
      setUserAuth(session.data, !session.isCurrentSession)
    }

    setLoadingUser(false)
    didMount.current = true
  }, [])

  useEffect(() => {
    // Carregar idsEmbedded do localStorage quando o contexto é inicializado
    const storedIdsEmbedded = localStorage.getItem('ba_ids_embedded');
    if (storedIdsEmbedded) {
      setIdsEmbedded(storedIdsEmbedded);
    }
  }, []);

  useEffect(() => {
    // Salvar idsEmbedded no localStorage sempre que ele for atualizado
    if (idsEmbedded) {
      localStorage.setItem('ba_ids_embedded', idsEmbedded);
    }
  }, [idsEmbedded]);

  useEffect(() => {
    if (router.query.token) {
      setTokenSSO(router.query.token);
    }
    if (router.query.ids) {
      setIdsEmbedded(router.query.ids);
    }
    if (router.query.width) {
      setWidth(router.query.width);
    }
    if (router.query.height) {
      setHeight(router.query.height);
    }
  }, [router.query.token, router.query.ids, router.query.width, router.query.height]);

  return (
    <AuthContext.Provider
      value={{
        signed: user.idCognito && user.idDB,
        user,
        userSensitive,
        loadingUser,
        exitApp,
        setUserAuth,
        updateUserAuth,
        setTokenTTL,
        getUserAvatar,
        handleAuthTokenRefresh,
        showDemoUser,
        setShowDemoUser,
        daysLeftLicense,
        solucxData,
        setSolucxData,
        tokenSSO,
        embedded,
        setEmbedded,
        idsEmbedded,
        width,
        setWidth,
        height,
        setHeight
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}

export class AppAccessError extends Error {
  constructor(message, options) {
    super(message, options)
  }
}
