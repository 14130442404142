import React from 'react'
import * as S from './styles'

import Title from './Title'
import ButtonMenu from '../../ButtonMenu'

const EditStyleModal = ({
  name,
  contentHTML,
  allowSubmitBtn = true,
  confirmCallback,
  cancelCallback,
  confirmText
}) => {
  return (
    <S.EditInfoModalContainer>
      <Title name={name} cancelCallback={cancelCallback} />

      {contentHTML}

      <ButtonMenu
        cancelCallback={cancelCallback}
        confirmCallback={confirmCallback}
        confirmText={confirmText}
        allowSubmitBtn={allowSubmitBtn}
      />
    </S.EditInfoModalContainer>
  )
}

export default EditStyleModal
