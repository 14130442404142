import React, { useEffect, useState } from 'react'
import * as S from './styles'

const ModalButton = ({ settings }) => {
  const [buttonClass, setButtonClass] = useState('')

  useEffect(() => {
    let name = 'btn'

    name += settings.sizeClass ? ` ${settings.sizeClass}` : ` md`
    name += settings.colorClass ? ` ${settings.colorClass}` : ` blue`

    if (settings.extraClasses) {
      name += ` ${settings.extraClasses}`
    }

    setButtonClass(name)
  }, [settings])

  return (
    settings && (
      <S.ModalButtonContainer
        id={settings.id}
        className={buttonClass}
        onClick={settings.callback}
        type="button"
      >
        {settings.text}
      </S.ModalButtonContainer>
    )
  )
}

export default ModalButton
