import React, { useEffect, useState } from 'react'
import * as S from './styles'

import AlertScreen from '@/components/AlertScreen'

import { ASSET_PATHS } from '@/services/paths'
import { getLocalizedZendeskLink } from '@/services/language'

import { useTranslation } from 'react-i18next'

const CrashScreen = () => {
  const { t } = useTranslation()

  const [swalCrash, setSwalCrash] = useState('')
  const [messagesCrash, setMessagesCrash] = useState('')
  const [messagesSupport, setMessagesSupport] = useState('')

  useEffect(() => {
    setSwalCrash(t('swal.crash'))
    setMessagesCrash(t('messages.crash'))
    setMessagesSupport(t('messages.support2'))
  }, [t])

  return (
    <AlertScreen
      id="screen-alert"
      titleHtml={
        <S.CrashScreenTitle className="mb-4">{swalCrash}</S.CrashScreenTitle>
      }
      textHtml={
        <S.CrashScreenText>
          <span>{`${messagesCrash} `}</span>
          <a
            href={getLocalizedZendeskLink()}
            rel="noreferrer"
            target="_blank"
            id="btn_help"
          >
            {`${messagesSupport}.`}
          </a>
        </S.CrashScreenText>
      }
      imgURL={`${process.env.CDN_URL}/public/alerts/frontend.webp`}
      imgWidth={496}
      imgHeight={394}
      logoURL={ASSET_PATHS.logo}
      logoWidth={232}
      logoHeight={92}
      circlesInfo={[
        { top: '91%', left: '4%', radius: 176, thickness: 40 },
        { top: '7%', left: '98%', radius: 203, thickness: 53 }
      ]}
    />
  )
}

export default CrashScreen
