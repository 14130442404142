import React, { useEffect, useState } from 'react'
import * as S from './styles'

import NavIcon from '../NavIcon'

import { useModal } from '@/contexts/ModalContext'

import { useTranslation } from 'react-i18next'

const SectionsNav = () => {
  const { settings } = useModal()
  const { t } = useTranslation()

  const [commandInfo, setCommandInfo] = useState()

  useEffect(() => {
    setCommandInfo({
      blades: {
        mouse: {
          title: t('titles.mouse'),
          commands: [
            {
              name: t('subtitles.move'),
              text: t('helpers.moveMouseBlade'),
              icons: ['mouse_left', 'blade_move']
            },
            {
              name: t('subtitles.zoom'),
              text: t('helpers.zoomMouse'),
              icons: ['mouse_scroll', 'blade_zoom']
            },
            {
              name: t('subtitles.textbox'),
              text: t('helpers.textboxMouse'),
              icons: ['mouse_left', 'blade_textbox']
            }
          ]
        },
        keyboard: {
          title: t('titles.keyboard'),
          commands: [
            {
              name: t('subtitles.move'),
              text: t('helpers.moveKeyboard'),
              icons: ['keyboard_arrows', 'blade_move']
            },
            {
              name: t('subtitles.zoom'),
              text: t('helpers.zoomKeyboard'),
              icons: ['keyboard_plus', 'blade_zoom']
            }
          ]
        },
        trackpad: {
          title: t('titles.trackpad'),
          commands: [
            {
              name: t('subtitles.move'),
              text: t('helpers.movePad'),
              icons: ['pad_left', 'blade_move']
            },
            {
              name: t('subtitles.zoom'),
              text: t('helpers.zoomPad'),
              icons: ['pad_pinch', 'blade_zoom']
            }
          ]
        }
      },
      models: {
        mouse: {
          title: t('titles.mouse'),
          commands: [
            {
              name: t('subtitles.rotate'),
              text: t('helpers.rotateMouse'),
              icons: ['mouse_left', 'model_rotate']
            },
            {
              name: t('subtitles.move'),
              text: t('helpers.moveMouseModel'),
              icons: ['mouse_right', 'model_move']
            },
            {
              name: t('subtitles.select'),
              text: t('helpers.selectMouse'),
              icons: ['mouse_select', 'model_select']
            }
          ]
        },
        keyboard: {
          title: t('titles.keyboard'),
          commands: [
            {
              name: t('subtitles.camera'),
              text: t('helpers.cameraKeyboard'),
              icons: ['keyboard_wasd', 'model_move']
            },
            {
              name: t('subtitles.rotate'),
              text: t('helpers.rotateKeyboard'),
              icons: ['keyboard_arrows', 'model_rotate']
            },
            {
              name: t('subtitles.zoom'),
              text: t('helpers.zoomKeyboard'),
              icons: ['keyboard_plus', 'model_zoom']
            }
          ]
        },
        trackpad: {
          title: t('titles.trackpad'),
          commands: [
            {
              name: t('subtitles.rotate'),
              text: t('helpers.rotatePad'),
              icons: ['pad_left', 'model_rotate']
            },
            {
              name: t('subtitles.move'),
              text: t('helpers.movePad'),
              icons: ['pad_right', 'model_move']
            },
            {
              name: t('subtitles.zoom'),
              text: t('helpers.zoomPad'),
              icons: ['pad_pinch', 'model_zoom']
            }
          ]
        }
      }
    })
  }, [t])

  const renderNavigation = () => {
    return Object.keys(commandInfo[settings.config.type]).map(
      (inputType, i) => {
        return (
          <S.SectionsNavContainer key={i}>
            <div className="section-title">
              {commandInfo[settings.config.type][inputType].title}
            </div>
            {renderRowSection(inputType)}
          </S.SectionsNavContainer>
        )
      }
    )
  }

  const renderRowSection = inputType => {
    return (
      <div className="flexbox">
        {commandInfo[settings.config.type][inputType].commands.map((com, i) => {
          return renderSingleItem(com, i)
        })}
      </div>
    )
  }

  const renderSingleItem = (commandItem, index) => {
    return (
      <div key={index} className="item-group">
        <NavIcon icons={commandItem.icons} />
        <h6>{commandItem.name}</h6>
        <p>{commandItem.text}</p>
      </div>
    )
  }

  return (
    <div id="section-nav" className="help-section">
      {commandInfo && renderNavigation()}
    </div>
  )
}

export default SectionsNav
