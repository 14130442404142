export const EVENT_KEYS = {
  click: 'click',
  resize: 'resize',
  scroll: 'scroll',
  keyup: 'keyup',
  keydown: 'keydown',
  fullscreen: 'fullscreenchange',
  languageChange: 'language_change',
  categoryChange: 'category_change',
  openContent: 'opened_content',
  tokenRefresh: 'token_refreshed',

  // GOOGLE ANALYTICS
  signin: 'user_login',
  signout: 'user_logout',
  clickContent: 'content_click',
  clickModuleDescription: 'module_description_click',
  clickContentDescription: 'content_description_click',
  clickCategory: 'category_click',
  requestDemo: 'request_demo_click',
  filterTextInput: 'filter_text_input',
  searchTextInput: 'search_text_input'
}

export const sendToDataLayer = dataObj => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(dataObj)
}
