import styled from 'styled-components'

export const ContentDetailsContainer = styled.div`
  width: ${props => props.width}px;
  max-width: 100%;
  background: none;
`

export const ContentDetailsImgContainer = styled.div`
  .detail-img {
    border-radius: 5px 5px 0 0;
    display: block;
    max-width: 100%;
  }

  button {
    background: none;
    color: ${props => (props.whiteBg ? 'var(--dark)' : `var(--light2)`)};
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  @media only screen and (max-width: 1200px) {
    position: relative;
    overflow: hidden;

    button {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }
  }
`

export const ContentDetailsTextContainer = styled.div`
  background: var(--light);
  padding: 2rem;
  border-radius: 0 0 5px 5px;

  p {
    color: var(--grey2);

    &.title {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 1.5rem;
      color: var(--dark);
    }
  }
`
