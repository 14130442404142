import styled from 'styled-components'

export const FormWrapper = styled.form`
  label {
    margin-bottom: 0.25rem;

    &.checkbox-label {
      width: fit-content;
    }
  }

  section:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }

  section:last-of-type {
    margin-bottom: 2rem;
  }

  a {
    text-decoration: none;
    font-weight: 700;
    color: var(--grey7);
  }

  input[type='checkbox'] {
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
    border: 1px solid var(--grey6);
    border-radius: 0.3125rem;
    cursor: pointer;
  }

  .photo {
    width: 100%;

    .flexbox {
      margin-bottom: 1rem;
    }

    .properties {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      justify-content: center;
      margin-left: 1.5rem;

      & > p:first-child {
        margin-bottom: 1.5rem;
      }
    }

    img {
      border-radius: 50%;
      float: left;
    }
  }

  .react-tel-input .form-control {
    width: 100%;
    height: 41px;
    border: 1px var(--grey4) solid;
  }

  .react-tel-input {
    margin: 5px 0;
  }

  form::placeholder,
  select {
    color: var(--grey3);
  }

  #reminders {
    label {
      color: var(--grey7);
    }

    & * {
      font-size: 14px;
    }
  }

  #btn_back,
  #btn_resendtoken {
    font-size: 16px;
  }

  #btn_wrapper {
    width: fit-content;
    margin-left: auto;
  }

  .link-button,
  .icon-button {
    background: none;
    margin: 0;
    width: fit-content;
    color: var(--grey2);
  }

  .link-button {
    text-decoration: underline;
  }

  .icon-button span:first-of-type {
    margin-right: 0.5rem;
  }

  @media only screen and (max-width: 768px) {
    //MOBILE

    input,
    .flexbox > label {
      font-size: 14px;
    }

    input[type='checkbox'] {
      width: 25px;
    }
  }
`
export const CropperContainer = styled.div`
  position: relative;
  height: 200px;
  margin-bottom: 3rem;
`
