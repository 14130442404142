import React, { useEffect, useState } from 'react'
import * as S from './styles'

import DecorativeCircle from '@/components/DecorativeCircle'
import ImageNext from '@/components/ImageNext'

import { useTranslation } from 'react-i18next'

const AlertScreen = props => {
  const {
    id,
    className,
    titleHtml,
    textHtml,
    imgURL,
    imgWidth,
    imgHeight,
    imgAlt,
    logoURL,
    logoWidth,
    logoHeight,
    circlesInfo
  } = props

  const { t } = useTranslation()

  const [screenClass, setScreenClass] = useState()
  const [altsLogo, setAltsLogo] = useState('')

  useEffect(() => {
    setAltsLogo(t('alts.logo'))
  }, [t])

  useEffect(() => {
    let name = 'alert-screen'
    if (className) {
      name += ` ${className}`
    }

    setScreenClass(name)
  }, [className])

  return (
    <S.AlertScreenContainer id={id} className={screenClass}>
      {circlesInfo.map((info, i) => (
        <DecorativeCircle
          key={i}
          top={info.top}
          left={info.left}
          radius={info.radius}
          thickness={info.thickness}
        />
      ))}

      <ImageNext
        src={logoURL}
        width={logoWidth}
        height={logoHeight}
        alt={altsLogo}
        className="logo-img"
      />

      <ImageNext
        src={imgURL}
        width={imgWidth}
        height={imgHeight}
        alt={imgAlt}
        autoSize={true}
        className="alert-img"
      />

      {titleHtml}
      {textHtml}
    </S.AlertScreenContainer>
  )
}

export default AlertScreen
