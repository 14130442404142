import React, { useEffect, useState } from 'react'
import * as S from './styles'

const Password = props => {
  const { id, placeholder, inputState, inputHandler } = props

  const [iconName, setIconName] = useState()

  useEffect(() => {
    setIconName(getVisibilityIconName(true))
  }, [])

  const getVisibilityIconName = isVisible => {
    const baseName = 'visibility'
    return isVisible ? baseName : `${baseName}_off`
  }

  const toggleInputVisibility = btnEl => {
    const inputEl = btnEl.parentNode.querySelector('input')
    if (inputEl.type === 'password') {
      // Liga visibilidade
      inputEl.type = 'text'
      setIconName(getVisibilityIconName(false))
    } else {
      // Desliga visibilidade
      inputEl.type = 'password'
      setIconName(getVisibilityIconName(true))
    }
  }

  return (
    <S.PasswordContainer>
      <input
        type="password"
        id={id}
        placeholder={placeholder}
        value={inputState}
        onChange={e => inputHandler(e.target.value)}
      />
      <span
        className="eye material-icons"
        onClick={e => {
          toggleInputVisibility(e.target)
        }}
      >
        {iconName}
      </span>
    </S.PasswordContainer>
  )
}

export default Password
