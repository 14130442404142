import styled from 'styled-components'

export const MoveCollectionContainer = styled.div`
  background: var(--light);
  border-radius: 10px;
  padding: 1.8rem 0;
  min-width: 36rem;
  width: 47vw;
  max-width: 500px;

  button {
    background-color: transparent;
  }
`

export const MoveCollectionTitle = styled.div`
  border-bottom: 1px solid var(--grey4);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.25rem 1.56rem 2.25rem;
  word-wrap: break-word;
  word-break: break-all;

  p {
    color: var(--dark);
    font-size: 1.5rem;
    font-weight: 600;
  }
`

export const MoveCollectionContent = styled.div`
  padding: 1.5rem 2.25rem 0 2.25rem;

  .label {
    color: var(--dark);
    font-size: 0.875rem;
    font-weight: 500;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 2.5rem;
    gap: 1.25rem;

    button {
      padding: 0.7rem 1.56rem;
      border-radius: 5px;
      font-size: 0.875rem;
      font-weight: 500;
    }

    button[type='submit'] {
      background-color: var(--blue);
      color: var(--light);
    }

    button[type='button'] {
      border: 1px solid var(--grey11);
      color: var(--grey14);
    }

    button:disabled,
    button[disabled] {
      pointer-events: none;
      opacity: 0.5;
    }
  }
`

export const MoveCollectionButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1.5px solid var(--blue);
  border-radius: 10px;
  padding: 10px 14px;
  margin-top: 8px;
  width: 100%;
  color: var(--grey21);
  font-size: 16px`

export const MoveCollectionListWrapper = styled.div`
  box-shadow: 0px 0px 10px 0px #00000040;
  border: 1px solid #BDBDBD;
  margin-top: 4px;
  padding: 8px 0;

  ul {
    max-height: 10rem;
    overflow-y: scroll;
  
    li {
      list-style: none;
      padding: 8px 16px;
      color: var(--grey21);
      font-size: 14px;
  
      &:hover {
        background-color: var(--blue13);
        cursor: pointer;
      }
    }
  }

  p {
    font-size: 12px;
    margin: 16px 16px 8px 16px;
    border-top: 1px solid var(--grey11);
    padding-top: 16px;
    display: block;

    span {
      &:last-of-type {
        font-weight: 600;
      }
    }
  }
`
