import React, { useEffect, useState } from 'react'
import * as S from './styles'

import Shortcut from '../Shortcut'

import { useModal } from '@/contexts/ModalContext'

import { useTranslation } from 'react-i18next'

const SectionsShortcut = () => {
  const { settings } = useModal()
  const { t } = useTranslation()

  const [commandInfo, setCommandInfo] = useState()
  const [bladesShortcutMessage, setBladesShortcutMessage] = useState()

  useEffect(() => {
    setBladesShortcutMessage(t('subtitles.bladeMessage'))
    setCommandInfo({
      blades: [
        { name: t('subtitles.rotate'), key: 'r' },
        { name: t('subtitles.turn'), key: 'f' },
        { name: t('subtitles.textbox'), key: 'c' }
      ],
      models: [
        { name: t('subtitles.deselect'), key: ['delete', 'return'] },
        { name: t('subtitles.focus'), key: 'u' },
        { name: t('subtitles.selectMultiple'), key: 'i' },
        { name: t('subtitles.hide'), key: 'o' },
        { name: t('subtitles.isolate'), key: 'p' },
        { name: t('subtitles.tree'), key: 'z' },
        { name: t('subtitles.textbox'), key: 'x' },
        // { name: t('subtitles.tag'), key: 'c' },
        { name: t('subtitles.cutTool'), key: 'v' },
        { name: t('subtitles.rotate'), key: 'r' }
      ]
    })
  }, [t])

  return (
    <div id="section-shortcut" className="help-section">
      {commandInfo &&
        commandInfo[settings.config.type].map((com, i) => {
          return (
            <S.RowContainer className="flexbox h-spaced v-centered" key={i}>
              <span>{com.name}</span>
              <Shortcut text={com.key} />
            </S.RowContainer>
          )
        })}

      {settings.config.type === 'blades' && (
        <S.Message>{bladesShortcutMessage}</S.Message>
      )}
    </div>
  )
}

export default SectionsShortcut
