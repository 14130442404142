import React, { useEffect, useState } from 'react'
import * as S from './styles'

import TabMenu from './TabMenu'
import SectionsNav from './SectionsNav'
import SectionsShortcut from './SectionsShortcut'

const ViewerHelpModal = () => {
  const [currentSection, setCurrentSection] = useState('navigation')

  useEffect(() => {
    if (!currentSection) return

    const nav = document.getElementById('section-nav')
    const shortcut = document.getElementById('section-shortcut')

    if (currentSection === 'navigation') {
      nav.classList.remove('invisible')
      shortcut.classList.add('invisible')
    } else {
      nav.classList.add('invisible')
      shortcut.classList.remove('invisible')
    }
  }, [currentSection])

  return (
    <S.ViewerHelpContainer id="modal-help">
      <TabMenu stateHandler={setCurrentSection} />
      <div className="scrollable">
        <SectionsNav />
        <SectionsShortcut />
      </div>
    </S.ViewerHelpContainer>
  )
}

export default ViewerHelpModal
