import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { useModal } from '@/contexts/ModalContext'

import { setCachedAlert, onUncaughtError } from '@/services/userAlerts'
import {
  ENDPOINTS,
  sendPostRequest,
  handleResponseStates
} from '@/services/api'

import { useTranslation } from 'react-i18next'
import { i18n } from '@/translate/i18n'
import Swal from 'sweetalert2'

import * as S from './styles'
import { slugify, slugifyWithoutSeparator } from '@/services/functions'

const MoveCollectionModal = ({ config }) => {
  const { closeModal } = useModal()
  const router = useRouter()

  const { t } = useTranslation()

  const [moveText, setMoveText] = useState()
  const [labelText, setLabelText] = useState()
  const [placeholderText, setPlaceholderText] = useState()
  const [cancelText, setCancelText] = useState()
  const [myCollectionText, setMyCollectionText] = useState()

  const [selectedFolder, setSelectedFolder] = useState()
  const [foldersList, setFoldersList] = useState()
  const [showList, setShowList] = useState(false)
  const [currentItem, setCurrentItem] = useState()

  useEffect(() => {
    setMoveText(`${t('buttons.move')}`)
    setLabelText(`${t('labels.moveCollection')}`)
    setPlaceholderText(`${t('placeholders.moveCollection')}`)
    setCancelText(`${t('buttons.cancel')}`)
    setMyCollectionText(`${t('titles.collections')}`)
  }, [t])

  const getChildren = (fullList, paths, currentPath = '') => {
    for (let i = 0; i < fullList.length; i++) {
      if (fullList[i].type === 'folder' && fullList[i].id !== config.collectionId) {
        paths.push({
          value: fullList[i].id,
          label: currentPath ? `${currentPath}/${fullList[i].name}` : fullList[i].name
        })
        if (fullList[i].child && fullList[i].id !== config.collectionId) {
          getChildren(
            fullList[i].child,
            paths,
            currentPath ? `${currentPath}/${fullList[i].name}` : fullList[i].name
          )
        }
      }
    }
  }

  useEffect(() => {
    let paths = []
    getChildren(config.allCollections, paths)
    const simplifiedRouter = slugifyWithoutSeparator(router.asPath.split('/Collections/').join())
    const removeCurrentFolder = paths.filter((item) => {
      return slugifyWithoutSeparator(item.label) !== simplifiedRouter
    })
    setFoldersList(removeCurrentFolder)
  }, [])

  const onSuccess = () => {
    config.setAction(!config.action)
    Swal.fire({
      icon: 'success',
      title: i18n.t('swal.success'),
      html: i18n.t('messages.collectionMoved')
    })
  }

  const responsesMoved = [{ name: 200, callback: onSuccess }]

  const moveCollection = async e => {
    e.preventDefault()
    config.setLoading(true)
    closeModal()
    const body = {
      id: config.collectionId,
      folder_id: selectedFolder.value
    }
    await sendPostRequest(ENDPOINTS.move_collection, body)
      .then(response => {
        handleResponseStates(response, responsesMoved)
      })
      .catch(error => {
        const msg = error.data || error
        console.log(msg)
        onUncaughtError()
      })
      .finally(() => {
        config.setLoading(false)
      })
  }

  const selectFolder = (value) => {
    setSelectedFolder(value)
    setCurrentItem(value.label)
  }

  return (
    config && (
      <S.MoveCollectionContainer>
        <S.MoveCollectionTitle>
          <p>
            {moveText} {config.name}
          </p>
          <button id="btn_closemodal_move" type="button" onClick={closeModal}>
            <span className="material-icons">close</span>
          </button>
        </S.MoveCollectionTitle>

        <S.MoveCollectionContent>
          <p className='label'>{labelText}</p>
          <S.MoveCollectionButton className='selected' onClick={() => setShowList(!showList)} id="show_list_toggle">
            <p>{selectedFolder ? selectedFolder.name : placeholderText}</p>
            <span id="modal-icon" className="material-icons">
              expand_more
            </span>
          </S.MoveCollectionButton>
          {showList && (
            <S.MoveCollectionListWrapper>
              <ul>
                {router.asPath !== '/Collections' && (
                  <li
                    onClick={() => selectFolder(
                      { value: '', label: myCollectionText, name: myCollectionText, paths: [myCollectionText] }
                    )}
                    id="select_path"
                  >
                    {myCollectionText}
                  </li>
                )}
                {foldersList.map(item => {
                  const folderPaths = item.label.split(`/`)
                  const folderName = folderPaths[folderPaths.length - 1]
                  return (
                    <li
                      key={item.value}
                      onClick={() => selectFolder({ ...item, name: folderName, paths: folderPaths })}
                      id="select_path"
                    >
                      {folderName}
                    </li>
                  )
                })}
              </ul>
              {currentItem && (
                <p>
                  {selectedFolder.paths.map((item, idx) => <span key={`${item}-${idx}`}>{` / ${item}`}</span>) || myCollectionText}
                </p>
              )}
            </S.MoveCollectionListWrapper>
          )}
          <div className='buttons'>
            <button type="button" onClick={closeModal} id="btn_cancel_move">
              {cancelText}
            </button>
            <button
              type="submit"
              onClick={moveCollection}
              disabled={!selectedFolder}
              id={'btn_move'}
            >
              {moveText}
            </button>
          </div>
        </S.MoveCollectionContent>
      </S.MoveCollectionContainer>
    )
  )
}

export default MoveCollectionModal
