import styled from 'styled-components'

export const TabMenuContainer = styled.div`
  //Adiciona a altura do seletor, que não conta pq é position absolute
  margin-bottom: calc(3rem + 5px);
  button {
    background: none;
  }
  .underline-select {
    background: var(--blue);
    height: 5px;
    border-radius: 50px;
    margin-top: 0.5rem;
    position: absolute;
    width: ${props => props.selectorWidth};
    left: ${props => props.selectorPos};
    transition: all 450ms;
  }
`

export const TabButton = styled.button`
  color: var(--grey8);
  font-size: 16px;
  &:not(:last-child) {
    margin-right: 3rem;
  }
`

export const CloseButton = styled.button`
  color: var(--dark);
`
