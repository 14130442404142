import React, { useEffect, useState } from 'react'
import * as S from './styles'

import { useTranslation } from 'react-i18next'

const Shortcut = ({ text }) => {
  const { t } = useTranslation()

  const [buttonsReturn, setButtonsReturn] = useState('')
  const [messagesSeparator, setMessagesSeparator] = useState('')

  useEffect(() => {
    setButtonsReturn(t('buttons.return'))
    setMessagesSeparator(t('messages.separator'))
  }, [t])

  const renderKeyText = () => {
    if (Array.isArray(text)) return renderMultipleKeys()
    return renderSingleKey(text)
  }

  const renderSingleKey = keyText => {
    if (keyText === 'return') return renderReturnKey()

    return (
      <S.Key>
        <span>{keyText}</span>
      </S.Key>
    )
  }

  const renderReturnKey = () => {
    return (
      <S.Key className="flexbox v-centered">
        <span className="material-icons-round">arrow_back</span>
        <span>{buttonsReturn}</span>
      </S.Key>
    )
  }

  const renderSeparator = (index, array) => {
    if (index === array.length - 1) return null
    return <span>{messagesSeparator}</span>
  }

  const renderMultipleKeys = () => {
    return (
      <div className="flexbox v-centered">
        {text.map((keyText, i, array) => {
          return (
            <div key={i}>
              {renderSingleKey(keyText)}
              {renderSeparator(i, array)}
            </div>
          )
        })}
      </div>
    )
  }

  return <S.ShortcutContainer>{text && renderKeyText()}</S.ShortcutContainer>
}

export default Shortcut
