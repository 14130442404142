import React from 'react'
import * as S from './styles'

const ContentAccessButton = ({ text, handler }) => {
  return (
    <S.ContentAccessButtonContainer
      id="btn_opencontent"
      type="button"
      className="btn md blue"
      onClick={handler}
    >
      {text}
    </S.ContentAccessButtonContainer>
  )
}

export default ContentAccessButton
