import styled from 'styled-components'

export const BannerModal = styled.div`
  text-align: right;
  padding: 0.5rem;

  button {
    background: none;
  }
`
