import styled from 'styled-components'

export const ViewerHelpContainer = styled.div`
  height: 100%;
  padding: 2rem 2rem 1rem 3rem;

  .scrollable {
    overflow-y: scroll;
    height: 78%;
    padding-right: 1rem;
    scrollbar-width: 4px;
    scrollbar-color: var(--grey2);
    ::webkit-scrollbar {
      width: 4px;
    }
    ::webkit-scrollbar-thumb {
      background: var(--grey2);
      border-radius: 50px;
    }
    ::webkit-scrollbar-track {
      background: none;
    }
  }

  .help-section:not(.invisible) {
    display: block;
    -webkit-animation fadeIn 450ms;
    animation fadeIn 450ms;
  }

  .invisible {
    display: none;
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
