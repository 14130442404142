import styled from 'styled-components'

export const RenameCollectionContainer = styled.div`
  background: var(--light);
  border-radius: 10px;
  padding: 1.8rem 0;
  min-width: 36rem;
  width: 47vw;
  max-width: 500px;

  button {
    background-color: transparent;
  }

  > form {
    padding: 0.75rem 2.25rem 0 2.25rem;

    label {
      color: var(--dark);
      font-size: 0.875rem;
      font-weight: 500;
    }

    input {
      border-radius: 10px;

      @media only screen and (max-width: 450px) {
        font-size: 10px;
      }
    }

    > div {
      display: flex;
      justify-content: flex-end;
      margin-top: 2.5rem;
      gap: 1.25rem;

      button {
        padding: 0.7rem 1.56rem;
        border-radius: 5px;
        font-size: 0.875rem;
        font-weight: 500;
      }

      button[type=submit] {
        background-color: var(--blue);
        color: var(--light);
      }

      button[type=button] {
        border: 1px solid var(--grey11);
        color: var(--grey14);
      }
    }
  }
`

export const RenameCollectionTitle = styled.div`
  border-bottom: 1px solid var(--grey4);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.25rem 1.56rem 2.25rem;

  p {
    color: var(--dark);
    font-size: 1.5rem;
    font-weight: 600;
  }
`
