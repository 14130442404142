import React, { useRef } from 'react'
import * as S from './styles'

const FileUploader = ({ handler, dropMessage }) => {
  const refFileInput = useRef()

  const disableEvent = e => {
    e.preventDefault()
  }

  const fileDrop = e => {
    e.preventDefault()
    const files = e.dataTransfer.files
    if (files.length > 0) {
      handler(files)
    }
  }

  const filesSelected = () => {
    const files = refFileInput.current.files
    if (files.length > 0) {
      handler(files)
    }
  }

  const fileInputClicked = () => {
    refFileInput.current.click()
  }

  return (
    <S.FileUploaderContainer>
      <S.DropContainer
        onDragOver={disableEvent}
        onDragEnter={disableEvent}
        onDragLeave={disableEvent}
        onDrop={fileDrop}
        onClick={fileInputClicked}
      >
        <div className="drop-message">{dropMessage}</div>
        <input
          id="inp_file"
          ref={refFileInput}
          type="file"
          multiple
          onChange={filesSelected}
        />
      </S.DropContainer>
    </S.FileUploaderContainer>
  )
}

export default FileUploader
