import React, { useEffect, useState } from 'react'

import { useModal } from '@/contexts/ModalContext'

import { onUncaughtError } from '@/services/userAlerts'
import {
  ENDPOINTS,
  sendPostRequest,
  handleResponseStates
} from '@/services/api'

import { useTranslation } from 'react-i18next'
import { i18n } from '@/translate/i18n'
import Swal from 'sweetalert2'

import * as S from './styles'

const MoveCollectionDropModal = ({ config }) => {
  const { closeModal } = useModal()

  const { t } = useTranslation()

  const [moveText, setMoveText] = useState()
  const [toText, setToText] = useState()
  const [cancelText, setCancelText] = useState()

  useEffect(() => {
    setMoveText(`${t('buttons.move')}`)
    setCancelText(`${t('buttons.cancel')}`)
    setToText(`${t('messages.to')}`)
  }, [t])

  const onSuccess = () => {
    config.setAction(!config.action)
    Swal.fire({
      icon: 'success',
      title: i18n.t('swal.success'),
      html: i18n.t('messages.collectionMoved')
    })
  }

  const responsesMoved = [{ name: 200, callback: onSuccess }]

  const moveCollection = async e => {
    e.preventDefault()
    config.setLoading(true)
    closeModal()
    const body = {
      id: config.collectionId,
      folder_id: config.folderId
    }
    await sendPostRequest(ENDPOINTS.move_collection, body)
      .then(response => {
        handleResponseStates(response, responsesMoved)
      })
      .catch(error => {
        const msg = error.data || error
        console.log(msg)
        onUncaughtError()
      })
      .finally(() => {
        config.setLoading(false)
      })
  }

  return (
    config && (
      <S.MoveCollectionContainer>
        <S.MoveCollectionTitle>
          <p>
            {moveText} {config.collectionName} {toText} {config.folderName}?
          </p>
          <button id="btn_closemodal_move_drop" type="button" onClick={closeModal}>
            <span className="material-icons">close</span>
          </button>
        </S.MoveCollectionTitle>

        <S.MoveCollectionContent>
          <div className='buttons'>
            <button type="button" onClick={closeModal} id="btn_cancel_move_drop">
              {cancelText}
            </button>
            <button
              type="submit"
              onClick={moveCollection}
              id="btn_move_drop"
            >
              {moveText}
            </button>
          </div>
        </S.MoveCollectionContent>
      </S.MoveCollectionContainer>
    )
  )
}

export default MoveCollectionDropModal
