import styled from 'styled-components'

export const AlertScreenContainer = styled.div`
  position: relative;
  text-align: center;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  padding: 3rem;

  .logo-img,
  .alert-img {
    display: block;
  }

  .logo-img {
    margin: 0 auto;
  }

  .alert-img {
    margin: 5rem auto;
    max-width: 40%;
  }

  @media only screen and (max-width: 1400px) and (max-height: 800px) {
    .alert-img {
      max-width: 30%;
    }
  }

  @media only screen and (max-width: 1100px) and (max-height: 700px) {
    .alert-img {
      max-width: 30%;
    }
  }

  @media only screen and (min-width: 901px) and (max-height: 700px) {
    padding: 1rem 3rem;

    .alert-img {
      margin: 4rem auto;
    }
  }

  @media only screen and (max-width: 900px) {
    .alert-img {
      margin: 8rem auto;
      max-width: 80%;
    }
  }

  @media only screen and (max-width: 400px) {
    padding: 1.5rem;

    .logo-img {
      width: 116px;
      height: 46px;
    }

    .alert-img {
      margin: 4rem auto;
      max-width: 80%;
    }
  }

  @media only screen and (max-width: 400px) and (max-height: 600px) {
    .alert-img {
      margin: 3rem auto;
    }
  }

  @media only screen and (max-width: 400px) and (max-height: 500px) {
    .alert-img {
      max-width: 70%;
    }
  }
`
