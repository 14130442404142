import React, { useEffect, useState } from 'react'
import * as S from './styles'

import { useModal } from '@/contexts/ModalContext'

import ImageNext from '@/components/ImageNext'
import DecorativeCircle from '@/components/DecorativeCircle'
import ModalButton from '../../ModalButton'

const AlertStyleModal = ({ config }) => {
  const { closeModal } = useModal()

  const [modalClass, setModalClass] = useState()

  useEffect(() => {
    let name = 'modal-alert'
    if (config.extraClasses) {
      name += ` ${config.extraClasses}`
    }

    setModalClass(name)
  }, [config])

  return (
    config && (
      <div className={modalClass}>
        <S.AlertImgContainer>
          {config.circles?.map((info, i) => (
            <DecorativeCircle
              key={i}
              top={info.top}
              left={info.left}
              radius={info.radius}
              thickness={info.thickness}
            />
          ))}

          <ImageNext
            src={config.img.URL}
            width={config.img.width}
            height={config.img.height}
            alt={config.img.alt}
            className="alert-img"
          />

          {(config.showCloseButton || !config.buttons) && (
            <button id="btn_close_alert" type="button" onClick={closeModal}>
              <span className="material-icons-round">close</span>
            </button>
          )}
        </S.AlertImgContainer>

        <S.AlertTextContainer>
          <p className="title">{config.title}</p>
          {config.text}
          {config.buttons && (
            <div className="flexbox v-centered">
              {config.buttons.map((btn, i) => (
                <ModalButton key={i} settings={btn} />
              ))}
            </div>
          )}
        </S.AlertTextContainer>
      </div>
    )
  )
}

export default AlertStyleModal
