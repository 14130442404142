import React from 'react'
import * as S from './styles'

const DecorativeCircle = ({ top, left, radius, thickness }) => {
  return (
    <S.DecorativeCircleContainer
      top={top}
      left={left}
      radius={radius}
      thickness={thickness}
    >
      <div></div>
    </S.DecorativeCircleContainer>
  )
}

export default DecorativeCircle
