import styled from 'styled-components'

export const PasswordContainer = styled.div`
  display: flex;
  align-items: center;

  input[type="password"], input[type="text"] {
    padding-right: 3.2rem;
  }

  .eye {
    font-size: 20px;
    margin-left: -2.2rem;
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    user-select: none;
    z-index: 1;
  }

  .error ~ .eye,
  .success ~ .eye {
    margin-left: -4.4rem;
  }
`
