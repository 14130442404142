import styled from 'styled-components'

export const NewContentModalWrapper = styled.div`
  background-color: var(--light);
  border-radius: 20px;
  padding: 30px;
  max-width: 536px;
  display: flex;
  flex-direction: column;

  h3 {
    color: var(--grey17);
    font-size: 20px;
    font-weight: 700;
  }
  
  #subtitle {
    color: var(--grey10);
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  #text {
    color: var(--grey15);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  button {
    margin-top: 2rem;
    color: var(--grey16);
    border: 1px solid var(--grey3);
    border-radius: 10px;
    background: transparent;
    padding: 8px 18px;
    font-size: 16px;
    font-weight: 500;
    align-self: self-end;
  }
`

export const VideoWrapper = styled.div`
  margin: 1.5rem 0 2rem 0;

  video {
    width: 100%;
  }
`