import styled from 'styled-components'

export const CrashScreenTitle = styled.p`
  color: var(--orange);
  font-size: 36px;
  font-weight: 700;
`

export const CrashScreenText = styled.div`
  span,
  a {
    font-size: 16px;
  }

  span {
    color: var(--grey10);
  }

  a {
    font-weight: 600;
    color: var(--blue2);
    text-decoration: underline;
  }
`
