import { useEffect, useRef } from 'react'

import ImageNext from '@/components/ImageNext'

const ImageWithFallback = props => {
  const { src, fallbackSrc, width, height, alt, autoSize, ...rest } = props

  const imgRef = useRef()

  const onPrimaryImgError = () => {
    if (!imgRef.current) return

    console.warn('Image loading failed, fallback was used', src)
    imgRef.current.src = fallbackSrc
    imgRef.current.onerror = onFallbackImgError
  }

  const onFallbackImgError = () => {
    console.error('Fallback image error:', fallbackSrc)
    removeSkeletonAnimation()
  }

  const addSkeletonAnimation = () => {
    imgRef.current.classList.add('skeleton-img')
  }

  const removeSkeletonAnimation = () => {
    imgRef.current.classList.remove('skeleton-img')
  }

  useEffect(() => {
    if (!src || !imgRef.current) return

    if (imgRef.current.src) {
      imgRef.current.src = ''
    }
    imgRef.current.src = src
    imgRef.current.onerror = onPrimaryImgError
    addSkeletonAnimation()
  }, [imgRef.current, src])

  if (!src || !fallbackSrc) {
    return
  }

  return (
    <ImageNext
      ref={imgRef}
      onLoad={removeSkeletonAnimation}
      width={width}
      height={height}
      alt={alt}
      autoSize={autoSize}
      {...rest}
    />
  )
}

export default ImageWithFallback
