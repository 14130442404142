import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

import { useModal } from '@/contexts/ModalContext'

import {
  ENDPOINTS,
  sendPostRequest,
  handleResponseStates
} from '@/services/api'
import { onUncaughtError } from '@/services/userAlerts'

import { useTranslation } from 'react-i18next'
import { i18n } from '@/translate/i18n'
import Swal from 'sweetalert2'

import * as S from './styles'

const CreateFolderCollectionModal = ({ config }) => {
  const { t } = useTranslation()
  const { closeModal } = useModal()
  const router = useRouter()

  const [createFolderText, setCreateFolderText] = useState('')
  const [createFolderPlaceholder, setCreateFolderPlaceholder] = useState('')
  const [cancelText, setCancelText] = useState()
  const [createFolderLabelText, setCreateFolderLabelText] = useState()

  const [folderName, setFolderName] = useState('')

  useEffect(() => {
    setCreateFolderText(t('buttons.createFolder'))
    setCreateFolderPlaceholder(t('placeholders.createFolder'))
    setCancelText(`${t('buttons.cancel')}`)
    setCreateFolderLabelText(`${t('labels.folderName')}`)
  }, [t])

  const onSuccess = () => {
    config.setAction(!config.action)
    Swal.fire({
      icon: 'success',
      title: i18n.t('swal.success'),
      html: i18n.t('messages.collectionFolderCreated')
    })
  }

  const responsesCreate = [{ name: 200, callback: onSuccess }]

  const createFolder = async e => {
    e.preventDefault()
    config.setLoading(true)
    closeModal()
    const body = { name: folderName, folder_id: config.currentFolder }
    await sendPostRequest(ENDPOINTS.create_folder, body)
    .then(response => {
      handleResponseStates(response, responsesCreate)
    })
    .catch(error => {
      const msg = error.data || error
      console.log(msg)
      onUncaughtError()
    })
    .finally(() => {
      config.setLoading(false)
    })
  }

  return config && (
    <S.CreateFolderCollectionModal>
      <S.CreateFolderCollectionTitle>
        <p>{createFolderText}</p>
        <button id="btn_closemodal_createfolder" type="button" onClick={closeModal}>
          <span className="material-icons">close</span>
        </button>
      </S.CreateFolderCollectionTitle>

      <form onSubmit={createFolder}>
          <label htmlFor={createFolderText}>{createFolderLabelText}</label>
          <input
            type="text"
            placeholder={createFolderPlaceholder}
            name={createFolderText}
            onChange={e => setFolderName(e.target.value)}
            maxLength={50}
            id="inp_create_folder"
            required
          />
          <div>
            <button type="button" id="btn_cancel_create_folder" onClick={closeModal}>
              {cancelText}
            </button>
            <button type="submit" id="btn_create_folder">{createFolderText}</button>
          </div>
        </form>
    </S.CreateFolderCollectionModal>
  )
}

export default CreateFolderCollectionModal