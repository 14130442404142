import styled from 'styled-components'

export const EditInfoModalContainer = styled.div`
  background: var(--light);
  border-radius: 5px;
  padding: 2rem;

  strong {
    font-size: 24px;
    font-weight: 600;
  }

  input {
    font-weight: 500;
  }
`
