import React, { createContext, useContext, useEffect, useState } from 'react'

import { EVENT_KEYS } from '@/services/events'

const initialState = { width: undefined, height: undefined }

export const WindowSizeContext = createContext(initialState)

export function WindowSizeProvider(props) {
  const { children } = props

  const [windowSize, setWindowSize] = useState(initialState)

  useEffect(() => {
    handleResize()

    const listener = window.addEventListener(EVENT_KEYS.resize, handleResize)
    return () => {
      window.removeEventListener(EVENT_KEYS.resize, listener)
    }
  }, [])

  const handleResize = () => {
    const size = {
      width: window.innerWidth,
      height: window.innerHeight
    }
    setWindowSize(size)
  }

  return (
    <WindowSizeContext.Provider value={{ windowSize }}>
      {children}
    </WindowSizeContext.Provider>
  )
}

export function useWindowSize() {
  return useContext(WindowSizeContext)
}
