import React from 'react'
import * as S from './styles'

const baseURL = `${process.env.CDN_URL}/public/help`
const bladeMoveImg = `${baseURL}/blade_move.svg`
const bladeTextboxImg = `${baseURL}/blade_textbox.svg`
const bladeZoomImg = `${baseURL}/blade_zoom.svg`
const keyboardArrowsImg = `${baseURL}/keyboard_arrows.svg`
const keyboardPlusImg = `${baseURL}/keyboard_plus.svg`
const keyboardWasdImg = `${baseURL}/keyboard_wasd.svg`
const modelMoveImg = `${baseURL}/model_move.svg`
const modelRotateImg = `${baseURL}/model_rotate.svg`
const modelSelectImg = `${baseURL}/model_select.svg`
const modelZoomImg = `${baseURL}/model_zoom.svg`
const mouseLeftImg = `${baseURL}/mouse_left.svg`
const mouseRightImg = `${baseURL}/mouse_right.svg`
const mouseScrollImg = `${baseURL}/mouse_scroll.svg`
const mouseSelectImg = `${baseURL}/mouse_select.svg`
const padLeftImg = `${baseURL}/pad_left.svg`
const padRightImg = `${baseURL}/pad_right.svg`
const padPinchImg = `${baseURL}/pad_pinch.svg`

const NavIcon = ({ icons }) => {
  const iconImgs = {
    blade_move: bladeMoveImg,
    blade_textbox: bladeTextboxImg,
    blade_zoom: bladeZoomImg,
    keyboard_arrows: keyboardArrowsImg,
    keyboard_plus: keyboardPlusImg,
    keyboard_wasd: keyboardWasdImg,
    model_move: modelMoveImg,
    model_rotate: modelRotateImg,
    model_select: modelSelectImg,
    model_zoom: modelZoomImg,
    mouse_left: mouseLeftImg,
    mouse_right: mouseRightImg,
    mouse_scroll: mouseScrollImg,
    mouse_select: mouseSelectImg,
    pad_left: padLeftImg,
    pad_right: padRightImg,
    pad_pinch: padPinchImg
  }

  return (
    <S.NavIconContainer className="flexbox v-centered h-centered">
      <img src={iconImgs[icons[0]]} alt="" className="icon1" />
      <span>=</span>
      <img src={iconImgs[icons[1]]} alt="" className="icon2" />
    </S.NavIconContainer>
  )
}

export default NavIcon
