import React from 'react'

import CrashScreen from '@/components/AlertScreen/CrashScreen'
import AppAccessScreen from '@/components/AlertScreen/AppAccessScreen'

import { AppAccessError } from '@/contexts/AuthContext'

import { clearStorage } from '@/services/browserStorage'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = { errorType: 'none' }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    if (error instanceof AppAccessError) {
      clearStorage()
      return { errorType: 'appAccess' }
    }
    return { errorType: 'error' }
  }

  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log(error, errorInfo)
  }

  render() {
    // Check if the error is thrown
    switch (this.state.errorType) {
      case 'none':
        // Return children components in case of no error
        return this.props.children

      case 'appAccess':
        return <AppAccessScreen />

      default:
        return <CrashScreen />
    }
  }
}

export default ErrorBoundary
