import React, { useEffect } from 'react'
import * as S from './styles'

import Select from 'react-select'

const MHSelect = ({ id, options, defaultValue, handler, selectRef }) => {
  const ControlStyle = () => ({
    display: 'flex',
    flex: 'wrap',
    padding: '0.5rem 1rem',
    borderRadius: '5px',
    border: '1px solid var(--grey5)',
    color: 'var(--grey7)',
    cursor: 'pointer',
    background: 'var(--light)',
    minWidth: '200px'
  })

  const ValueContainerStyle = provided => ({
    ...provided,
    padding: '0 2rem 0 0'
  })

  const DropdownIndicatorStyle = () => ({
    display: 'flex',
    padding: '0',
    color: 'var(--grey2)',
    boxSizing: 'border-box'
  })

  const SingleValueStyle = provided => ({
    ...provided,
    margin: '0'
  })

  const InputStyle = provided => ({
    ...provided,
    margin: '0',
    padding: '0'
  })

  const MenuStyle = provided => ({
    ...provided,
    top: '0',
    margin: '0',
    borderRadius: '5px',
    boxShadow: '0 0 20px 3px rgba(120, 121, 241, 0.2)',
    width: 'fit-content',
    zIndex: 3
  })

  const MenuListStyle = provided => ({
    ...provided,
    paddingBottom: '0.5rem',
    paddingTop: '0.5rem'
  })

  const OptionStyle = (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    backgroundColor: state.isSelected
      ? 'rgba(123, 97, 255, 0.2)'
      : 'var(--light)',
    color: 'var(--dark)',
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: state.isSelected
        ? 'rgba(123, 97, 255, 0.2)'
        : 'rgba(123, 97, 255, 0.1)'
    }
  })

  const Style = {
    control: ControlStyle,
    valueContainer: ValueContainerStyle,
    dropdownIndicator: DropdownIndicatorStyle,
    singleValue: SingleValueStyle,
    input: InputStyle,
    menu: MenuStyle,
    menuList: MenuListStyle,
    option: OptionStyle
  }

  useEffect(() => {
    if (!selectRef || options?.length === 0) return
    const defaultOption = defaultValue ? defaultValue : options[0]
    selectRef.current.selectOption(defaultOption)
  }, [defaultValue, options, selectRef])

  return (
    <S.MHSelectContainer>
      <Select
        ref={selectRef}
        inputId={id}
        options={options}
        placeholder={null}
        isSearchable={false}
        components={{ IndicatorSeparator: null }}
        styles={Style}
        onChange={option => {
          const optionIndex = options.indexOf(option)
          handler(option.value, option.label, optionIndex)
        }}
      />
    </S.MHSelectContainer>
  )
}

export default MHSelect
