import React, { useEffect, useState } from 'react'

import EditStyleModal from '../../Layouts/Edit'
import FormEditPassword from '@/components/Forms/EditPassword'

import { changeInputClass, validations } from '@/services/functions'
import {
  ENDPOINTS,
  sendPostRequest,
  handleResponseStates
} from '@/services/api'
import {
  passwordTooShort,
  passwordsDontMatch,
  invalidPassword,
  onPasswordReset,
  onUncaughtError,
  onTooManyRequests,
  onTooManyFailedAttempts,
  onLimitExceeded
} from '@/services/userAlerts'

import { useAuth } from '@/contexts/AuthContext'
import { useModal } from '@/contexts/ModalContext'

import { useTranslation } from 'react-i18next'

const EditPasswordModal = () => {
  const { t } = useTranslation()
  const { user, userSensitive } = useAuth()
  const { settings, closeModal } = useModal()

  const [oldPass, setOldPass] = useState('')
  const [newPass, setNewPass] = useState('')
  const [repeatPass, setRepeatPass] = useState('')
  const [allowSubmitBtn, setAllowSubmitBtn] = useState(false)

  const [buttonsChangePassword, setButtonsChangePassword] = useState('')

  let post = false
  let oldPassValidation = false
  let newPassValidation = false
  let repeatPassValidation = false

  useEffect(() => {
    setButtonsChangePassword(t('buttons.changePassword'))
  }, [t])

  useEffect(() => {
    changeInputClass('inp_pass', oldPass, post, oldPassValidation)
  }, [oldPass, post, oldPassValidation])

  useEffect(() => {
    if (newPass.length >= 8 && repeatPass.length >= 8) {
      setAllowSubmitBtn(true)
    } else {
      setAllowSubmitBtn(false)
    }
  }, [newPass, repeatPass])

  useEffect(() => {
    changeInputClass('inp_newpass', newPass, post, newPassValidation)
  }, [newPass, post, newPassValidation])

  useEffect(() => {
    changeInputClass('inp_repeatpass', repeatPass, post, repeatPassValidation)
  }, [repeatPass, post, repeatPassValidation])

  const validateData = () => {
    oldPassValidation = validations.required(oldPass, 'inp_pass', post)
    newPassValidation = validations.password(newPass, 'inp_newpass', post)
    repeatPassValidation = validations.repeatPassword(
      newPass,
      repeatPass,
      'inp_repeatpass',
      post
    )

    if (!oldPassValidation) {
      return false
    } else if (!newPassValidation) {
      passwordTooShort()
      return false
    } else if (!repeatPassValidation) {
      passwordsDontMatch()
      return false
    }

    return true
  }

  const onChangePass = () => {
    onPasswordReset()
    closeModal()
  }

  const changeUserPassword = async () => {
    settings.config.loadHandler(true)
    await sendPostRequest(ENDPOINTS.change_password, {
      new_password: newPass
    })
      .then(response => {
        settings.config.loadHandler(false)
        handleResponseStates(response, responsesChange)
      })
      .catch(error => {
        settings.config.loadHandler(false)
        const msg = error.data || error
        console.error('changeUserPassword error: ', msg)
        onUncaughtError()
      })
  }

  const checkUserAuth = async () => {
    post = true
    if (!validateData()) {
      return
    }

    settings.config.loadHandler(true)
    await sendPostRequest(ENDPOINTS.sign_in, {
      username: userSensitive.email,
      password: oldPass
    })
      .then(response => {
        settings.config.loadHandler(false)
        handleResponseStates(response, responsesAuth)
      })
      .catch(error => {
        settings.config.loadHandler(false)
        const msg = error.data || error
        console.error('checkUserOldPassword error: ', msg)
        onUncaughtError()
      })
  }

  const responsesAuth = [
    { name: 204, callback: changeUserPassword },
    { name: 'NotAuthorizedException', callback: invalidPassword },
    { name: 'InvalidPasswordException', callback: invalidPassword },
    { name: 'TooManyRequestsException', callback: onTooManyRequests },
    {
      name: 'TooManyFailedAttemptsException',
      callback: onTooManyFailedAttempts
    },
    { name: 'LimitExceededException', callback: onLimitExceeded }
  ]

  const responsesChange = [{ name: 200, callback: onChangePass }]

  return (
    <EditStyleModal
      name={buttonsChangePassword}
      confirmCallback={checkUserAuth}
      cancelCallback={closeModal}
      allowSubmitBtn={allowSubmitBtn}
      contentHTML={
        <FormEditPassword
          requestHandler={checkUserAuth}
          oldPassState={oldPass}
          oldPassHandler={setOldPass}
          newPassState={newPass}
          newPassHandler={setNewPass}
          repeatPassState={repeatPass}
          repeatPassHandler={setRepeatPass}
        />
      }
    />
  )
}

export default EditPasswordModal
