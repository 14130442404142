import React from 'react'
import * as S from './styles'

const Title = ({ name, cancelCallback }) => {
  return (
    <S.EditTitleContainer className="flexbox v-centered h-spaced">
      <strong>{name}</strong>
      <button
        id="btn_close"
        type="button"
        onClick={cancelCallback}
        className="modal-close"
      >
        <span className="material-icons">close</span>
      </button>
    </S.EditTitleContainer>
  )
}

export default Title
